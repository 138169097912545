<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div>
      <FlexContainer v-if="isSchoolMode" :title="$t('subTitle.customerInfos')" class="gap10 mb-2" #content>
        <div class="d-flex flex-column noPadding text-left">
          <p><strong>{{ $t('text.customerAccount') }}:</strong> {{ fullName(wondCustomer) }}</p>
        </div>
      </FlexContainer>

      <date-search-table id="dateSearchTable" :callback="getTransactions" style="" />
      <b-container fluid>
        <b-row class="d-none d-md-flex">
          <h3 class="normalText sr-only">{{ $t('table.transactionTable') }}</h3>
          <b-table small class="transactionTable shadowTable"
                   :items="recentTransactions"
                   :fields="fields"
                   responsive
                   striped
                   hover
                   table-class="roundedTable"
                   head-variant="dark"
                   tbody-class="text-left"
                   :label-sort-asc="$t('ariaLabel.sortAsc')"
                   :label-sort-desc="$t('ariaLabel.sortDesc')"
                   :label-sort-clear="$t('ariaLabel.sortClear')"
                   @sort-changed="onSortChanged"
                   @row-clicked="emptyMethodForRetainingFocus"
                   :tbody-tr-attr="rowIdentifier">
            <template #cell(amount)="data">
              <span>{{ data.item.prepayment ? data.item.formattedPaymentTotal : data.item.formattedTotal }}</span>
            </template>
            <template #cell(details)="data">
              <b-button class="btn btn-block btn-sm"
                        v-b-tooltip :title="$t('button.details')" :aria-label="$t('ariaLabel.transactionDetails') + data.item.formattedDate"
                        @click="showTransactionDetails(data.item)">
                <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
              </b-button>
            </template>
            <template v-slot:empty>
              <b-tr>
                <b-td class="text-left" colspan="6">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </template>
            <template #custom-foot v-if="config.TRANSACTION_HISTORY.showTotal">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="4"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="1"><b>{{ prePaymentTotal }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="1"><b>{{ paymentTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </template>
          </b-table>
        </b-row>

        <b-row class="d-md-none">
          <h3 class="normalText sr-only">{{ $t('table.transactionTable') }}</h3>
          <b-table class="shadowTable"
                   small
                   head-variant="dark"
                   :items="recentTransactions"
                   :fields="mobileFields"
                   responsive
                   striped
                   hover
                   table-class="roundedTable"
                   tbody-class="text-left"
                   :label-sort-asc="$t('ariaLabel.sortAsc')"
                   :label-sort-desc="$t('ariaLabel.sortDesc')"
                   :label-sort-clear="$t('ariaLabel.sortClear')"
                   @sort-changed="onSortChanged"
                   @row-clicked="emptyMethodForRetainingFocus"
                   :tbody-tr-attr="rowIdentifier">
            <template #cell(amount)="data">
              <span>{{ data.item.prepayment ? data.item.formattedPaymentTotal : data.item.formattedTotal }}</span>
            </template>
            <template #cell(details)="data">
              <b-button class="btn btn-block btn-sm"
                        v-b-tooltip :title="$t('button.details')" :aria-label="$t('ariaLabel.transactionDetails') + data.item.formattedDate"
                        @click="showTransactionDetails(data.item)">
                <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
              </b-button>
            </template>
            <template v-slot:empty>
              <b-tr>
                <b-td class="text-left" colspan="4">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </template>
            <template #custom-foot v-if="config.TRANSACTION_HISTORY.showTotal">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="2"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="1"><b>{{ paymentPrePaymentTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </template>
          </b-table>
        </b-row>

        <b-row v-if="config.TRANSACTION_HISTORY.showPaymentDetails">
          <h3 class="normalText sr-only">{{ $t('table.paymentDetails') }}</h3>
          <b-table class="shadowTable"
              head-variant="dark"
              small
              :items="paymentsListTotal"
              :fields="paymentFields"
              responsive
              striped
              hover
              table-class="roundedTable"
              :label-sort-asc="$t('ariaLabel.sortAsc')"
              :label-sort-desc="$t('ariaLabel.sortDesc')"
              :label-sort-clear="$t('ariaLabel.sortClear')"
              :tbody-tr-attr="rowIdentifier">
            <template v-slot:empty>
              <b-tr>
                <b-td class="text-left" colspan="7">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </template>
          </b-table>
        </b-row>
      </b-container>
    </div>

    <!-- Modal for transaction details -->
    <b-modal v-if="currentTransactionSelected" id="transaction-details-modal" ref="transactionDetailsModal" @shown="focusCloseButton" :title="$t('title.transaction') + ' ' + currentTransactionSelected.formattedDate" hide-footer>
      <transaction-details :transaction="currentTransactionSelected" />
    </b-modal>
  </div>
</template>

<script>
import TransactionDetails from "../sub-components/TransactionDetails.vue";
import DateSearchTable from "@/components/main-components/DateSearchTable.vue";
import Utils from "@/utils/utils";
import VueLoading from "vue-loading-overlay";
import {accountService} from "@/services/api/accountService";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import {transactionService} from "../../services/api/transactionService";
import {useAppStore} from "../../stores/app";
import {Workflow} from "../../constants/constants";

export default {
  components: {
    'loading': VueLoading,
    'transaction-details': TransactionDetails,
    'date-search-table': DateSearchTable,
    FlexContainer,
  },
  computed: {
    config() {
      return window.config;
    },
    isSchoolMode() {
      return this.appStore.isWorkflow(Workflow.SCHOOL);
    },
    fields() {
      return [
        { key: 'formattedDate', label: this.$t('table.date'), sortable: true },
        { key: 'transNum', label: this.$t('table.transId'), sortable: true},
        { key: 'shopDescription', label: this.$t('table.shop'), sortable: true, tdClass: 'text-left' },
        { key: 'tillDescription', label: this.$t('table.till'), sortable: true },
        // Sort by numericAmount, display formattedTotal
        { key: 'prePayment', label:this.$t('table.prepayment'), sortable: true, class: 'text-right', formatter: (value, key, item) => item.hasPrepayment ? item.sumPrepayment : '' },
        { key: 'numericAmount', label: this.$t('table.payment'), sortable: true, class: 'text-right', formatter: (value, key, item) => item.hasPayment ? item.sumPayment : '' },
        { key: 'details', label: '', tdClass: 'text-right' }
      ];
    },
    mobileFields(){
      return [
        { key: 'formattedDate', label: this.$t('table.date'), sortable: true, class: 'text-left' },
        { key: 'shopDescription', label: this.$t('table.shop'), sortable: true, class: 'text-left' },
        // Sort by numericAmount, display formattedTotal
        { key: 'numericAmount', label: this.$t('table.prepayment') + ' / ' + this.$t('table.payment'), sortable: true, class: 'text-right', formatter: (value, key, item) =>  item.paymentPrePayment },
        { key: 'details', label: '', class: 'text-center' }
      ];
    },
    paymentFields() {
      return [
        { key: 'paymentDescription', label: this.$t('table.paymentsList'), class: 'text-left', colspan: 5 },
        { key: 'formattedAmount', label: this.$t('table.amount'), class: 'text-right', colspan: 2 }
      ];
    },
  },
  props: {
    customerId: {
      type: [String, Number],
    }
  },
  data() {
    return {
      isLoading: false,
      wondCustomer: {},
      recentTransactions: [],
      transactionsFormattedTotal: 0.00,
      prePaymentTotal: 0.00,
      paymentTotal: 0.00,
      paymentPrePaymentTotal: 0.00,
      paymentsListTotal: [],
      currentTransactionSelected: {},
    };
  },
  setup() {
    const appStore = useAppStore();

    return {
      appStore
    };
  },
  methods: {
    focusCloseButton() {
      this.$nextTick(() => {
        const closeButton = document.querySelector("#transaction-details-modal .close");
        if (closeButton) {
          closeButton.focus();
        }
      });
    },
    emptyMethodForRetainingFocus() {
        // This method intentionally left blank
    },
    fullName(customer) {
      const nameParts = [];

      if (typeof customer === 'undefined')
        return '';

      if (customer.description) {
        nameParts.push(customer.description);
      }

      if (customer.firstName) {
        nameParts.push(customer.firstName);
      }

      return nameParts.join(' ').trim();
    },
    async selectChild(id) {
      this.isLoading = true;
      const result = await accountService.selectChild(id);
      this.isLoading = false;

      if (result.exception !== undefined || result.errorCode < 0) {
        this.makeToast('danger', this.$t('notification.selectChildError'));
        return;
      }

      return result.wondChild;
    },
    async getTransactions(dateFrom, dateTo) {
      this.isLoading = true;
      const result = await transactionService.getTransactions(dateFrom, dateTo);
      this.isLoading = false;

      // Prepare the transactions with numericAmount for sorting
      const f = new Intl.DateTimeFormat(undefined ,{localeMatcher:"best fit",dateStyle: 'medium',timeStyle: 'short'});

      this.recentTransactions = result.transactions.map(transaction => {
        const numericAmount = parseFloat(transaction.formattedTotal.replace(/[^\d.-]/g, ''));
        const numericPaymentTotal = parseFloat(transaction.formattedPaymentTotal.replace(/[^\d.-]/g, ''));

        const transactionDate = f.format(new Date(transaction.transDate));
        const hasPayment = transaction.paymentsList.some(item => item.isPrepayment !== 1 && item.amount !== 0);
        const hasPrepayment = transaction.paymentsList.some(item => item.isPrepayment === 1 && item.amount !== 0);
        const sumPayment = Utils.formatCurrency(transaction.paymentsList.reduce((sum, item) => item.isPrepayment ? sum : sum + item.amount, 0), this.config.ACCOUNT_BALANCE_CURRENCY, true, this.config.ACCOUNT_BALANCE_SEPARATOR);
        const sumPrepayment = Utils.formatCurrency(transaction.paymentsList.reduce((sum, item) => item.isPrepayment ? sum + item.amount : sum, 0), this.config.ACCOUNT_BALANCE_CURRENCY, true, this.config.ACCOUNT_BALANCE_SEPARATOR);

        return {
          ...transaction,
          numericAmount: transaction.prepayment ? numericPaymentTotal : numericAmount,
          formattedDate: transactionDate,
          hasPayment: hasPayment,
          hasPrepayment: hasPrepayment,
          sumPayment: sumPayment,
          sumPrepayment: sumPrepayment,
          paymentPrePayment: `${hasPrepayment ? sumPrepayment : Utils.formatCurrency(0, this.config.ACCOUNT_BALANCE_CURRENCY, true, this.config.ACCOUNT_BALANCE_SEPARATOR)} / ${hasPayment ? sumPayment : Utils.formatCurrency(0, this.config.ACCOUNT_BALANCE_CURRENCY, true, this.config.ACCOUNT_BALANCE_SEPARATOR)}`
        };

      });

      this.transactionsFormattedTotal = result.transactionsFormattedTotal;

      this.prePaymentTotal = Utils.formatCurrency(this.recentTransactions.reduce((sum, transaction) => {
          return sum + transaction.paymentsList.reduce((innerSum, payment) =>  payment.isPrepayment ? innerSum + Number(payment.amount) : innerSum, 0);
      }, 0), this.config.ACCOUNT_BALANCE_CURRENCY, true, this.config.ACCOUNT_BALANCE_SEPARATOR);

      this.paymentTotal = Utils.formatCurrency(this.recentTransactions.reduce((sum, transaction) => {
        return sum + transaction.paymentsList.reduce((innerSum, payment) =>  payment.isPrepayment ? innerSum : innerSum + Number(payment.amount), 0);
      }, 0), this.config.ACCOUNT_BALANCE_CURRENCY, true, this.config.ACCOUNT_BALANCE_SEPARATOR);

      this.paymentPrePaymentTotal = this.prePaymentTotal + ' / ' + this.paymentTotal;

      this.paymentsListTotal = result.paymentsListTotal;
    },
    showTransactionDetails(item) {
      this.currentTransactionSelected = item;
      this.$nextTick(() => {
        if (this.$refs.transactionDetailsModal) {
          this.$refs.transactionDetailsModal.show();  // Open the modal
        } else {
          console.error('Modal reference is not defined.');
        }
      });
    },
    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
    },
    rowIdentifier(item, type) {
      if (type != 'row') {
        return {};
      }
      return {
        'title': this.$t('text.transactionFrom') + ' ' + item.formattedDate
      }
    }
  },
  async mounted() {
    if (this.isSchoolMode) {
      this.wondCustomer = await this.selectChild(this.customerId);
    }
    await this.getTransactions(Utils.getStartOfMonth(), Utils.getEndOfMonth());
  }
};
</script>
<style scoped>
  .transactionTable {
    text-align: left; /* Align header text to the left */
  }
</style>
<style>
  th > div {
    text-align: inherit;
  }

  th:focus-visible{
    z-index: 1;
  }

  tr:focus-visible{
    z-index: 1;
    position: relative;
  }

  .container-fluid .table td {
    vertical-align: middle; /* Center Table Cells Vertically */
  }

  .roundedTable {
    border-radius: 0.3rem;
    overflow: hidden;
  }

  .shadowTable{
    box-shadow: 0 0 4px -2px black;
    border-radius: 0.3rem;
  }

</style>
