<template>
  <div>
    <b-spinner label="Authenticating..."></b-spinner>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import {Login, Mode, Workflow} from "@/constants/constants";
import {useLocaleStore} from "@/stores/locale";
import {useAuthStore} from "@/stores/auth";
import {useAppStore} from "@/stores/app";
import {useOrderStore} from "@/stores/order";
import {useCustomerStore} from "@/stores/customer";
import {accountService} from "@/services/api/accountService";
import orderMixin from "@/components/mixins/orderMixin";

export default {
  mixins: [orderMixin],
  data() {
    return {
      ordersPossible: true,
    };
  },
  props: {
    code: String,
    jwt: String,
    shopCode: Number,
    tableCode: String,
    orderImmediate: Boolean,
    isMealPlan: Boolean,
    isSchool: Boolean,
    validationType: String,
  },
  setup() {
    const localeStore = useLocaleStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const orderStore = useOrderStore();
    const customerStore = useCustomerStore();

    return {
      localeStore,
      authStore,
      appStore,
      orderStore,
      customerStore
    };
  },
  methods: {
    async changeLanguage(newLocale) {
      await this.localeStore.setLocale(newLocale);
    },
    async doNavigationByAppMode() {
      if (this.isSchool) {
        this.$navigateToStudentAccounts(true);
      } else if (this.shopCode) {
        if (this.orderImmediate) {
          await this.doImmediateOrder();
          await this.doCheckIfOrdersAreCurrentlyPossible();
          this.$navigateToMenu(true);
        } else if (this.tableCode) {
          Object.assign(window.config, window.configTablemode);

          await this.selectTableByQRCode(this.shopCode, this.tableCode);
          this.$navigateToMenu(true);
        } else {
          this.shopStore.setWondCode(this.shopCode);
          if (this.isMealPlan) {
            this.$navigateToMealPlanner(true);
          } else {
            this.$navigateToShop(true);
          }
        }
      } else {
        this.$navigateToStartPage();
      }
    },
    async handleSSO() {
      try {
        const result = await accountService.loginWithJWT(this.jwt, this.code, this.shopCode, this.validationType);
        if (result.exception !== undefined || result.errorCode < 0) {
          this.$navigateToErrorSSO();
          return;
        }

        if (window.config.USE_ALLERGEN_FILTERS && result.wondCustomer.dietaryInfo !== undefined) {
          this.customerStore.setDietaryInfo(result.wondCustomer.dietaryInfo);
        }

        this.authStore.setAuthenticated(true, Login.Mode.SINGLE_SIGN_ON, Login.UserType.REGISTERED_USER);

        if (window.config.USE_EXTERNAL_LANGUAGE) {
          const newLocale = !Utils.isEmpty(result.customer.locale) ? result.customer.locale : this.config.LANGUAGE_LIST[0].value;
          await this.changeLanguage(newLocale.replace('-', '_'));
        }

        await this.doNavigationByAppMode();
      } catch (error) {
        this.$navigateToErrorSSO();
      }
    },
  },
  created() {
    if (this.isSchool) {
      this.appStore.setWorkflow(Workflow.SCHOOL);
    } else if (this.shopCode) {
      if (this.orderImmediate) {
        this.appStore.setWorkflow(Workflow.ORDER);
        this.appStore.setMode(Mode.IMMEDIATE);
      } else if (this.tableCode) {
        this.appStore.setWorkflow(Workflow.ORDER);
        this.appStore.setMode(Mode.TABLE);
      } else {
        if (this.isMealPlan) {
          this.appStore.setWorkflow(Workflow.MEAL_PLAN);
        } else {
          this.appStore.setWorkflow(Workflow.ORDER);
        }
        this.appStore.setMode(Mode.SHOP);
      }
    }
    this.handleSSO();
  },
};
</script>
