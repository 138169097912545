<template>
  <div id="mealPlans">
    <vue-loading v-if="isLoading" />

    <div id="mealPlan" v-if="mealPlan" class="d-flex gap10 flex-column">
      <div v-for="group in mealPlan.groups" :key="group.description" class="meal-container">
        <div class="meal-group">
          <h2 class="meal-group-title py-1" style="font-size:20px;">
            <span class="text-left">{{ group.description }}</span>
            <span class="text-right">
              <strong>{{ productCountByGroup(group.id) }}</strong> / <strong>{{ group.maxProducts }}</strong>
              <span v-if="group.minProducts > 0" class="mealplan-group-requirement"> ({{ $t('text.minProducts') }}: {{ group.minProducts }})</span>
            </span>
          </h2>
        </div>
        <div class="grid-layout">
          <div
              v-for="product in group.items"
              :key="product?.kkProdId"
              :class="{ 'disabled-item': !isMealPlanValid || product.locked }"
          >
            <div :id="product.kkProdId" class="meal-item">
              <img v-if="product.image1" :src="product.image1" :alt="product.name" class="meal-image" @click="viewProductDetails(product.kkProdId, product.isLocked, product.name, group.id)"/>
              <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                <div class="meal-details" @click="viewProductDetails(product.kkProdId, product.isLocked, product.name, group.id)">
                  <div class="meal-description">
                    <span>{{ product.name }}</span>
                    <div class="properties"></div>
                    <div class="allergens">
                      <div v-for="property in product.properties" :key="property.id">
                        <div>
                          <img v-if="property.image" :src="property.image" :alt="property.name" class="property-img" />
                        </div>
                      </div>
                      <div v-for="allergen in product.allergens" :key="allergen.id">
                        <div>
                          <img v-if="allergen.image" :src="allergen.image" :alt="allergen.name" class="allergen-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row gap10 w-100 flex-wrap">
                  <div
                      class="button"
                      :class="{ disabled: !isMealPlanValid }"
                      @click="viewProductDetails(product.kkProdId, product.isLocked, product.name, group.id)"
                      role="button"
                      tabindex="0"
                      aria-label="View product details"
                  >
                    <font-awesome-icon :icon="['fas', 'circle-info']" />
                  </div>
                  <SpinButton
                    :class="{ disabled: !isMealPlanValid || product.locked || isGroupMaxReached(group.id) }"
                    :input-disabled="true"
                    :min=0
                    :max="getMaxAvailableForProduct(group.id, product.kkProdId)"
                    :modelValue="selectedProductsCount[group.id]?.[product.kkProdId] || 0"
                    @update:modelValue="({ value, type }) => handleProductUpdate(product.kkProdId, product.isLocked, product.hasChildren, product.name, product.price, type, group.id)"
                    :preventEmit="product.hasChildren"
                    role="button"
                    tabindex="0"
                    aria-label="Add or remove product from basket"
                    v-if="canOrder"
                  />
                  <span class="meal-price" style="white-space: nowrap">{{ product.formattedPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for product details -->
      <b-modal id="product-details-modal" hide-footer :title="selectedProduct ? selectedProduct.name : ''">
        <ProductDetails
          v-if="selectedProduct && selectedProduct.groupId"
          :productId="selectedProduct.productId"
          :max-quantity="getMaxAvailableForProduct(selectedProduct.groupId, selectedProduct.productId)"
          :current-quantity="getCurrentProductCount(selectedProduct.groupId, selectedProduct.productId)"
          :groupId="selectedProduct.groupId"
          :can-order="canOrder"
          @add-to-basket="addToBasket"
          @remove-from-basket="removeFromBasket"
          @close="closeProductDetailsModal" />
      </b-modal>

    </div>
  </div>
</template>

<script>
import ProductDetails from "@/components/sub-components/ProductDetails.vue";
import {basketService} from "@/services/api/basketService";
import VueLoading from "vue-loading-overlay";
import SpinButton from "@/components/page-components/templates/SpinButton.vue";
import {useMealPlanStore} from "@/stores/mealPlan";
import {OrderTypes} from "@/constants/constants";
import {useAppStore} from "@/stores/app";
import {useShopStore} from "@/stores/shop";
import {formatProductOptions} from "@/utils/productUtils";
import {encode64} from "@/utils/encodeUtils";

export default {
  name: "MealPlan",
  components: {
    SpinButton,
    VueLoading,
    ProductDetails,
  },
  props: {
    mealPlan: {
      type: Object,
      required: true,
    },
    reservationDateTime: {
      type: Date,
      required: true,
    }
  },
  data() {
    return {
      isLoading: false,
      selectedProduct: {},
      selectedProductsCount: {}, // Track selected products count by group
    };
  },
  computed: {
    isMealPlanValid() {
      if (!this.mealPlan) {
        return false;
      }

      const currentTime = new Date();
      const mealPlanStartDate = this.getMealPlanStartDate(this.mealPlan);
      return currentTime < mealPlanStartDate;
    },
    canOrder(){
      return this.config.MEAL_PLAN.withOrdering;
    },
    /** @file public/settings/config/default.js */
    config() {
      return window.config;
    }
  },
  setup() {
    const mealPlanStore = useMealPlanStore();
    const appStore = useAppStore();
    const shopStore =useShopStore()

    return {
      mealPlanStore,
      appStore,
      shopStore
    };
  },
  mounted() {
    this.initializeSelectedProducts();
  },
  methods: {
    initializeSelectedProducts() {
      const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
      if (order) {
        this.selectedProductsCount = {};

        order.products.forEach(product => {
          if (!this.selectedProductsCount[product.groupId]) {
            this.$set(this.selectedProductsCount, product.groupId, {});
          }

          this.$set(this.selectedProductsCount[product.groupId], product.id, product.quantity);
        });
      }
    },
    closeProductDetailsModal() {
      this.$bvModal.hide("product-details-modal");
    },
    closeBasketProductsModal() {
      this.$bvModal.hide("basket-products-modal");
    },
    handleProductUpdate(productId, isLocked, hasChildren, name, price, type, groupId) {
      if (hasChildren) {
        if (type === 'increment') {
          this.viewProductDetails(productId, isLocked, name, groupId);
        } else if (type === 'decrement') {
          // this.viewBasketProducts(group, productId);
        }
      } else {
        if (type === 'increment') {
          this.addToBasket(productId, 1, price, undefined, groupId);
        } else if (type === 'decrement') {
          this.removeFromBasket(productId, 1, groupId);
        }
      }
    },
    productCountByGroup(groupId) {
      if (!this.selectedProductsCount[groupId]) {
        return 0;
      }

      return Object.values(this.selectedProductsCount[groupId])
        .filter(qty => typeof qty === 'number' && !isNaN(qty))
        .reduce((sum, qty) => sum + qty, 0);
    },
    isGroupMaxReached(groupId) {
      const group = this.mealPlan.groups.find(g => g.id === groupId);
      if (!group) return true;

      return this.productCountByGroup(groupId) >= group.maxProducts;
    },
    async addToBasket(productId, quantity, price, productOptions = undefined, groupId) {
      try {
        let result;
        if (productOptions) {
          const formattedOptions = formatProductOptions(productOptions, productId);
          if (formattedOptions) {
            let options = formattedOptions.reduce((acc, option) => {
              const [key, value] = option.split(':');
              acc[key] = value;
              return acc;
            }, { prod: productId });

            options.kkjson = encode64(JSON.stringify(options));

            result = await basketService.addComplexProductToBasket(productId, OrderTypes.DINE_IN, false, quantity, options);
          }
        } else {
          result = await basketService.addProductToBasket(productId, OrderTypes.DINE_IN, false, quantity);
        }
        if (result.exception) {
          if (result.exception.code === 64) {
            this.makeToast('danger', this.$t('notification.addItemMinError'));
          } else if (result.exception.code === 65) {
            this.makeToast('danger', this.$t('notification.addItemMaxError'));
          } else {
            this.makeToast('danger', this.$t('notification.addItemFailed'));
          }
          return; // Don't update store if API fails
        }

        const order = this.mealPlanStore.addOrder(this.shopStore.getShopId, OrderTypes.DINE_IN, this.mealPlan.id, this.reservationDateTime);
        this.appStore.setCurrentMealPlanOrderId(order.orderId);
        console.log(`Stored orderId: ${order.orderId} in appStore`);

        this.mealPlanStore.addProductToOrder(this.appStore.currentMealPlanOrderId, productId, price, quantity, groupId);

        // Ensure the group exists
        if (!this.selectedProductsCount[groupId]) {
          this.$set(this.selectedProductsCount, groupId, {});
        }

        // Correctly update the quantity for the selected product and prevent double counting
        const currentQuantity = this.selectedProductsCount[groupId][productId] || 0;
        this.$set(this.selectedProductsCount[groupId], productId, currentQuantity + quantity);
      } catch (error) {
        console.error("Error adding product to basket:", error);
        this.makeToast('danger', this.$t('notification.addItemFailed'));
      }
    },
    removeFromBasket(productId, quantity, groupId) {
      const result = basketService.removeProductFromBasketByProductId(productId, quantity, OrderTypes.DINE_IN);
      if (result.exception) {
        const errorCode = result.exception.code;
        const errorMessages = {
          64: 'notification.removeItemMinError',
          65: 'notification.removeItemMaxError',
        };
        this.makeToast('danger', this.$t(errorMessages[errorCode] || 'notification.removeItemFailed'));
        return;
      }

      this.mealPlanStore.removeProductFromOrder(this.appStore.currentMealPlanOrderId, productId, quantity);

      if (!this.selectedProductsCount[groupId]) {
        this.$set(this.selectedProductsCount, groupId, {});
      }

      const currentQuantity = this.selectedProductsCount[groupId][productId] || 0;
      const updatedQuantity = Math.max(0, currentQuantity - quantity);
      this.$set(this.selectedProductsCount[groupId], productId, updatedQuantity);
    },
    getMealPlanStartDate(mealPlan) {
      const validDate = new Date(mealPlan.dateValid);

      if(mealPlan.formattedStart)
      {
        const [startHour, startMinute] = mealPlan.formattedStart.split(':');
        validDate.setHours(startHour, startMinute, 0, 0);
      }
      else
      {
        validDate.setHours(0, 0, 0, 0);
      }

      return validDate;
    },
    // Get the current count of a product within a group
    getCurrentProductCount(groupId, productId) {
      return this.selectedProductsCount[groupId]?.[productId] || 0;
    },
    getMaxAvailableForProduct(groupId, productId) {
      const group = this.mealPlan.groups.find(g => g.id === groupId);
      if (!group) return 0;

      const currentGroupCount = this.productCountByGroup(groupId);
      const currentProductCount = this.getCurrentProductCount(groupId, productId);

      // Remaining slots after excluding the current selection of the product
      const remainingSlots = group.maxProducts - currentGroupCount;

      // Allow adding more only if there are available slots
      return Math.max(0, remainingSlots + currentProductCount);
    },
    viewProductDetails(productId, isLocked, name, groupId) {
      if (this.isMealPlanValid && !isLocked) {

        console.log('Selected Products Count:', this.selectedProductsCount);

        this.selectedProduct = { productId, name, groupId };
        this.$bvModal.show("product-details-modal");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/css/customizer';

/* Style updates for disabled state */
.button.disabled {
  background-color: #d3d3d3; /* Light gray to indicate disabled */
  pointer-events: none;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

.meal-container {
  background-color: var(--page-container-background);
  border-radius: var(--button-border-radius);
  margin-top: 10px
}

.meal-group {
  background: custom-chain(mealplan-group-color,var(--page-group-header));
  margin: 0 auto;
  padding: 0 10px;
  border-radius: var(--button-border-radius);
}

.meal-group-title {
  font-size: 16px;
  color: custom-chain(mealplan-group-color-text,var(--page-group-title));
  display: flex;
  justify-content: space-between; /* Space between the two spans */
  align-items: center; /* Center the text vertically */
  width: 100%; /* Ensure it takes the full width */
}

.mealplan-group-requirement {
  color: custom-chain(mealplan-group-color-requirement,red);
}

.text-left {
  flex: 1; /* Allow the left text to grow */
  text-align: left; /* Align left text */
}

.text-right {
  text-align: right; /* Align right text */
}

.meal-item {
  display: flex;
  flex: 1 0 360px;
  gap: 10px;
  text-align: left;
  align-items: stretch;
  padding: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--page-group-item);
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
  height: 100%;
}

.meal-item:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
  cursor: pointer;
}

.meal-image {
  width: 120px;
  height: auto;
  border: custom-chain('mealplan-productimage-border','');
  border-radius: custom-chain('mealplan-productimage-border-radius','images-border-radius',var(--button-border-radius));
  object-fit: cover;
}

.meal-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.meal-description {
  font-weight: bold;
  text-align: left;
}

.properties {
  filter: hue-rotate(45deg);
}

.allergens, .properties {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
}

.allergen-img,
.property-img {
  max-width: 30px;
}

.meal-price {
  color: custom-chain(mealplan-meal-price-text,var(--background-text));
  font-size: 14px;
  align-self: center;
  flex-grow: 1;
  text-align: right;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
}

.button:hover,
.button:focus {
  background-color: #0056b3;
}

.button:active {
  background-color: #004494;
}
</style>
