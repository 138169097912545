// <template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="false"
             loader='bars'></loading>

    <div>
    <flow :currentPoint="4"></flow>

    <div class="col-12 checkoutPadding">
      <div class="checkoutPadding">
                <div class="col-12 chosenShopTile checkoutTilePadding" v-if="config.SHOW_SHOP_ON_PAYMENT_PAGE">
          <h2>{{shop}}</h2>
          <span v-html="shopAddress"></span>
        </div>

        <div class="col-12 chosenTimeTile checkoutTilePadding" v-if="!isImmediateMode && orderType != 'delivery'">
          <span v-html="time"></span>
          <span v-if="isTableMode">{{$t('text.tableId')}} </span>
          <span v-if="isTableMode" v-html="tableCode"></span>
        </div>

        <div class="col-12 chosenArticlesTile">

          <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; background:lightgray; display: flow-root; padding: 10px;  border-bottom:0.2px solid var(--main-color);">
            <span style="font-size:25px;">{{ getCampaignName(item.campaignId) }}</span>
            <br/>
            <span v-if="item.gainedPoints > 0">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsGained') }} </span>
                            <span style="color:var(--main-color); font-size:25px;"> {{ item.gainedPoints }} </span>
                        </span>
            <br/>
            <span v-if="item.usedPoints > 0">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsBurned') }} </span>
                            <span style="color:var(--main-color); font-size:25px;"> {{ item.usedPoints }} </span>
                        </span>

          </div>
          <br/>

          <table class="shoppingCartItemsTable"  style="table-layout:fixed" v-if="basketLoaded && basketItems.length > 0">
            <thead>
            <th class="shoppingCartHeaderBig">{{ $t('text.article') }}</th>
            <th class="shoppingCartHeaderSmall"></th>
            <th class="shoppingCartHeader">{{ $t('text.sum') }}</th>
            </thead>

            <tr v-for="item in basketItems" v-bind:key="item.id">
              <td class="shoppingCartLine">{{(item.prodText !== undefined) ? item.prodText :item.text}}
                <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                  <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                </div>
              </td>
              <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td>

              <td class="shoppingCartLine mainColor" style="font-weight:600;">
                                <span v-if="item.formattedDiscountedPrice == undefined">
                                    {{item.weightOrVolume != undefined ? item.formattedTotalPrice : item.formattedPrice}}
                                    <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}}
                                    </span>
                                </span>
                <span v-if="item.formattedTotalDiscountedPrice != undefined">
                                    {{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedTotalDiscountedPrice}}
                                    <span v-if="item.weightOrVolume == undefined" style="color:gray;text-decoration: line-through;">
                                        {{ item.formattedTotalPrice }}
                                    </span>
                                    <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedDiscountedPrice + "/kg" : ""}}
                                    </span>
                                </span>
              </td>
            </tr>
          </table>

          <table class="shoppingCartSummaryTable" v-if="basketLoaded && basketItems.length > 0">
            <thead>
            <th style="width:70%"></th>
            <th style="width:30%"></th>
            </thead>
            <tr>
              <td class="shoppingCartSummaryLine">{{ $t('text.sum') }}</td>
              <td class="shoppingCartSummaryLine">{{basketTile.formattedTotal}}</td>
            </tr>
            <tr v-if="basketTile.paymentsList != undefined">
              <td class="shoppingCartLine" style="color:gray;">{{$t('subTitle.subsidy')}}</td>
              <td class="shoppingCartLine" style="color:gray;">{{formatCurrency(basketTile.paymentsList[0].paymentAmount)}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="isTableMode && !isImmediateMode" class="col-12 customerCardBalance checkoutPadding">
        <p class="customerCardBalanceLabel col-4">{{ $t('text.customerCardBalance') }}</p>
        <p class="customerCardBalanceLabel col-8">{{ Utils.formatCurrency(prepayBalanceCash, config.ACCOUNT_BALANCE_CURRENCY) }}</p>
        <!-- TODO refactor modal topup for reuse at this part
        <b-button v-if="config.TOPUP.enabled" class="customerCardBalanceButton btn btn-primary" @click="$refs.topUpModal.openModal()">{{ $t('button.charge') }}</b-button>
        <p v-if="config.TOPUP.enabled != true" class="col-4"></p> -->
      </div>
      <div class="col-12 checkoutPadding" id="paymentMethods" v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)">
        <b-button v-if="payment.paymentType == 1" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ payment.description }}</b-button>
        <b-button v-if="payment.paymentType == 4" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ $t('button.creditcard') }}</b-button>
        <b-button v-if="payment.paymentType == 5" style="margin-top: auto" class="btn btn-primary col-12" :disabled="prepayBalanceCash == 0 || prepayBalanceCash < basketTile.basketTotal" v-on:click="pay(payment)">{{ $t('button.customerCard') }}</b-button>
        <b-button v-if="payment.paymentType == 50" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ payment.description }}</b-button>
        <b-button v-if="showFreeButton && payment.code == 'free'" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ $t('button.submit') }}</b-button>
      </div>
      <div class="col-12 checkoutPadding">
        <b-button class="col-12 btn btn-primary" style="margin-top: auto" @click="payLater" v-if="config.QRLANDING_OPEN_TABLES_ENABLE == true">{{$t('button.payLater')}}</b-button>
      </div>
    </div>

    <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-saferpay>
    <credit-card-payment-securepay v-if='showSecurepay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-securepay>
    <credit-card-payment-novalnet v-if='showNovalnet == true' :subCode="currentPaymentSubCode" ></credit-card-payment-novalnet>
    <credit-card-payment-payone v-if='showPayone == true' :subCode="currentPaymentSubCode" :callback="closePayOne" ></credit-card-payment-payone>
    <credit-card-payment-adyen v-if='showAdyen == true'></credit-card-payment-adyen>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import CreditCardPaymentComponentSaferpay from '../main-components/CreditCardPaymentComponentSaferpay.vue'
import CreditCardPaymentComponentSecurepay from '../main-components/CreditCardPaymentComponentSecurepay.vue'
import CreditCardPaymentComponentNovalnet from '../main-components/CreditCardPaymentComponentNovalnet.vue'
import CreditCardPaymentComponentPayone from '../main-components/CreditCardPaymentComponentPayone.vue'
import CreditCardPaymentComponentAdyen from '../main-components/CreditCardPaymentComponentAdyen'
import VueLoading from 'vue-loading-overlay'
import FlowIndicator from '../main-components/FlowIndicator.vue'
import Utils from "../../utils/utils";
import {accountService} from "@/services/api/accountService";
import {paymentService} from "@/services/api/paymentService";
import {Mode, Order, PaymentProviders, Workflow} from "@/constants/constants";
import {useBasketStore} from "@/stores/basket";
import {orderService} from "@/services/api/orderService";
import {useAppStore} from "@/stores/app";
import {useOrderStore} from "@/stores/order";
import {useMealPlanStore} from "@/stores/mealPlan";

export default {
  data() {
    return {
      prepayBalanceCash: 0,
      showSaferpay: false,
      showSecurepay: false,
      showNovalnet: false,
      showPayone: false,
      showAdyen: false,
      paymentList: [],
      currentPayment: "",
      paymentFinished: false,
      isLoading: false,
      shop: "",
      shopAddress: "",
      time: "",
      basketTile: {},
      // pointCampaigns: [],
      pointCampaignsDefinitions: [],
      basketLoaded: false,
      showFreeButton: false,
      currentPaymentSubCode: "",
      firstClickDone: false,
      orderType: null,
      tableCode: null
    }
  },
  components: {
    'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
    'credit-card-payment-securepay': CreditCardPaymentComponentSecurepay,
    'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
    'credit-card-payment-payone': CreditCardPaymentComponentPayone,
    'credit-card-payment-adyen': CreditCardPaymentComponentAdyen,
    'loading': VueLoading,
    'flow' : FlowIndicator
  },
  setup() {
    const basketStore = useBasketStore();
    const appStore = useAppStore();
    const orderStore = useOrderStore();
    const mealPlanStore = useMealPlanStore();

    return {
      basketStore,
      appStore,
      orderStore,
      mealPlanStore,
    };
  },
  computed:{
    Utils() {
      return Utils
    },
    currentlySelectedPayment (){
      if(this.currentPayment == "Saferpay")
      {
        console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
        return this.currentPayment + "~~" + this.currentPaymentSubCode;
      }
      else if(this.currentPayment == "PayOne")
      {
        if(this.currentPaymentSubCode !== "")
        {
          return this.currentPayment + "~~" + this.currentPaymentSubCode;
        }
        else
        {
          return this.currentPayment;
        }
      }
      else
      {
        return this.currentPayment;
      }
    },
    pointCampaigns(){
      return this.basketTile.pointCampaigns;
    },
    basketItems(){
      return this.basketTile.basketItems;
    },
    isTableMode() {
      return this.appStore.isMode(Mode.TABLE);
    },
    isImmediateMode() {
      return this.appStore.isMode(Mode.IMMEDIATE);
    },
    config(){
      return window.config;
    },
  },
  async created() {
    const result = await accountService.afterLogin();

    if (result.exception !== undefined) {
      this.$router.go(-1);
      return;
    }

    if (result.wondCustomer != undefined) {
      var config = window.config;
      if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
        this.prepayBalanceCash = (result.wondCustomer.creditLimit - result.wondCustomer.creditBalance).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
      } else {
        this.prepayBalanceCash = (result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
      }
    }

    let order;
    if (this.appStore.isWorkflow(Workflow.MEAL_PLAN)) {
      order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
    } else if (this.appStore.isWorkflow(Workflow.ORDER)) {
      order = this.orderStore.getOrder(this.appStore.currentOrderId);
    }
    this.orderType = order.orderType;
    this.tableCode = order.tableCode;

    await this.confirmOrder(order);

    this.pointCampaignsDefinitions = window.pointCampaignsDefinitions;
  },
  methods:{
    promotionBarcode() {
      return this.basketStore.getPromotionBarcode;
    },
    comment() {
      return this.basketStore.getComment;
    },
    closePayOne(status){
      if(status >= 0)
      {
        this.$navigateToCheckoutFinished(true);
      }
    },
    formatCurrency(value){
      // switch (this.config.) {
      //     case value:

      //         break;

      //     default:
      //         break;
      // }
      // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
      // return formatter.format(value);
      var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
      return formatter.format(value);
    },
    getCampaignName(campaignId){
      for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
      {
        if(this.pointCampaignsDefinitions[i].id == campaignId)
        {
          return this.pointCampaignsDefinitions[i].description;
        }
      }

      return "";
    },
    async preparePayments() {
      const result = await paymentService.getPaymentMethods();

      this.shopAddress = result.formattedShopAddr;
      this.shop = result.shopName;
      this.time = result.formattedPickupTime;
      window.orderDateTime = result.formattedPickupTime;

      if (result.paymentDetails == undefined) {
        this.showFreeButton = true;
        return;
      } else {
        this.showFreeButton = false;
      }

      this.paymentList = result.paymentDetails;

      if (this.paymentList.length == 1) {
        var element = new Object;
        element.value = this.paymentList[0].code;
        element.subCode = this.paymentList[0].subCode;
        console.log(element);
        this.onPaymentSelect(element);
      }

      window.onmessage = async function (e) {
        let result = e.data.toString();
        if (result == "S" || result == "A" || result == "F" || result.startsWith("payoneA") || result.startsWith("payoneB") || result.startsWith("payoneD")) {
          this.isLoading = true;
        }

        if (result == "S") {
          if (this.currentPaymentSubCode == "T") {
            await paymentService.processPayment(PaymentProviders.SAFERPAY, { vresult: result });
            this.isLoading = false;
          }
          this.$navigateToCheckoutFinished();
        } else if (result == "A") {
          this.showSaferpay = false;
          this.showSecurepay = false;
          this.showNovalnet = false;
          this.showPayone = false;
          this.showAdyen = false;
          this.isLoading = false;

        } else if (result == "F") {
          this.makeToast('danger', this.$t('notification.paymentError'));
          this.showSaferpay = false;
          this.showSecurepay = false;
          this.showNovalnet = false;
          this.showPayone = false;
          this.showAdyen = false;
          this.isLoading = false;

        } else if (result.startsWith("payoneA")) {
          await paymentService.processPayment(PaymentProviders.PAYONE, { vresult: result.replace('payone', '') });
          this.isLoading = false;
          this.$navigateToCheckoutFinished();
        } else if (result.startsWith("payoneB")) {
          this.makeToast('danger', this.$t('notification.paymentAborted'));
          this.showSaferpay = false;
          this.showSecurepay = false;
          this.showNovalnet = false;
          this.showPayone = false;
          this.showAdyen = false;
          this.isLoading = false;
          this.$refs.externalPaymentModal.closeModal();
        } else if (result.startsWith("payoneD")) {
          this.makeToast('danger', this.$t('notification.paymentError'));
          this.showSaferpay = false;
          this.showSecurepay = false;
          this.showNovalnet = false;
          this.showPayone = false;
          this.showAdyen = false;
          this.isLoading = false;
          this.$refs.externalPaymentModal.closeModal();
        } else if (result.startsWith("novalnet")) {
          this.isLoading = false;
          this.$navigateToCheckoutFinished();
        }
      }
    },
    async confirmOrder(order) {
      // TODO window variables and table mode
      let params = {
        tableId: order.tableId ? order.tableId : undefined,
        mealPlanId: order.mealPlanId ? order.mealPlanId : undefined,
        reservationDateTime: order.reservationDateTime ? order.reservationDateTime : undefined,
      };

      this.isLoading = true;
      const result = await orderService.confirm(order.orderType, this.comment(), this.promotionBarcode(), true, params)
      if (result.exception !== undefined) {
        this.isLoading = false;
        return;
      }

      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

      this.basketTile = result.basketTile;
      this.isLoading = false;

      if (result.basketTile.pointCampaigns != undefined) {
        this.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
        this.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
      }

      if (this.appStore.isMode(Mode.IMMEDIATE)) {
        let pickupOrderNumber = result.pickupNumber;
        if (pickupOrderNumber !== undefined) {
          if (this.appStore.isWorkflow(Workflow.ORDER)) {
            if (order) {
              this.orderStore.setPickupNumberForOrder(order.orderId, pickupOrderNumber);
            }
          } else if (this.appStore.isWorkflow(Workflow.MEAL_PLAN)) {
            if (order) {
              this.mealPlanStore.setPickupNumberForOrder(order.orderId, pickupOrderNumber);
            }
          }
        }
      }

      this.basketLoaded = true;

      await this.preparePayments();
    },
    onPaymentSelect(element){
      $(":checkbox[ident!=" + element.value.replace('~~', "") + "]").prop("checked", false);
      this.showSaferpay = false;
      this.showSecurepay = false;
      this.showNovalnet = false;
      this.showPayone = false;
      this.showAdyen = false;

      this.currentPayment = element.value;
      console.log("current payment method: " + this.currentPayment);
      console.log("current subcode:" + element.subCode);

      if(element.subCode != undefined)
      {
        if(element.subCode == "T")
        {
          this.currentPaymentSubCode = element.subCode;
        }
        else if(element.subCode == "P")
        {
          this.currentPaymentSubCode = element.subCode;
        }
            // else if(element.subCode == "PDT")
            // {
            //     this.currentPaymentSubCode = element.subCode;
        // }
        else
        {
          element.subCode = "";
          this.currentPaymentSubCode = "";
        }
      }
      else
      {
        element.subCode = "";
        this.currentPaymentSubCode = "";
      }

      if(this.currentlySelectedPayment != "customercard")
      {
        this.onSubmit(true);
      }
    },
    async onSubmit(checkCod) {
      // AP 20220504 The vue-loading-overlay does not always correctly block multiple submits
      // Therefore a second failsafe check is needed to stop multiple submits.
      if (this.isLoading) {
        return;
      }

      this.showSaferpay = false;
      this.showSecurepay = false;
      this.showNovalnet = false;
      this.showPayone = false;
      this.showAdyen = false;

      if (this.currentlySelectedPayment == "customercard" && this.basketTile.basketTotal > this.prepayBalanceCash) {
        this.makeToast('danger', this.$t("balanceNotEnough"));
        return;
      }

      if ((this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment == "customercard" || this.currentlySelectedPayment == "TCPDebitor") && checkCod) {
        return;
      }

      this.isLoading = true;

      const result = await paymentService.pay(this.currentlySelectedPayment);
      if (result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T") {
        this.isLoading = false;
        console.log(this.currentPayment);
        this.showSaferpay = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Securepay") {
        this.isLoading = false;
        console.log(this.currentPayment);
        this.showSecurepay = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Novalnet") {
        this.isLoading = false;
        console.log(this.currentPayment);
        this.showNovalnet = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "PayOne") {
        this.showPayone = true;
        console.log(this.currentPayment);
        this.isLoading = false;
        this.$forceUpdate();

        setTimeout(() => {
          var objDiv = document.getElementsByClassName("container")[0];
          var iFrame = document.getElementById("payoneCheckoutContainer");
          objDiv.scrollTop = objDiv.scrollHeight;
          iFrame.scrollIntoView({behavior: "smooth"});
        }, 300);

        return;
      }

      if (result.paymentMethod == "Adyen") {
        this.isLoading = false;
        console.log(this.currentPayment);
        this.showAdyen = true;
        this.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "StripeModule") {
        return;
      }

      if (result.exception !== undefined) {
        this.isLoading = false;
        this.makeToast('danger', this.$t("exceptionMessage"));
        return;
      }

      if (result.page == "tcp.catalog.checkoutfinished.page") {
        if (this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment == "customercard" || this.currentlySelectedPayment == "free") {
          window.shoppingCart = null;

          await orderService.checkout();
        }
        this.isLoading = false;

        window.shoppingCart = undefined;

        if (this.appStore.isWorkflow(Workflow.MEAL_PLAN)) {
          this.mealPlanStore.setStateForOrder(this.appStore.currentMealPlanOrderId, Order.state.FINISHED);
          this.$navigateToMealPlanner(true);
        } else if (this.appStore.isWorkflow(Workflow.ORDER)) {
          this.$navigateToCheckoutFinished();
        }
        return
      }

      this.isLoading = false;
      window.shoppingCart = null;

      this.$navigateToOrderPayment();
    },
    async payLater() {
      this.isLoading = true;

      const result = await paymentService.pay("cod");
      if (result.exception !== undefined) {
        this.isLoading = false;
        this.makeToast('danger', this.$t("exceptionMessage"));
        return;
      }

      if (result.page == "tcp.catalog.checkoutfinished.page") {
        if (this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment == "customercard") {
          await orderService.checkout();
        }

        this.isLoading = false;

        window.shoppingCart = undefined;

        this.$navigateToCheckoutFinished();
        return;
      }

      this.isLoading = false;
      window.shoppingCart = null;
      this.$router.push({path: '/Payment'});
    },
    pay(payment)
    {
      if (payment.subCode != undefined)
      {
          this.currentPaymentSubCode = payment.subCode;
      }
      this.currentPayment = payment.code;

      let checkCod = true;
      if (payment.code == 'code' || payment.code == 'customercard' || payment.code == 'free' || payment.code == 'TCPDebitor')
      {
          checkCod = false;
      }
      this.onSubmit(checkCod);
    }
  },
}
</script>

<style scoped>
.chosenShopTile{
  border: 1px solid var(--body-color);
  border-radius:5px;
  margin:5px;
}

.chosenTimeTile {
  background-color: var(--body-color);
  border-radius:5px;
  margin: 5px;
}

.chosenArticlesTile {
  background-color: var(--body-color);
  border-radius:5px;
  margin: 5px;
  text-align: left;
}

.shoppingCartHeader{
  width:30%;
}

.shoppingCartLine{
  height:40px;
}

.shoppingCartHeaderBig{
  width:60%;
}

.shoppingCartHeaderSmall{
  width:10%;
}

.shoppingCartItemsTable{
  width:100%;
}

.shoppingCartSummaryTable{
  width:100%;
  border-top: 1px solid var(--main-color);
}

.shoppingCartSummaryLine{
  font-weight:600;
}

@media screen and (max-width:768px) {
  .chosenShopTile{
    margin:0px;
    margin-bottom:5px;
  }
  .chosenTimeTile {
    margin:0px;
    margin-bottom:5px;
  }
  .chosenArticlesTile {
    margin:0px;
    margin-bottom:5px;
  }
}

.forwardBackward {
  border-top: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
</style>
