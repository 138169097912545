<template>
  <div class="dropdown-container">
    <!-- Accessible Label -->
    <label :for="dropdownId" class="dropdown-label" v-if="typeof label === 'string'&&label.length > 0">{{ label }}</label>

    <!-- Dropdown with ARIA attributes for accessibility -->
    <select
        :id="dropdownId"
        v-model="selectedValue"
        @change="handleSelectionChange"
        @blur="validateSelection"
        :class="{ 'is-invalid': showError }"
        :aria-invalid="showError ? 'true' : 'false'"
        :aria-required="required"
        :aria-describedby="showError ? errorMessageId : null"
        :title="showError ? errorMessage : ''"
    >
      <option value="" disabled selected>{{ placeholder }}</option>
      <option v-for="option in sortedOptions"
              :key="option.value"
              :value="option.value"
              :disabled="option.disabled"
      >
        {{ option.text }}
      </option>
    </select>

    <!-- Accessible Error Message -->
    <span
        v-if="showError"
        :id="errorMessageId"
        class="error-message"
        role="alert"
        aria-live="assertive"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  event:['input','selection-changed'],
  props: {
    label: String,
    /** @type {Array<DropdownOption>} */
    options: {
      type: Array,
      required: true,
    },
    value: [String || Number],
    required: Boolean,
    placeholder: String,
    sortable: {
      type: String,
      default: '', // Accept 'asc', 'desc', or '' (no sorting)
      validator: (value) => ['asc', 'desc', ''].includes(value),
    },
  },
  data() {
    return {
      selectedValue: this.value,
      showError: false,
      errorMessage: '',
    };
  },
  computed: {
    dropdownId() {
      return `dropdown-${this._uid}`; // Unique ID for accessibility
    },
    errorMessageId() {
      return `error-${this._uid}`; // Unique ID for error message
    },
    sortedOptions() {
      if (this.sortable === 'asc') {
        return [...this.options].sort((a, b) => a.text.localeCompare(b.text));
      } else if (this.sortable === 'desc') {
        return [...this.options].sort((a, b) => b.text.localeCompare(a.text));
      }
      return this.options;
    },
  },
  methods: {
    validateSelection() {
      this.showError = false;
      this.errorMessage = '';

      if (this.required && !this.selectedValue) {
        this.showError = true;
        this.errorMessage = 'This field is required.';
      }

      // Emit input event to parent
      this.$emit('input', this.selectedValue);
    },
    handleSelectionChange() {
      // Emit change event with the selected value
      this.$emit('selection-changed', this.selectedValue);
    },
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue; // Sync the v-model value
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}

.dropdown-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

select {
  width: 100%;
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
  height: calc(1.5em + 0.75rem + 2px);
}

select:focus {
  border-color: #007BFF;
}

.is-invalid {
  border-color: red;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
}
</style>
