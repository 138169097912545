import { defineStore } from 'pinia';
import { removeTimerBase } from "@/store/SessionTimer";
import {Login} from "@/constants/constants";

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    mode: null, // options set in constants.js Login.Mode
    loginType: null, // options set in constants.js Login.UserType
  }),

  getters: {
    isLoggedIn: (state) => state.isAuthenticated,
    isGuest: (state) => state.loginType === Login.UserType.GUEST_USER,
    isUser: (state) => state.loginType === Login.UserType.REGISTERED_USER,
  },

  actions: {
    setAuthenticated(isAuthenticated, mode = Login.Mode.STANDARD, type = Login.UserType.REGISTERED_USER) {
      this.isAuthenticated = isAuthenticated;
      this.mode = isAuthenticated ? mode : null;
      this.loginType = isAuthenticated ? type : null;
      if (!isAuthenticated) {
        removeTimerBase();
      }
    },
    reset() {
      this.isAuthenticated = false;
      this.mode = null;
      this.loginType = null;
      removeTimerBase();
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: 'auth',
        storage: localStorage
      },
    ],
  },
});
