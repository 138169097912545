import { defineStore } from 'pinia';

export const useShopStore = defineStore('shop', {
  state: () => ({
    id: null,
    wondId: null,
    wondCode: null,
    name: null,
    imageUrl: null,
    notes: null,
  }),

  actions: {
    setWondCode(wondCode) {
      this.wondCode = wondCode;
    },
    setShop(id, wondId, wondCode, name, imageUrl, notes) {
      this.id = id;
      this.wondId = wondId;
      this.wondCode = wondCode;
      this.name = name;
      this.imageUrl = imageUrl;
      this.notes = notes;
    },
    resetShop() {
      this.id = null;
      this.wondId = null;
      this.wondCode = null;
      this.name = null;
      this.imageUrl = null;
      this.notes = null;
    }
  },

  getters: {
    getShopId: state => state.id,
    getWondId: state => state.wondId,
    getWondCode: state => state.wondCode,
    getShopName: state => state.name,
    getShopImageUrl: state => state.imageUrl,
    getShopNotes: state => state.notes,
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: 'shop',
        storage: localStorage,
      },
    ],
  },
});
