export function formatLabelWithPrice(label, object, hidePrice = false) {
  if (!object || object.virtual || object.price <= 0 || !window.config.EXTRAS_SHOW_PRICE) {
    return label;
  }

  if (hidePrice) {
    return label;
  }

  const formattedPrice = `${window.config.EXTRAS_SHOW_PRICE_PREFIX} ${object.formattedPrice}`;
  return `${label} (${formattedPrice})`;
}

/** @param {Array<ProductOption>} productOptions */
export function formatProductOptions(productOptions, parentProdId = null, counter = { value: 0 }) {
  let formattedStrings = [];
  let lastOptionProdId = parentProdId; // Track the immediate parent // btw this value will always be ignored / overridden, since level 1 does not useit

  productOptions.forEach((option) => {
    const isSubOpt = option.level > 2;
    const prefix = isSubOpt ? "subOpt" : "opt";

    const currentProdId = option.prodId;

    // For sub-options, assign the last valid parent (immediate upper level)
    const relatedProdId = isSubOpt ? lastOptionProdId : currentProdId;

    const formattedString = `${prefix}_${option.minQty}_${option.maxQty}_${option.id}_${currentProdId}_${counter.value}:${relatedProdId}`;
    formattedStrings.push(formattedString);

    counter.value++;

    // Update lastOptionProdId to track the correct parent for deeper levels
    lastOptionProdId = currentProdId;

    // Pass lastOptionProdId instead of currentProdId to sub-options
    if (option.options && option.options.length > 0) {
      const nestedFormatted = formatProductOptions(
        option.options,      // Sub-options list
        lastOptionProdId,    // Correctly track the immediate parent
        counter
      );
      formattedStrings.push(...nestedFormatted);
    }
  });

  return formattedStrings;
}
