<template>
  <div>
    <img id="myImage" :src="backgroundImagePath">

    <div>
      <div class="col-12" style="padding-top:15%">
        <img style="max-width:150px;width:100%" :src="logoPath"/>
      </div>

      <div class="col-12 fontTitle fontLandingTitle">
        {{ $t('title.landingPage') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="tableCode">
        <p>{{ $t('text.landingPageWithTableId', {table: tableCode}) }}</p>
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="orderImmediate && ordersPossible">
        {{ $t('text.landingPageWithoutTableId') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="orderImmediate && !ordersPossible">
        {{ $t('text.landingPageBlockedText') }}
      </div>

      <div class="col-12 btnLandingWrapper" v-if="ordersPossible">
        <b-button class="btn btn-primary btnLanding" @click="doNavigationByAppMode">{{ $t('button.landingOrder') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Mode, Workflow} from "@/constants/constants";
import {useLocaleStore} from "../../stores/locale";
import {useAppStore} from "../../stores/app";
import {useOrderStore} from "../../stores/order";
import {useShopStore} from "@/stores/shop";
import orderMixin from "@/components/mixins/orderMixin";

export default {
  mixins: [orderMixin],
  data() {
    return {
      ordersPossible: true,
    };
  },
  props: {
    shopCode: {
      Type: Number
    },
    tableCode: {
      Type: String
    },
    orderImmediate: {
      Type: Boolean
    },
    isMealPlan: {
      Type: Boolean
    },
  },
  setup() {
    const localeStore = useLocaleStore();
    const appStore = useAppStore();
    const orderStore = useOrderStore();
    const shopStore = useShopStore();

    return {
      localeStore,
      appStore,
      orderStore,
      shopStore,
    };
  },
  computed: {
    backgroundImagePath() {
      return process.env.BASE_URL + 'settings/resources/LandingBackground.jpg';
    },
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
  },
  methods: {
    async changeLanguage(newLocale) {
      await this.localeStore.setLocale(newLocale);
    },
    async doNavigationByAppMode() {
      if (this.shopCode) {
        if (this.orderImmediate) {
          await this.doImmediateOrder();
          await this.doCheckIfOrdersAreCurrentlyPossible();
          this.$navigateToMenu(true);
        } else if (this.tableCode) {
          Object.assign(window.config, window.configTablemode);

          await this.selectTableByQRCode(this.shopCode, this.tableCode);
          this.$navigateToMenu(true);
        } else {
          this.shopStore.setWondCode(this.shopCode);
          if (this.isMealPlan) {
            this.$navigateToMealPlanner(true);
          } else {
            this.$navigateToShop(true);
          }
        }
      } else {
        this.$navigateToErrorLanding();
      }
    },
  },
  created() {
    if (this.shopCode) {
      if (this.orderImmediate) {
        this.appStore.setWorkflow(Workflow.ORDER);
        this.appStore.setMode(Mode.IMMEDIATE);
      } else if (this.tableCode) {
        this.appStore.setWorkflow(Workflow.ORDER);
        this.appStore.setMode(Mode.TABLE);
      } else {
        if (this.isMealPlan) {
          this.appStore.setWorkflow(Workflow.MEAL_PLAN);
        } else {
          this.appStore.setWorkflow(Workflow.ORDER);
        }
        this.appStore.setMode(Mode.SHOP);
      }
    }
  }
};
</script>
