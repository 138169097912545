<template>
  <section class="basket-container" aria-labelledby="basket-summary-title">
      <!--<h2>Your Basket</h2>-->

    <div class="basket-list">
      <BasketProduct
        v-for="product in basketItems"
        :key="product.id"
        :product="product"
      />
    </div>

    <!-- Basket Total -->
    <h3 class="basket-total normalText mb-0">
      <span>{{ $t('text.sum') }}:</span>
      <span class="total-price">{{ formattedTotal }}</span>
    </h3>
  </section>
</template>

<script>
import BasketProduct from "@/components/sub-components/Basket/BasketProduct.vue";
import basketMixin from "@/components/mixins/basketMixin";

export default {
  mixins: [basketMixin],
  components: {
    BasketProduct,
  },
  props: {
    orderType: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.fetchBasketTile(this.orderType);
  },
};
</script>

<style scoped>
.basket-container {
  padding: 16px;
  background: #fff;
  border-radius: 8px; /* any reason to be round? */
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*/
}

.basket-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.basket-total {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  padding: 12px;
}

.total-price {
  color: #0057b8;
}
</style>
