<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader="bars"></loading>

    <FlexContainer v-if="weeks" :title="$t('subTitle.mealPlanDownloads')" class="mb-2" #content>
      <div class="d-flex flex-column noPadding text-left gap10">
        <Button
            v-for="(week, index) in weeks"
            :key="index"
            center icon="download" lg
            @click="handleDownload(week)">
          {{ formatWeekLabel(week) }}
        </Button>
      </div>
    </FlexContainer>
  </div>
</template>

<script>
import { useMealPlanStore } from "@/stores/mealPlan";
import Button from "@/components/barrier-free-template-components/Button.vue";
import { useShopStore } from "@/stores/shop";
import { useAppStore } from "@/stores/app";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import {getCurrentWeekdays, addDays, formatFullDate} from "@/utils/dateUtils";
import { productService } from "@/services/api/productService";
import loading from "vue-loading-overlay";

export default {
  components: { loading, FlexContainer, Button },
  inject: ["setPageMetaTitle"],
  data() {
    return {
      isLoading: false,
      weeks: [],
      numWeeks: window.config.MEAL_PLAN.download.weeks,
    };
  },
  setup() {
    const appStore = useAppStore();
    const mealPlanStore = useMealPlanStore();
    const shopStore = useShopStore();
    return { appStore, mealPlanStore, shopStore };
  },
  async created() {
    await this.setPageMetaTitle(this.shopStore.name);
    this.prepareDownloads();
  },
  methods: {
    async handleDownload(week) {
      this.isLoading = true;
      await productService.downloadMealPlansAsPDF(week.start, week.end);
      this.isLoading = false;
    },
    prepareDownloads() {
      this.weeks = [];
      let currentDate = new Date();

      for (let i = 0; i < this.numWeeks; i++) {
        const weekdays = getCurrentWeekdays(currentDate, window.config.MEAL_PLAN.weekDays);
        this.weeks.push({ start: weekdays[0], end: weekdays[weekdays.length - 1] });
        currentDate = addDays(currentDate, 7);
      }
    },
    formatWeekLabel(week) {
      return `${formatFullDate(week.start)} - ${formatFullDate(week.end)}`;
    },
  },
};
</script>

<style scoped>
input {
  margin-top: 10px;
  padding: 5px;
  width: 60px;
}
</style>
