import { apiUtils } from "@/utils/apiUtils";
import {useAuthStore} from "@/stores/auth";
import {removeTimerBase} from "@/store/SessionTimer";
import Utils from "@/utils/utils";
import {useMealPlanStore} from "@/stores/mealPlan";
import {useOrderStore} from "@/stores/order";

export const accountService = {
  async beforeLogin() {
    try {
      const result = await apiUtils.doGet('TCPLoginJ.action', {
        json: "true"
      });

      return result;
    } catch (error) {
      console.error("Error on before login:", error);
      throw error;
    }
  },
  async login(emailAddr, password, responseToken, rememberMe) {
    try {
      const result = await apiUtils.doPost('/TCPLoginSubmitJ.action', {
        json: "true",
        loginUsername: emailAddr,
        password: password,
        responseToken: responseToken != "" ? responseToken : undefined,
        rememberMe: rememberMe
      });

      return result;
    } catch (error) {
      console.error("Error on customer login:", error);
      throw error;
    }
  },
  async loginWithJWT(jwt, code, shopCode, validationType) {
    try {
      const result = await apiUtils.doPost('/TCPLoginWithJWTJ.action', {
        jwt: jwt,
        code: !Utils.isEmpty(code) ? code : undefined,
        shopCode: !Utils.isEmpty(shopCode) ? shopCode : undefined,
        validationType: !Utils.isEmpty(validationType) ? validationType : undefined,
        returnCustomerData: "true"
      });

      return result;
    } catch (error) {
      console.error("Error on customer sso login:", error);
      throw error;
    }
  },
  async afterLogin() {
    try {
      const result = await apiUtils.doGet('/TCPAfterLoginJ.action', {
        json: "true",
      });

      return result;
    } catch (error) {
      console.error("Error on after login:", error);
      throw error;
    }
  },
  async logout() {
    try {
      const result = await apiUtils.doGet('/TCPLogoutJ.action', {});
      if (result.errorCode < 0) {
        throw new Error(result.errorMsg || "Logout failed");
      }

      const authStore = useAuthStore();
      authStore.reset();

      const orderStore = useOrderStore();
      orderStore.reset();

      const mealPlanStore = useMealPlanStore();
      mealPlanStore.reset();

      removeTimerBase();

      return result;
    } catch (error) {
      console.error("Error on logout", error);
      return { error: true, message: error.message || "Unknown error" };
    }
  },
  async selectChild(id) {
    try {
      const result = await apiUtils.doGet('/TCPSelectChildJ.action', {
        json: "true",
        wondChildId: id
      });

      return result;
    } catch (error) {
      console.error("Error select child:", error);
      throw error;
    }
  },
  async getCountries(countryChange) {
    try {
      const result = await apiUtils.doGet('/TCPCustomerRegistrationJ.action', {
        json: "true",
        countryChange: countryChange
      });

      return result;
    } catch (error) {
      console.error("Error on get countries for registration:", error);
      throw error;
    }
  },
  async register(responseToken, userType, gender, firstName, lastName, emailAddr, cardNumber, customerCode,
                 streetAddress, postcode, city, countryId, telephoneNumber, notes1, notes2, notes3, notes4, otherInfos,
                 password, allowNoRegister) {
    try {
      const result = await apiUtils.doPost('/TCPCustomerRegistrationSubmitJ.action', {
        json: "true",
        responseToken: responseToken,
        userType: userType,
        gender: gender || '-',
        firstName: firstName || '---',
        lastName: lastName || '---',
        emailAddr: emailAddr,
        cardNumber: cardNumber,
        customerCode: customerCode,
        streetAddress: streetAddress || '---',
        postcode: postcode || '---',
        city: city || '---',
        countryId: countryId,
        telephoneNumber: telephoneNumber || '---',
        notes1: notes1,
        notes2: notes2,
        notes3: notes3,
        notes4: notes4,
        otherInfos: otherInfos,
        password: password,
        privacy: '1',
        tc: '1',
        allowNoRegister: allowNoRegister,
      });

      return result;
    } catch (error) {
      console.error("Error on customer registration:", error);
      throw error;
    }
  },
  async resetPassword(emailAddr, responseToken) {
    try {
      const result = await apiUtils.doPost('/TCPForgotPasswordSubmitJ.action', {
        json: "true",
        emailAddr: emailAddr,
        responseToken: responseToken != "" ? responseToken : undefined
      });

      return result;
    } catch (error) {
      console.error("Error on reset password:", error);
      throw error;
    }
  },
  async getShortQRCode(code) {
    try {
      const result = await apiUtils.doGet('/TCPGetShortQRCodeJ.action',{
        json: "true",
        code: code
      });
      return result;
    } catch (error) {
      console.error("Error on getting short QR-Code:", error);
      throw error;
    }
  },
  async changePassword(passwordOld, passwordNew, passwordNewRepeat) {
    try {
      const result = await apiUtils.doPost('/TCPChangePasswordSubmitJ.action', {
        json: "true",
        currentPassword: passwordOld,
        password: passwordNew,
        passwordConfirmation: passwordNewRepeat,
      });
      return result;
    } catch (error) {
      console.error("Error on changing password:", error);
      throw error;
    }
  },
  async enable(key, m, i) {
    try {
      const result = await apiUtils.doPost('/TCPEnableCustomerJ.action', {
        json: "true",
        key: key,
        m: m,
        i: i
      });
      return result;
    } catch (error) {
      console.error("Error on changing password:", error);
      throw error;
    }
  },
};
