import Vue, {nextTick} from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import merge from 'lodash/merge';

Vue.use(VueI18n);

export async function loadLocaleMessages(i18n, locale) {
  const localeMap = {
    'en_GB': 'en',
    'de_DE': 'de',
    'fr_FR': 'fr',
  };
  const defaultMessagesResponse = await axios.get(`${process.env.BASE_URL}settings/messages/default/${localeMap[locale]}.json`);
  const customMessagesResponse = await axios.get(`${process.env.BASE_URL}settings/messages/custom/${localeMap[locale]}.json`);
  const defaultMessages = defaultMessagesResponse.data;
  const customMessages = customMessagesResponse.data;

  i18n.setLocaleMessage(locale, merge({}, defaultMessages, customMessages));

  return nextTick();
}

const i18n = new VueI18n({
  locale: window.config.LANGUAGE_LIST[0].value,
  fallbackLocale: window.config.LANGUAGE_LIST[0].value,
  messages: {},
});

export default i18n;
