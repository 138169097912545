/** @typedef {{group:ProductDetailGroupNode,node:ProductDetailItemNode,path:String,parent:RecursiveItem|undefined}} RecursiveItem */
/** @typedef {function(RecursiveItem,path:String):boolean} visitor */

/**
 * @param {Array<ProductDetailGroupNode>} structurePart
 * @param {StructureEditSettings} structureSetting
 * @param {visitor} visitor
 * */
const transformStructure = function (structurePart, structureSetting, visitor) {
  const join = (a,b)=>{return (typeof a==='string'?a+'.':'')+b;}
  let value = [];

  const visitGroup = function (path, groupStructure, parent) {
    const groupId = join(path, groupStructure[structureSetting.idPropGroup]);
    const groupChilds = groupStructure[structureSetting.childPropGroup];

    for (const child of groupChilds) {
      visitNode(groupId, child, groupStructure, parent);
    }
  }

  const visitNode = function (path, nodeStructure, groupStructure, parent) {
    const nodeId = join(path, nodeStructure[structureSetting.idPropNode]);
    const me = {group:groupStructure, node:nodeStructure, path:nodeId, parent};
    const result = visitor(me,nodeId);

    if (result === false) return; // return false => skip all subItems
    if (typeof result !== 'boolean' && typeof result !== 'undefined') { // return {} => add item to value
      value.push(result);
    }

    const nodeChilds = nodeStructure[structureSetting.childPropNode];
    if (nodeChilds && nodeChilds.length > 0) {
      for (const child of nodeChilds) {
        visitGroup(nodeId, child, me);
      }
    }
  }
  for (const rootGroup of structurePart) {
    visitGroup(undefined, rootGroup);
  }
  return value;
}

export const StructureUtils = {
  /**
   * @param {string[]} values
   * @param {Array<ProductDetailGroupNode>} structure
   * @param {StructureEditSettings} structureSettings
   * @param {string} groupIdProp
   * @param {string} itemIdProp
   * */
  convertToServer(values, structure, structureSettings, groupIdProp, itemIdProp) {
    const contained = id => values.filter(a=>a.startsWith(id + '.')).length

    /** @type {Array<RecursiveItem>} */
    const candidates = transformStructure(structure, structureSettings, function visitor(item, pathId) { // visit the full structure
      item.summedItemId = String(item.node[itemIdProp]);
      if (item.parent !== undefined) {
        item.summedItemId = [item.parent.summedItemId, item.summedItemId].join('_'); // add property : "{parent.summedItemId?}_{itemId}"
      }

      if (values.includes(pathId)) {
        return item; // save candidate
      }
      if (!contained(pathId)) return false; // skip sub items
    });

    return candidates.map(function (item, index) {
      if (item.parent === undefined) { // add OPT: 'opt_{min}_{max}_{group}_{item}_{uuid}={item}'
        return [
          'opt'
          ,item.group.minQty
          ,item.group.maxQty
          ,item.group[groupIdProp]
          ,item.node[itemIdProp]
          ,index
        ].join('_') + '=' + item.node[itemIdProp];
      } else { // add subOpt: 'subOpt_{min}_{max}_{group}_{...parents.item}_{uuid}={item}
        return [
          'subOpt'
          ,item.group.minQty
          ,item.group.maxQty
          ,item.group[groupIdProp]
          ,item.parent.summedItemId
          ,index
        ].join('_') + '=' + item.node[itemIdProp];
      }
    });
  },
  /**
   * @param {string[]} values
   * @param {Array<ProductDetailGroupNode>} structure
   * @param {StructureEditSettings} structureSettings
   * @param {string} groupIdProp
   * @param {string} itemIdProp
   * */
  convertToClient(values, structure, structureSettings, groupIdProp, itemIdProp) {
    // todo: this structure is just a dummy implementation - please edit at will
    const pk = i => i.group[groupIdProp] + '.' + i.node[itemIdProp];

    const searchedValues = values.reduce(function converter(prev, curr) {
      const data = curr.replace("=", "_").split("_");
      const myPk = data.at(3) + '.' + data.at(-1); // '{groupId}.{itemId}'
      const path = data.at(0) === 'opt' ? '' : data.slice(4, -2).join('_'); // '' || '{itemId}' || '{itemId}_{itemId...}'
      if (typeof prev[myPk] === 'undefined') {
        prev[myPk] = [path];
      } else {
        prev[myPk].push(path);
      }
      return prev; // {['{groupId}.{itemId}']:['',...]}
    },{});

    return transformStructure(structure, structureSettings, function visitor(item, pathId) { // search through the whole structure
      const hasParent = item.parent !== undefined;
      item.summedItemId = String(item.node[itemIdProp]);
      if (hasParent) {
        item.summedItemId = [item.parent.summedItemId, item.summedItemId].join('_'); // add property : "{parent.summedItemId?}_{itemId}"
      }

      let s = searchedValues[pk(item)]; // this is "undefined" if we dont search this item.
      if (s !== undefined) {
        if (!hasParent && s.indexOf('') !== -1) { // search for '' in the searchedValues
          return pathId;
        } else if (hasParent && s.indexOf(item.parent.summedItemId) !== -1) {// search for '{parent.summedItemId}' in the searchedValues
          return pathId;
        }
      }
    });
  },
}
