<template>
  <div style="padding-top: 10vh;">
    <div v-if="isTopUp || isSchoolTopUp">
      <div v-if="paymentFailed">
        <p>{{ $t('notification.chargeError') }}</p>
        <Button @click="redirectTo">{{ $t('button.back') }}</Button>
      </div>
      <div v-else>
        <p>{{ $t('notification.chargeSuccessful') }}</p>
        <p v-if="redirectTimer > 0">{{ $t('text.redirectTimer') }}</p>
      </div>
    </div>
    <div v-else>
      <div v-if="paymentFailed">
        <p>{{ $t('notification.paymentError') }}</p>
        <Button @click="redirectTo">{{ $t('button.back') }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {Order, Workflow} from "@/constants/constants";
import {useAppStore} from "@/stores/app";
import {useMealPlanStore} from "@/stores/mealPlan";
import Button from "@/components/barrier-free-template-components/Button.vue";

export default {
  components: {Button},
  data() {
    return {
      paymentFailed: false,
      redirectTimer: window.config.PAYMENT.redirectTimerInSeconds, // Set the initial countdown time (in seconds)
      countdownInterval: null, // This will hold the interval ID
    };
  },
  setup() {
    const appStore = useAppStore();
    const mealPlanStore = useMealPlanStore();

    return {
      appStore,
      mealPlanStore,
    };
  },
  props: {
    payment: {
      Type: String,
    },
    result: {
      Type: String,
    },
  },
  computed: {
    isTopUp() {
      return this.appStore.isWorkflow(Workflow.TOP_UP);
    },
    isSchoolTopUp() {
      return this.appStore.isWorkflow(Workflow.SCHOOL);
    },
    isMealPlan() {
      return this.appStore.isWorkflow(Workflow.MEAL_PLAN);
    }
  },
  methods: {
    startRedirectTimer() {
      this.countdownInterval = setInterval(() => {
        if (this.redirectTimer > 0) {
          this.redirectTimer--;
        } else {
          clearInterval(this.countdownInterval);
          this.redirectTo(); // Redirect when timer hits zero
        }
      }, 1000);
    },
    redirectTo() {
      if (this.isTopUp) {
        this.$navigateToCustomerCard(true);
      } else if (this.isSchoolTopUp) {
        this.$navigateToStudentAccounts(true);
      } else if (this.isMealPlan) {
        this.$navigateToMealPlanConfirmation();
      } else {
        this.$navigateToOrderConfirmation();
      }
    },
  },
  beforeMount() {
    window.shoppingCart = null;
  },
  async created() {
    if (this.result === 'S') {
      if (this.isTopUp || this.isSchoolTopUp) {
        this.startRedirectTimer(this.result);
      } else if (this.isMealPlan) {
        this.mealPlanStore.setStateForOrder(this.appStore.currentMealPlanOrderId, Order.state.FINISHED);
        this.$navigateToMealPlanner(true);
      } else {
        this.$navigateToCheckoutFinished();
      }
    } else {
      this.paymentFailed = true;
    }
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.countdownInterval);
  },
};
</script>
