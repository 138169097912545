<template>

  <div id="app" class="col-12">
    <div v-show="appIsIdle" @click="refreshPage" style="position:fixed;width:100%; height:100%;top:0;left:0;right:0;bottom:0; background:rgba(108, 122, 137, 0.8) ; color:var(--main-color-highlight); z-index:99999999999999999999999999999999999999999999999999999;">
      <div style="padding-top:15vh;">
        {{ $t('text.sessionTerminated') }}
      </div>
      <img class="appIdleIcon" src="@/assets/images/excl-point.png" alt="!"/>
    </div>

    <div v-bind:class="{ appIsIdle: appIsIdle}" class="bodyScroller">

      <div style="position: fixed; z-index: 99999; left:2%" ><a class="sr-only sr-only-focusable bg-dark text-light" href="#main">{{ $t('text.skipToMainContent') }}</a></div>

      <header class="noMargin header" role="banner">
        <GlobalHeader />
      </header>

      <!--
      @ Accessablilty
       * This Element will gain focus on each Page Load, if there is *NO* autofocus element.
       * But since this element has neiter a role, a hitbox, a focus target, it is invisible and still can be called with focus() and blur()
      -->
      <button class="sr-only" href="javascript:void(0)" aria-hidden="true" ref="focusHelper" tabindex="-1" role="none" aria-description="Accessability Auto Focus Helper (ignore this element)"></button>
      <!-- /Accessability -->
      <div id="scrollingContent">
        <main role="main" id="main">
          <div class="oneWayScroll content col-12" id="contentSection">
            <!-- This is where pages get rendered, managed by the vue-router module -->
            <router-view id="content-wrapper" class="oneWayScroll col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" />
          </div>
        </main>
        <footer id="footer" role="contentinfo" class="noMargin col-12" style="padding:0;margin:0;">
          <!--<GlobalFooter />-->
          <global-footer-old :footerLinks="config.FOOTER.links" :config="config"></global-footer-old>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import 'vue-loading-overlay/dist/vue-loading.css'
import './css/app.scss'
import GlobalHeader from "@/components/layout-components/GlobalHeader.vue";
import GlobalFooterOld from "@/components/layout-components/GlobalFooterOld.vue";
import {isAppIdle, SessionTimer} from '@/store/SessionTimer'
import {useLocaleStore} from "@/stores/locale";
import {useAuthStore} from "@/stores/auth";
import {computed, ref} from "vue";
import {accountService} from "@/services/api/accountService";
import {useAppStore} from "@/stores/app";
import {Mode} from "@/constants/constants";

export default {
  name: 'App',
  setup() {
    const appStore = useAppStore();
    const localeStore = useLocaleStore();
    const authStore = useAuthStore();

    return {
      appStore,
      localeStore,
      authStore
    };
  },
  computed: {
    config() {
      return window.config;
    },
    currentLocale() {
      return this.localeStore.getLocale;
    },
    appIsIdle(){
      return isAppIdle();
    },
  },
  components: {
    GlobalHeader,
    GlobalFooterOld
  },
  destroyed() {
    this.timer.onUnmount();
    document.getElementById("app").removeEventListener('scroll', this.handleScroll);
  },
  watch:{
    '$route': function () {
      this.updatePageTitle();
      this.checkLoginCondition();

      // fix : Focus got lost after a page navigation. The Focus now moves to the Hidden Accessible after the page loads.
      const _self = this;
      this.$nextTick().then(function() { // wait for elements to be created;
        if (document.activeElement === document.body || document.activeElement === null) { // test, that the focus was really lost on the document
          let focusHelper = _self.$refs.focusHelper;
          if (focusHelper)
          {
            focusHelper.focus({ preventScroll:true, focusVisible:false }); // focusVisible still in experimental - use blur to hide the focus as fallback
            focusHelper.blur();
          }
        }
      })
    },
    'currentLocale': function () { // on lanugage change
      this.updatePageTitle();
    },
    'appIsIdle': function (value){
      if (value) {
        // console.log("Could not find request timestamp, session stopped!");
        // clearInterval(this.intervalId);
        this.logout();
        // return;
      }
    },
    'authStore.isLoggedIn': function (){
      this.checkLoginCondition();
    },
  },
  methods: {
    /** Proxify this
     * @param {function(...):T} f
     * @returns {function(...):T}
     * */
    t(f){
      const _t = f.bind(this);
      return function thisProxy(...a){return _t(...a);};
    },
    checkLoginCondition(){
      if (typeof this.$route.meta !== 'undefined' && this.$route.meta.requiresLogin === true){
        if (!this.authStore.isLoggedIn){
          this.logout();
        }
      }
    },
    refreshPage() {
      this.logout();
    },
    handleWheel(event) {
      if (event != undefined)
      {
        if (event.originalEvent.deltaY > 0)
        {
          this.minimizeHeader();
        }
      }
    },
    handleWheelContent(event) {
      if (undefined !== event?.originalEvent) {
        if (event.originalEvent.deltaY > 0)
        {
          if($(".header").hasClass("header-big"))
          {
            var myDiv = document.getElementById('content-wrapper');

            myDiv.scrollTop = 0;
            this.minimizeHeader();
          }
        }
      }
    },
    minimizeHeader() {
      $(".header").removeClass("header-big");
      $(".header").addClass("animTransition");
      $(".content").removeClass("header-big-content-margin");
      $(".content").addClass("header-small-content-margin");
    },
    handleScroll() {
        this.minimizeHeader();
      // Any code to be executed when the window is scrolled
    },
    handleScrollContent() {
      this.minimizeHeader();
    },
    handleSizeChanges(main) {
      var visibleHeight = main.clientHeight;
      var innerHeight = main.scrollHeight;
      if (visibleHeight < innerHeight) {
        main.parentElement.classList.add("scrollbarActive");
      } else {
        main.parentElement.classList.remove("scrollbarActive");
      }
    },
    handleMobileKeyboardChanges(event) {
      const { height } = event.target.boundingRect;
      document.body.style.cssText="--keyboardHeight:"+height+"px;";
      document.querySelector(".fontTitle").innerText=height;
    },
    updatePageTitle(){
      let pageTitle = "";
      if (typeof this.$route?.meta?.title === 'string') {
        pageTitle = this.$t(this.$route.meta.title);
      } else {
        pageTitle = this.$route.name;
      }
      document.title = this.$t('title.webShop') + ": " + pageTitle;
    },
    repeat() {
      var cookiesEnabled = true;
      var cookieEnabled = navigator.cookieEnabled;

      // When cookieEnabled flag is present and false then cookies are disabled.
      if (cookieEnabled === false) {
        cookiesEnabled = false;
      }

      // try to set a test cookie if we can't see any cookies and we're using
      // either a browser that doesn't support navigator.cookieEnabled
      // or IE (which always returns true for navigator.cookieEnabled)
      if (!document.cookie && (cookieEnabled === null || /*@cc_on!@*/false)) {
        document.cookie = "testcookie=1";

        if (!document.cookie) {
          cookiesEnabled = false;
        } else {
          document.cookie = "testcookie=; expires=" + new Date(0).toUTCString();
        }
      }

      if (!cookiesEnabled) {
        console.log("cookies are not enabled");
      }
    },
    async logout() {
      clearInterval(this.intervalId);

      this.appStore.setRedirectRoute(this.$route.name,this.$route.params); // store the current page as goto

      await accountService.logout();

      if (this.appStore.isMode(Mode.TABLE)) {
        this.$navigateToErrorLanding(true);
      } else if (this.appStore.isMode(Mode.IMMEDIATE)) {
        this.$navigateToGuest();
      } else {
        this.$navigateToLogin();
      }
    }
  },
  data() {
    return {
      isConfigLoaded: false,
      configLoaded: {},
      mobileView: true,
      showNav: false,
      intervalId: 0,
      timer: undefined,
    }
  },
  provide(){
    let metaPageTitle = ref(undefined);

    return {
      resetPageMetaTitle: function (){
        if (metaPageTitle.value !== undefined) {
          metaPageTitle.value = undefined;
        }
      },
      setPageMetaTitle: async function(name) {
        // waits for the route to reset the page MetaTitle
        this.$nextTick().then(
          function (){
            metaPageTitle.value=name;
          }
        );
      },
      getPageMetaTitle: computed(()=>metaPageTitle && metaPageTitle.value),
    };
  },
  async mounted() {
    this.timer = SessionTimer();
    this.timer.onMount();

    await this.localeStore.setLocale(this.currentLocale);

    if (this.intervalId !== 0) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(this.repeat, 1000);

    //BIND EVENTS
    $("#app").bind("scroll", this.t(function () {
      this.handleScroll();
    }));

    $("#siteHeader").bind("wheel", this.t(function (event) {
      this.handleWheel(event);
    }));

    $("#content-wrapper").bind("wheel", this.t(function (event) {
      this.handleWheelContent(event);
    }));

    $("#content-wrapper").bind("scroll", this.t(function (event) {
      this.handleScrollContent(event);
    }));

    /* region Resize Observer - ScrollBar Searcher */
    if (this.resizeObserver !== undefined) {
      this.resizeObserver.disconnect();
    }
    this.resizeObserver = new ResizeObserver(this.t(function (entries) {
      this.handleSizeChanges(entries[0].target);
    }));

    this.resizeObserver.observe(document.querySelector("#scrollingContent"), {box: "content-box"});

    if ("virtualKeyboard" in navigator) {
      navigator.virtualKeyboard.overlaysContent = true;
      navigator.virtualKeyboard.addEventListener("geometrychange", this.handleMobileKeyboardChanges);
    }
  },
  async updated() {
    this.handleSizeChanges(document.querySelector("#scrollingContent"));
  }
}
</script>
<style scoped>
</style>
