import moment from "moment/moment";

export function getCurrentDateTimeISO() {
  return moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
}

export function formatDate(date, format = 'YYYY-MM-DD') {
  return moment(date).format(format);
}

export function getCurrentWeekdays(date, days = 5) {
  const today = new Date(date);
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate Monday of the current week
  const monday = new Date(today);
  monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

  // Generate weekdays according days parameter
  const weekdays = [];
  for (let i = 0; i < days; i++) {
    const date = new Date(monday);
    date.setDate(monday.getDate() + i);
    weekdays.push(formatDate(date));
  }

  return weekdays;
}

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function formatFullDate(date, locale = navigator.language, options = {}) {
  const defaultOptions = {
    weekday: 'short',  // 'short', 'long', 'narrow'
    day: '2-digit',    // 'numeric', '2-digit'
    month: '2-digit',  // 'numeric', '2-digit', 'long', 'short', 'narrow'
    year: '2-digit'    // 'numeric', '2-digit'
  };

  // Merge custom options with default ones
  const mergedOptions = { ...defaultOptions, ...options };

  if (typeof date === "string") {
    date = new Date(date);
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.warn("Invalid date passed to formatFullDate:", date);
    return "Invalid Date";
  }

  return new Intl.DateTimeFormat(locale, mergedOptions).format(date);
}
