import { orderService } from "@/services/api/orderService";
import Utils from "@/utils/utils";
import { getCurrentDateTimeISO } from "@/utils/dateUtils";
import { OrderTypes } from "@/constants/constants";

export default {
  methods: {
    async doCheckIfOrdersAreCurrentlyPossible() {
      let hasTimeSlotForDay = false;

      const result = await orderService.getTimeSlots();
      const availabilities = result.availabilityList;
      if (!availabilities) {
        this.makeToast('danger', this.$t('notification.noTimeslotsAvailable'));
        this.$navigateToErrorLanding();
        return;
      }

      const mapDayWithTimeslots = new Map(
        availabilities.map(obj => [new Date(obj.date).toLocaleDateString(), obj.timeslots])
      );

      const currentDate = new Date();
      if (mapDayWithTimeslots.size > 0) {
        const timeslots = mapDayWithTimeslots.get(currentDate.toLocaleDateString());
        if (timeslots?.length > 0) {
          hasTimeSlotForDay = timeslots.some(timeslot => {
            const dateFrom = Utils.parseTimeHHMMSS(timeslot.fromtime);
            const dateTo = Utils.parseTimeHHMMSS(timeslot.totime);
            return currentDate >= dateFrom && currentDate <= dateTo;
          });
        }
      }

      if (hasTimeSlotForDay) {
        const resultOcc = await orderService.getTimeSlotOccupancies(1, currentDate);
        const occupancies = resultOcc?.timeSlots;
        if (!occupancies) {
          this.makeToast('danger', this.$t('notification.noTimeslotsAvailable'));
          this.$navigateToErrorLanding();
          return;
        }

        this.ordersPossible = occupancies.some(timeslot => {
          const dateFrom = Utils.parseTimeHHMMSS(timeslot.fromTime);
          const dateTo = Utils.parseTimeHHMMSS(timeslot.toTime);
          return currentDate >= dateFrom && currentDate <= dateTo && timeslot.slots > 0 && timeslot.usable > 0;
        });
      }
    },

    async doImmediateOrder() {
      const result = await orderService.immediateOrder(this.shopCode);
      if (result.exception) {
        this.makeToast('danger', this.$t('notification.failedGeneric'));
        this.$navigateToErrorLanding();
        return;
      }

      const order = this.orderStore.addOrder(this.shopCode, OrderTypes.DINE_IN, getCurrentDateTimeISO());
      this.appStore.setCurrentOrderId(order.orderId);
      this.shopStore.setShop(result.shop.id, result.shop.custom1, result.shop.custom2, result.shop.name, result.shop.image, result.shop.custom6);
    },

    async selectTableByQRCode(shopCode, tableCode) {
      const result = await orderService.selectTableByQRCode(shopCode, tableCode);
      if (result.exception) {
        this.makeToast('danger', this.$t('notification.failedGeneric'));
        this.$navigateToErrorLanding();
        return;
      }

      const order = this.orderStore.addOrder(this.shopCode, OrderTypes.DINE_IN, getCurrentDateTimeISO());
      this.orderStore.setTableForOrder(order.orderId, result.tableId, result.tableCode);
      this.appStore.setCurrentOrderId(order.orderId);
      this.shopStore.setShop(result.shop.id, result.shop.custom1, result.shop.custom2, result.shop.name, result.shop.image, result.shop.custom6);
    }
  }
};
