<template>
  <div>
    <!-- todo te - move all this into the StructureEditNode -->
    <div class="d-flex flex-row gap10">
      <h5 class="text-left flex-grow-1">{{ structure[structureSettings.namePropGroup] }}</h5>

      <!-- region Min / Max Notice -->
      <span v-if="!valid" class="requirement-text">{{ requirement }}</span>
      <!-- endregion -->
    </div>

    <div v-if="type!=='dropdown'" :class="{'checkmark-error':!valid}" class="d-flex flex-column gap10">
      <StructureEditNode
        v-for="child of structure[structureSettings.childPropGroup]"
        :key="child[structureSettings.idPropNode]"
        v-model="nativeModel"
        :path="modelId"
        :structure="child"
        :type="getChildType(child)"
        :selection-revokable="structure.minQty !== 0"
        :label="formatLabelWithPrice(child[structureSettings.namePropNode], child)"
        :structure-settings="structureSettings"
      />
    </div>
    <div v-if="type==='dropdown'" :class="{'checkmark-error':!valid}">
      <Dropdown
        :options="convertDropdownElements(structure[structureSettings.childPropGroup])"
        v-model="selection"
        :required="structure.minQty > 0"
        :placeholder="$t('placeholder.chooseOption')"
        @selection-changed="handleSelectedOption"
      />
    </div>

  </div>
</template>
<script>

import Dropdown from "@/components/barrier-free-template-components/Dropdown.vue";
import {formatLabelWithPrice} from "@/utils/productUtils";

/**
 * How the inputs work:
 * {@code path} is the prefix for the current Id; ex: path='x', current element's id = 'y' then myId = 'x.y'
 * {@code v-model} is a set of 'active' paths; example: myId = 'x', with children = ['y','z'], when v-model[] contains 'x' or 'x.*' then x is 'active' <br/>
 *
 * */
export default {
  name: 'StructureEditGroup',
  components: {
    Dropdown,
    StructureEditNode: () => import("./StructureEditNode.vue"), // due to recursive Usage, the childs have to be created each time
  },
  data() {
    return {
      selection: undefined,
      active: false,
    };
  },
  event: ['update:modelValue'],
  model: { // vue 2 v-model
    event: 'update:modelValue',
    prop: 'modelValue',
  },
  props: {
    id: String,
    path: {
      Type: String,
      default: () => '',
    },
    // /** @type EditableRecursiveDataStructure */
    /** @type {ProductDetailGroupNode} */
    structure: {
      Type: Object | 'ProductDetailGroupNode',
      // default: () => {}
      required: true,
    },
    /** @type Array<string> */
    value: {
      Type: Array,
      default: () => []
    },
    modelValue: {
      Type: Array,
      default: () => [],
    },
    /** @type {StructureEditSettings} */
    structureSettings: {
      Type: Object || 'StructureEditSettings',
      required: true,
    },
  },
  // inject:['__structureValue'],
  // provide:{
  //
  // },
  computed:{
    /** @type {Array<String>} */
    nativeModel: {
      /** @return {Array<String>} */
      get(){return this.modelValue;},
      set(value){this.$emit('update:modelValue', value);},
    },
    modelId(){
      // return this.structure.path + '.' + this.structure.id;
      // return 1;
      return this.join(this.structure[this.structureSettings.idPropGroup]);
    },
    hasChildren(){
      return this.structure[this.structureSettings.childPropGroup] && this.structure[this.structureSettings.childPropGroup].length > 0;
    },
    /**
     * The Group decides how the childs should be displayed
     * @returns {"checkbox"|"dropdown"|"radio"|string}
     * */
    type() {
      let hasChildren = this.childHasChilds(); // if any child has group child

      if (this.structure.maxQty === 1 && this.structure.minQty <= 1) {
        return hasChildren ? 'radio' : 'dropdown';
      }
      return 'checkbox';
    },
    requirement(){
      // if ()
      const chosenValues = this.getMeAndChildren(true, false);

      // if (chosenValues.length === 0) { // nothing to report, there is nothing to choose
      //   return undefined;
      // }

      const isChosen = chosenValues.includes(this.modelId);

      // if (!isChosen)
      // {
      //   return null; // do nothing, we are not selected
      // }
      const itemsSelected = chosenValues.length - (isChosen ? 1 : 0)

      if (this.structure.minQty <= itemsSelected && this.structure.maxQty >= itemsSelected)
        return undefined; // all good

      if (this.structure.minQty > itemsSelected) // - 1 since i am selected but do not count to the limit
        return this.$t("text.subItemMin", [this.structure.minQty, itemsSelected]);

      if (this.structure.maxQty < itemsSelected) //
        return this.$t("text.subItemMax", [this.structure.maxQty, itemsSelected]);

      console.error("Unknown Issue, can not validate; Structure requirements: %o, Length: %i, Selection: %o", this.structure, itemsSelected, chosenValues );

      return "Unknown Issue, Can not validate";
    },
    valid() {
      return typeof this.requirement === 'undefined';
    },
  },
  methods: {
    formatLabelWithPrice,
    /**
     * @param {ProductDetailItemNode} item
     * @returns {"checkbox"|"checkbox-boxed"|"radio"|"radio-boxed"|"dropdown"|string}
     * */
    getChildType(item){
      let baseType = this.type;
      if (this.hasDirectChildren(item) && baseType !== "dropdown"){
        return baseType + "-boxed";
      }
      return baseType;
    },
    /** @param {ProductDetailItemNode} item */
    hasDirectChildren(item) {
      if (typeof this.structureSettings.hasChildPropNode === "string"){
        return item[this.structureSettings.hasChildPropNode];
      }
      if (item[this.structureSettings.childPropNode]) {
        return item[this.structureSettings.childPropNode].length > 0;
      }
      return false;
    },
    /** @param {ProductDetailItemNode} item */
    getChildId(item){
      return item[this.structureSettings.idPropNode];
    },
    childHasChilds(){
      for (let child of this.structure[this.structureSettings.childPropGroup]) {
        if (child[this.structureSettings.childPropNode] && child[this.structureSettings.childPropNode].length >= 0) {
          return true;
        }
      }

      return false;
    },
    updateSelection(){
      /** @type {"changes.dropdown"|string} */
      const modelId = this.modelId;

      const currentModelSelectionChilds = this.getMeAndChildren(true);
      this.active = currentModelSelectionChilds.includes(modelId);

      if (this.type==='dropdown')
      {
        this.selection = this.getMeAndChildren(true, false)[0]; // i expect to be also selected or something similar
      }
      // this.active = currentModelSelectionChilds.includes(modelId) || currentModelSelectionChilds.length > 0;
    },
    deselect(withSelf=false){
      const modelId = this.modelId;

      this.nativeModel = this.nativeModel
        .filter(b => !((b === modelId && withSelf) || b.startsWith(modelId + '.')));
    },
    selectSelf(){
      let out = this.nativeModel;
      if (this.type === 'dropdown') {
        const parentId = this.path;

        out = out
          .filter(b => !b.startsWith(parentId + '.'));
      }

      out.push(this.modelId);
      this.nativeModel = out;
    },
    handleSelectedOption(value){
      // console.log("trigger",value);
      let out = this.nativeModel;
      if (this.type === 'dropdown') {
        const parentId = this.modelId;

        out = out
          .filter(b => !b.startsWith(parentId + '.'));
      }

      out.push(value);
      this.nativeModel = out;
    },
    join(id){
      if (this.path.length > 0)
        return this.path + '.' + id;
      return id;
    },
    /** @returns {"tooFew"|"tooMany"|null} */
    validation() {

    },
    /** @return Array<String> */
    getMeAndChildren(onlyDirect=true,includeMe=true){
      const modelId = this.modelId;
      return this.nativeModel
        .map(a=>""+a)
        .filter(a=>(a===modelId && includeMe) || a.startsWith(modelId+'.'))
        .filter(a=>!onlyDirect||!a.replace(modelId,'').includes('.',1))
    },
    /**
     * @param {Array<ProductDetailItemNode>} items
     * @returns Array<DropdownOption>
     * */
    convertDropdownElements(items){
      const _self = this;
      let childIntepretor = function(item){return _self.modelId + '.' + _self.getChildId(item);};
      const ret = items.map(a=>({text:a[_self.structureSettings.namePropNode],value:childIntepretor(a),disabled:false}));
      // console.log(ret);
      return ret;
    },
  },
  beforeMount() {
  },
  mounted(){
  },
  created(){
  },
  watch:{
    'nativeModel': function (){
      this.updateSelection();
    },
  },
}
</script>
<style lang="scss">
@import '@/css/customizer';

.requirement-text {
  color: custom-chain(--checkmark-error-color, #ff6767);
}
</style>
