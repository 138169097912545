import Vue from 'vue';
import Router from 'vue-router';
import AccountPage from "@/components/page-components/AccountPage.vue";
import Login from "@/components/page-components/Login.vue";
import ForgotPasswordPage from "@/components/page-components/ForgotPasswordPage.vue";
import Shops from "@/components/page-components/Shops.vue";
import Shop from "@/components/page-components/Shop/Shop.vue";
import Menu from "@/components/page-components/Menu.vue";
import RegisterPage from "@/components/page-components/RegisterPage.vue";
import RegisterAsParentPage from "@/components/page-components/RegisterAsParentPage.vue";
import RegisterAsChildPage from "@/components/page-components/RegisterAsChildPage.vue";
import GuestCheckout from "@/components/page-components/GuestCheckout.vue";
import ErrorSSOLanding from "@/components/page-components/ErrorSSOLanding.vue";
import ArticlePage from "@/components/page-components/ArticlePage.vue";
import PasswordReset from "@/components/page-components/PasswordReset.vue";
import AddressSelectionPage from "@/components/page-components/AddressSelectionPage.vue";
import EditCustomerPage from "@/components/page-components/EditCustomerPage.vue";
import TopUp from "@/components/page-components/TopUp.vue";
import OrderHistoryPage from "@/components/page-components/OrderHistoryPage.vue";
import TransactionHistoryPage from "@/components/page-components/TransactionHistoryPage.vue";
import StudentAccounts from "@/components/page-components/StudentAccounts.vue";
import AccountConfirm from "@/components/page-components/AccountConfirm.vue";
import TablePayPage from "@/components/page-components/TablePayPage.vue";
import OrderConfirmation from "@/components/page-components/OrderConfirmation.vue";
import CheckoutFinishedPage from "@/components/page-components/CheckoutFinishedPage.vue";
import PaymentEnd from "@/components/page-components/PaymentEnd.vue";
import Payment from "@/components/page-components/Payment.vue";
import LoginSSO from "@/components/page-components/LoginSSO.vue";
import MealPlanner from "@/components/page-components/MealPlanner/MealPlanner.vue";
import CustomerCard from "@/components/page-components/CustomerCard.vue";
import Dashboard from "@/components/page-components/Dashboard/Dashboard.vue";
import Template from "@/components/page-components/templates/Template.vue";
import AddressMutationPage from '@/components/page-components/AddressMutationPage.vue'
import {useAuthStore} from "@/stores/auth";
import pinia from "@/stores";
import ErrorLanding from "@/components/page-components/ErrorLanding.vue";
import Landing from "@/components/page-components/Landing.vue";
import AutoTopUp from "../components/page-components/AutoTopUp.vue";
import MealPlanConfirmation from "@/components/page-components/MealPlanner/MealPlanConfirmation.vue";
import {useAppStore} from "@/stores/app";
import {Mode} from "@/constants/constants";
import TopUpPayment from "@/components/page-components/TopUpPayment.vue";
import I18n, {loadLocaleMessages} from "@/i18n";
import {useLocaleStore} from "@/stores/locale";
import MealPlanDownloads from "@/components/page-components/MealPlanner/MealPlanDownloads.vue";

Vue.use(Router);

let config = window.config;
let configTableMode = window.configTablemode

const router = new Router({
    mode: 'history',
    base: '/dist/',
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: { title: 'title.dashboard', requiresLogin: config.FORCE_LOGIN_BEFORE_USE }
        },
        {
            path: '/template',
            name: 'Template',
            component: Template,
            meta: { enabled: process.env.NODE_ENV !== 'production', title: 'Template' }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: { title: 'title.login', ariaTitle: 'ariaLabel.login' }
        },
        {
            path: '/loginSSO',
            name: 'LoginSSO',
            component: LoginSSO,
            props: route => ({
                code: route.query.code,
                jwt: route.query.id_token,
                tableCode: route.query.t,
                shopCode: route.query.s,
                orderImmediate: route.query.i,
                isMealPlan: route.query.isMealPlan,
                isSchool: route.query.isSchool,
                validationType: route.query.validationType
            }),
        },
        {
            path: '/register',
            name: 'Register',
            component: RegisterPage,
            meta: { enabled: config.REGISTER.enabled, title: 'title.register', showBackButton: '/login' }
        },
        {
            path: '/registerAsParent',
            name: 'RegisterAsParent',
            component: RegisterAsParentPage,
            meta: { enabled: config.STUDENT_ACCOUNTS.enabled, title: 'title.registerAsParent', showBackButton: '/login' }
        },
        {
            path: '/guest',
            name: 'Guest',
            component: GuestCheckout,
            meta: { enabled: config.GUEST_CHECKOUT.enabled, title: 'title.registerGuest', showBackButton: '/login' }
        },
        {
            path: '/forgotPassword',
            name: 'ForgotPassword',
            component: ForgotPasswordPage,
            meta: { title: 'title.passwordForgot', showBackButton: '/login' }
        },
        {
            path: '/order/shops',
            name: 'OrderShops',
            component: Shops,
            meta: { enabled: config.ORDER.enabled, title: 'title.shop' },
        },
        {
            path: '/mealPlan/shops',
            name: 'MealPlanShops',
            component: Shops,
            meta: { enabled: config.MEAL_PLAN.enabled, title: 'title.shop' },
        },
        {
            path: '/mealPlan/downloads',
            name: 'MealPlanDownloads',
            component: MealPlanDownloads,
            meta: { enabled: config.MEAL_PLAN.enabled, title: 'title.downloads', showBackButton: true },
        },
        {
            path: '/order/shop',
            name: 'Shop',
            component: Shop,
            meta: { enabled: config.ORDER.enabled, title: 'title.shop', showBackButton: '/order/shops' },
        },
        {
            path: '/order/menu',
            name: 'Menu',
            component: Menu,
            meta: { enabled: config.ORDER.enabled, title: 'title.menu', requiresLogin: window.config.FORCE_LOGIN_BEFORE_USE, showBackButton: '/order/shops' }
        },
        {
            path: '/article',
            name: 'Article',
            component: ArticlePage,
            props: route => ({ article: route.query.a })
        },
        {
            path: '/tablePay',
            name: 'TablePay',
            component: TablePayPage
        },
        // Pages with required login
        {
            path: '/account',
            name: 'Account',
            component: AccountPage,
            meta: { title: 'title.account', requiresLogin: true }
        },
        {
            path: '/account/passwordReset',
            name: 'PasswordReset',
            component: PasswordReset,
            meta: { title: 'title.passwordReset', showBackButton: '/account', requiresLogin: true }
        },
        {
            path: '/account/address',
            name: 'Address',
            component: AddressSelectionPage,
            // TODO enabled: config.ADDRESS.enabled
            meta: { title: 'title.address', showBackButton: '/order/shops', requiresLogin: true }
        },
        {
            path: '/account/editAddress/:addressId?',
            name: 'EditAddress',
            component: AddressMutationPage,
            meta: { title: 'title.address', showBackButton: '/account', requiresLogin: true },
            props: true,
        },
        {
            path: '/account/editCustomer',
            name: 'EditCustomer',
            component: EditCustomerPage,
            meta: { enabled: config.CUSTOMER_DATA.editable, title: 'title.editCustomer', showBackButton: '/account', requiresLogin: true }
        },
        {
            path: '/customerCard',
            name: 'CustomerCard',
            component: CustomerCard,
            meta: { enabled: config.CUSTOMER_CARD.enabled, title: 'title.customerCard', requiresLogin: true },
        },
        // TODO store customer infos in pinia store and remove this route
        {
            path: '/topup/:customerId?',
            name: 'TopUp',
            component: TopUp,
            meta: { enabled: config.TOPUP.enabled, title: 'title.charge', showBackButton: '/customerCard', requiresLogin: true },
            props: true
        },
        // TODO store customer infos in pinia store and remove this route
        {
            path: '/topup/confirm/payment/:customerId?',
            name: 'TopUpPayment',
            component: TopUpPayment,
            meta: { enabled: config.TOPUP.enabled, title: 'title.charge', showBackButton: '/customerCard', requiresLogin: true },
            props: true
        },
        {
            path: '/autoTopup/:customerId?',
            name: 'AutoTopUp',
            component: AutoTopUp,
            meta: { enabled: config.AUTO_TOPUP.enabled, title: 'title.autoCharge', showBackButton: '/customerCard', requiresLogin: true },
            props: true
        },
        {
            path: '/orderHistory',
            name: 'OrderHistory',
            component: OrderHistoryPage,
            meta: { enabled: config.ORDER_HISTORY.enabled, title: 'title.orderHistory', requiresLogin: true }
        },
        {
            path: '/transactionHistory/:customerId?',
            name: 'TransactionHistory',
            component: TransactionHistoryPage,
            meta: { enabled: config.TRANSACTION_HISTORY.enabled, title: 'title.transactionHistory', requiresLogin: true },
            props: true
        },
        {
            path: '/mealPlan/meals',
            name: 'MealPlanner',
            component: MealPlanner,
            meta: { enabled: config.MEAL_PLAN.enabled, title: 'title.mealPlan', requiresLogin: true, showBackButton: '/mealPlan/shops', showDownloadButton: true },
            props: route => ({ isMealPlan: route.query.isMealPlan })
        },
        {
            path: '/studentAccounts',
            name: 'StudentAccounts',
            component: StudentAccounts,
            meta: { enabled: config.STUDENT_ACCOUNTS.enabled, title: 'title.studentAccounts', requiresLogin: true },
        },
        {
            path: '/registerAsChild',
            name: 'RegisterAsChild',
            component: RegisterAsChildPage,
            meta: { enabled: config.STUDENT_ACCOUNTS.enabled, title: 'title.registerAsChild', showBackButton: '/login', requiresLogin: true }
        },
        {
            path: '/order/confirmation',
            name: 'OrderConfirmation',
            component: OrderConfirmation,
            meta: { title: 'title.orderConfirmation', showBackButton: '/order/shops', requiresLogin: true }
        },
        {
            path: '/mealPlan/confirmation',
            name: 'MealPlanConfirmation',
            component: MealPlanConfirmation,
            meta: { title: 'title.orderConfirmation', showBackButton: '/mealPlan/shops', requiresLogin: true }
        },
        {
            path: '/mealPlan/payment',
            name: 'MealPlanPayment',
            component: Payment,
            meta: { enabled: config.MEAL_PLAN.enabled, title: 'title.payment', showBackButton: '/mealPlan/shops', requiresLogin: true },
        },
        {
            path: '/order/payment',
            name: 'OrderPayment',
            component: Payment,
            meta: { title: 'title.payment', showBackButton: '/order/shops', requiresLogin: true }
        },

        // Pages to redirect and replace browser history
        {
            path: '/confirm',
            name: 'Confirm',
            component: AccountConfirm,
            props: route => ({ k: route.query.key, m: route.query.m, i: route.query.i})
        },
        {
            path: '/landing',
            name: 'Landing',
            component: Landing,
            props: route => ({
                tableCode: route.query.t,
                shopCode: route.query.s,
                orderImmediate: route.query.i,
                isMealPlan: route.query.isMealPlan
            }),
        },
        {
            path: '/qrLanding',
            name: 'QrLanding',
            component: Landing, // Use the same component as Landing
            // TODO check enable value here
            meta: { enabled: configTableMode.TABLE_CHECKOUT.enabled },
            props: route => ({
                tableCode: route.query.t,
                shopCode: route.query.s,
                orderImmediate: route.query.i
            }),
        },
        {
            path: '/errorSSO',
            name: 'ErrorSSO',
            component: ErrorSSOLanding
        },
        {
            path: '/errorLanding',
            name: 'ErrorLanding',
            component: ErrorLanding,
            props: route => ({ sessionTimeout: route.query.sessionTimeout === 'true' || false })
        },
        {
            path: '/checkoutFinished',
            name: 'CheckoutFinished',
            component: CheckoutFinishedPage,
            meta: { requiresLogin: true },
        },
        {
            path: '/paymentEnd',
            name: 'PaymentEnd',
            component: PaymentEnd,
            meta: { requiresLogin: true },
            props: route => ({ payment: route.query.p, result: route.query.r }),
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    console.log(`Navigating from ${from.fullPath} to ${to.fullPath}`);

    const authStore = useAuthStore(pinia);
    const appStore = useAppStore(pinia);
    const localeStore = useLocaleStore(pinia);

    if (!I18n.availableLocales.includes(localeStore.getLocale)) {
        await loadLocaleMessages(I18n,localeStore.getLocale);
    }

    // Skip guard if navigating to the same route
    if (to.fullPath === from.fullPath) {
        return next(false);
    }

    // Authentication check and save route name before redirecting to login
    if (!authStore.isLoggedIn && to.meta.requiresLogin) {
        console.log("User not authenticated, saving redirect route:", to.name);
        appStore.setRedirectRoute(to.name, to.params);
        return appStore.isMode(Mode.IMMEDIATE) ? next({name: 'Guest'}) : next({name: 'Login'});
    }

    // Prevent logged-in users from accessing Login
    if (authStore.isLoggedIn && (to.name === 'Login' || to.name === 'LoginSSO')) {
        console.log("User already logged in, redirecting to default page or dashboard");
        return next({ path: config.DEFAULT_START_PAGE.enabled ? config.DEFAULT_START_PAGE.path : '/dashboard' });
    }

    // Feature enabled check
    const isEnabled = typeof to.meta.enabled === 'boolean' ? to.meta.enabled : true;
    if (!isEnabled) {
        console.log("Feature not enabled, redirecting to default page or dashboard");
        return next({ path: config.DEFAULT_START_PAGE.enabled ? config.DEFAULT_START_PAGE.path : '/dashboard' });
    }

    // Update `showBackButton`
    if (['/order/menu', '/mealPlan/meals', '/order/shop'].includes(to.path)) {
        if (appStore.isMode(Mode.TABLE) || appStore.isMode(Mode.IMMEDIATE)) {
            to.meta.showBackButton = undefined; // unset Show Back Button
        }
    }

    next();
});

export default router;
