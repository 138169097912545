import { defineStore } from 'pinia';
import { basketService } from "@/services/api/basketService";
import i18n from "@/i18n";
import {useOrderStore} from "@/stores/order";
import {useMealPlanStore} from "@/stores/mealPlan";

export const useBasketStore = defineStore('basket', {
  state: () => ({
    allergensWarning: false, // Indicates if allergens warning should be shown
    items: [], // List of items with ID and quantity, e.g., [{ itemId: '1', quantity: 2 }]
    currencyCode: 'CHF', // Default currency code
    formattedTotal: '0.00 CHF', // Total price of items formatted with currency
    numberOfItems: 0, // Total quantity of items in the basket
    propertiesWarning: false, // Indicates if properties warning should be shown
    subsidy: '0.00 CHF',
    comment: '', // User-provided comment
    shopId: null, // Selected shop ID
    promotionBarcode: null,
    orderType: null,
  }),

  getters: {
    getAllergensWarning: (state) => state.allergensWarning,
    getItems: (state) => state.items,
    getCurrencyCode: (state) => state.currencyCode,
    getFormattedTotal: (state) => state.formattedTotal,
    getNumberOfItems: (state) => state.numberOfItems,
    getPropertiesWarning: (state) => state.propertiesWarning,
    getComment: (state) => state.comment,
    getShopId: (state) => state.shopId,
    getPromotionBarcode: (state) => state.promotionBarcode,
    getOrderType: (state) => state.orderType,
  },

  actions: {
    toggleAllergensWarning() {
      this.allergensWarning = !this.allergensWarning; // Toggle allergens warning
    },
    addItem(itemId, quantity = 1) {
      const item = this.items.find((i) => i.itemId === itemId);
      if (item) {
        item.quantity += quantity; // Update quantity if the item already exists
      } else {
        this.items.push({ itemId, quantity }); // Add new item with quantity
      }
      this.updateBasketStats(); // Recalculate total and item count
    },
    async editBasketItem(itemId, quantity, orderType, token, callback) {
      if( typeof callback != 'function')
      {
        callback = function(){};
      }

      try {
        const result = await basketService.editBasket(itemId, quantity, orderType, token);
        if (result.exception) {
          // Handle specific exception codes
          if (result.exception.code === 64) {
            callback('danger', i18n.t('notification.increaseItemQuantityMinError'));
          } else if (result.exception.code === 65) {
            callback('danger', i18n.t('notification.increaseItemQuantityMaxError'));
          } else {
            callback('danger', i18n.t('notification.addItemFailed'));
          }
          return false; // Indicate failure
        }

        // Update basket state after successful API call
        const item = this.items.find((i) => i.itemId === itemId);
        if (item) {
          item.quantity = quantity;
        } else {
          this.addItem(itemId, quantity); // Add the item if it doesn't exist
        }

        this.formattedTotal = result.basketTile.formattedTotal;

        if (result.basketTile.paymentsList != undefined) {
          this.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
        } else {
          this.subsidy = "";
        }

        this.updateBasketStats(); // Recalculate totals and counts
        callback('success', i18n.t('notification.itemQuantityUpdated'));
        return true; // Indicate success
      } catch (error) {
        callback('danger', i18n.t('notification.addItemFailed'));
        return false; // Indicate failure
      }
    },
    clearBasket() {
      this.items = [];
      this.comment = '';
      this.shopId = null;
      this.promotionBarcode = null;
      this.orderType = null;
      this.updateBasketStats();
      basketService.emptyBasket();
      useOrderStore().reset();
      useMealPlanStore().reset();
    },
    setCurrencyCode(code) {
      this.currencyCode = code; // Update the currency code
      this.calculateFormattedTotal(); // Recalculate the total with the new currency
    },
    togglePropertiesWarning() {
      this.propertiesWarning = !this.propertiesWarning; // Toggle properties warning
    },
    setComment(newComment) {
      this.comment = newComment; // Update the comment
    },
    setShopId(newShopId) {
      this.shopId = newShopId; // Update the shop ID
    },
    setPromotionBarcode(newPromotionBarcode) {
      this.promotionBarcode = newPromotionBarcode;
    },
    setOrderType(newOrderType) {
      this.orderType = newOrderType;
    },
    calculateFormattedTotal() {
      // Example calculation, replace with real logic if needed
      const total = this.items.reduce(
        (sum, item) => sum + item.quantity * 10, // Assume each item costs CHF 10 for this example
        0
      );
      this.formattedTotal = `${total.toFixed(2)} ${this.currencyCode}`;
    },
    updateBasketStats() {
      this.numberOfItems = this.items.reduce((sum, item) => sum + item.quantity, 0); // Total quantity of items
      this.calculateFormattedTotal(); // Update formatted total
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: 'basket',
        storage: sessionStorage,
        /*paths: [
          'allergensWarning',
          'items',
          'currencyCode',
          'formattedTotal',
          'numberOfItems',
          'propertiesWarning',
          'comment',
          'shopId',
        ],*/
      },
    ],
  },
});
