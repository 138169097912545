export const Workflow = Object.freeze({
  DEFAULT: 'DEFAULT',
  MEAL_PLAN: 'MEAL_PLAN',
  TOP_UP: 'TOP_UP',
  ORDER: 'ORDER',
  SCHOOL: 'SCHOOL',
});

export const Mode = Object.freeze({
  GUEST: 'GUEST',
  LANDING: 'LANDING',
  LOGIN: 'LOGIN',
  ORDER: 'ORDER',
  SCHOOL: 'SCHOOL',
  TABLE: 'TABLE',
  IMMEDIATE: 'IMMEDIATE',
  TOPUP: 'TOPUP',
  SSO: 'SSO',
  SHOP: 'SHOP',
});

export const Currencies = Object.freeze({
    CHF: 'CHF',
    EUR: 'EUR',
});

export const Customer = Object.freeze({
  TYPE: Object.freeze({
    GUEST: 'GUEST',
    REGISTERED: 'REGISTERED'
  }),
});

export const Order = Object.freeze({
  type: Object.freeze({
    TAKEAWAY: 'takeaway',
    DELIVERY: 'delivery',
    DINE_IN: 'dinein',
  }),
  shortType: Object.freeze({
    TAKEAWAY: 'TA',
    DELIVERY: 'DE',
    DINE_IN: 'DI',
  }),
  numericType: Object.freeze({
    DINE_IN: 1,
    TAKEAWAY: 2,
    DELIVERY: 3
  }),
  state: Object.freeze({
    FINISHED: "FINISHED",
  }),
});

export const OrderTypes = Object.freeze({
    TAKEAWAY: 'takeaway',
    DELIVERY: 'delivery',
    DINE_IN: 'dinein',
});

export const PaymentProviders = Object.freeze({
  ADYEN: 'adyen',
  NOVALNET: 'novalnet',
  SAFERPAY: 'saferpay',
  SECUREPAY: 'securepay',
  STRIPE: 'stripe',
  PAYONE: 'payone',
});

export const Login = Object.freeze({
  Mode: Object.freeze({
    GUEST: 'GUEST', // Guest login without credentials
    STANDARD: 'LOGIN', // Standard login with credentials
    SINGLE_SIGN_ON: 'SSO', // Single Sign-On (SSO)
  }),
  UserType: Object.freeze({
    REGISTERED_USER: '0', // Logged-in user
    GUEST_USER: '2', // Guest user
  }),
});

export const UserTypes = Object.freeze({
    DEFAULT: '0',
    CHILD: '1',
    PARENT: '2',
});
