<template>
  <div class="basket-product">
    <img class="product-image"
      :src="product.prodImgSrc"
      :alt="product.prodName"
      loading="lazy"
    />

    <div class="d-flex flex-column w-100">
      <!-- Product Details -->
      <div class="product-info">
        <span class="product-text" v-if="product.text" v-html="product.text"></span>
      </div>
      <!-- todo : Append Product Details if any here (example Burger Menu: Selected Desert and Drink) -->

      <div class="d-flex flex-row justify-content-end">
        <!-- todo translate -->
        <span class="price px-1" aria-label="Quantity">{{ product.formattedPrice }}</span>

        <!-- Product Quantity -->
        <!-- todo translate -->
        <span class="product-quantity" aria-label="Quantity">x{{ product.quantity }}</span>

        <!-- Product Price -->
        <!-- todo translate -->
        <span class="product-price" aria-label="Price">{{ product.formattedTotalPrice }}</span>
      </div>
    </div>
  </div>

    <!-- <div class="basket-product">
      <img :src="product.prodImgSrc" :alt="product.prodName" class="product-image" />
      <div class="product-info">
        <h4>{{ product.prodName }}</h4>
        <p v-if="product.text" v-html="product.text"></p>
        <p><strong>Price:</strong> {{ product.formattedPrice }}</p>
        <p><strong>Quantity:</strong> {{ product.quantity }}</p>
        <p><strong>Total:</strong> {{ product.formattedTotalPrice }}</p>
        <div class="actions">
          <button @click="editProduct">Edit</button>
          <button @click="removeProduct">Remove</button>
        </div>
      </div>
    </div>-->
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editProduct() {
      console.log("Editing product:", this.product);
      // Emit an event for editing
      this.$emit("edit-product", this.product);
    },
    removeProduct() {
      console.log("Removing product:", this.product);
      // Emit an event for removal
      this.$emit("remove-product", this.product.id);
    },
  },
};
</script>

<style scoped>
.basket-product {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  gap: 12px;
}

.product-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.product-info {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.product-quantity {
  font-size: 16px;
  color: #222;
  min-width: 40px;
  text-align: center;
}

.product-price {
  font-size: 16px;
  font-weight: bold;
  color: #222;
  min-width: 80px;
  text-align: right;
}
</style>
