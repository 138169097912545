<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" loader="bars"></loading>
    <div>
      <FlexContainer v-if="isChild" :title="$t('subTitle.customerCardInfos')" class="mb-2" #content>
        <div class="d-flex flex-column noPadding text-left gap10">
          <h3 class="normalText"><strong>{{ $t('text.cardHolder') }}:</strong> {{ fullName(wondCustomer) }}</h3>
          <h3 class="normalText"><strong>{{ $t('text.cardNumber') }}:</strong> {{ wondCustomer.cardNum }}</h3>
          <h3 class="normalText"><strong>{{ $t('text.customerCardBalance') }}</strong> {{ wondCustomer.formattedPrepayBalanceCash }}</h3>
        </div>
      </FlexContainer>

      <b-container fluid>
        <b-row v-if="paymentDetails.length > 1">
          <b-col cols="12">
            <FlexContainer :title="$t('subTitle.paymentSelect')" #content>
              <div class="d-flex flex-column noPadding">
                <b-button v-for="payment in paymentDetails" :key="payment.code + (payment.subCode ? '~~' + payment.subCode : '')"
                          class="btn btn-primary col-12 mt-2"
                          @click="onSubmit(payment)">
                  {{ $t('button.payByCreditCard') }}
                </b-button>
              </div>
            </FlexContainer>
          </b-col>
        </b-row>
      </b-container>

      <component :is="currentPaymentComponent" :subCode="currentPaymentSubCode" :callback="redirectTo" v-if="currentPaymentComponent"></component>
    </div>
  </div>
</template>

<script>
import VueLoading from "vue-loading-overlay";
import { accountService } from "@/services/api/accountService";
import { paymentService } from "@/services/api/paymentService";
import { useAppStore } from "@/stores/app";
import { useOrderStore } from "@/stores/order";
import { Workflow } from "@/constants/constants";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import CreditCardPaymentComponentSaferpay from "@/components/main-components/CreditCardPaymentComponentSaferpay.vue";
import CreditCardPaymentComponentSecurepay from "@/components/main-components/CreditCardPaymentComponentSecurepay.vue";
import CreditCardPaymentComponentNovalnet from "@/components/main-components/CreditCardPaymentComponentNovalnet.vue";
import CreditCardPaymentComponentPayone from "@/components/main-components/CreditCardPaymentComponentPayone.vue";
import CreditCardPaymentComponentAdyen from "@/components/main-components/CreditCardPaymentComponentAdyen.vue";

export default {
  name: "top-up",
  components: {
    loading: VueLoading,
    FlexContainer,
    "credit-card-payment-saferpay": CreditCardPaymentComponentSaferpay,
    "credit-card-payment-securepay": CreditCardPaymentComponentSecurepay,
    "credit-card-payment-novalnet": CreditCardPaymentComponentNovalnet,
    "credit-card-payment-payone": CreditCardPaymentComponentPayone,
    "credit-card-payment-adyen": CreditCardPaymentComponentAdyen,
  },
  data() {
    return {
      isLoading: false,
      wondCustomer: {},
      paymentDetails: [],
      currentPaymentComponent: null,
      currentPaymentSubCode: ""
    };
  },
  setup() {
    return {appStore: useAppStore(), orderStore: useOrderStore()};
  },
  computed: {
    isChild() {
      return this.appStore.isWorkflow(Workflow.SCHOOL);
    }
  },
  props: {
    customerId: [String, Number]
  },
  methods: {
    fullName(customer) {
      return [customer.description, customer.firstName].filter(Boolean).join(" ").trim();
    },
    async getPaymentDetails() {
      this.isLoading = true;
      const result = await paymentService.getPaymentMethods();
      this.isLoading = false;
      if (result.exception || !result.paymentDetails) {
        this.makeToast("danger", this.$t("error.onTopUp"));
        return;
      }
      this.paymentDetails = result.paymentDetails;
      if (this.paymentDetails.length === 1) {
        this.onSubmit(this.paymentDetails[0]);
      }
    },
    async onSubmit(payment) {
      this.isLoading = true;
      const paymentMethod = payment.subCode ? `${payment.code}~~${payment.subCode}` : payment.code;
      const result = await paymentService.pay(paymentMethod);
      this.isLoading = false;

      const paymentComponents = {
        Saferpay: "credit-card-payment-saferpay",
        Securepay: "credit-card-payment-securepay",
        Novalnet: "credit-card-payment-novalnet",
        PayOne: "credit-card-payment-payone",
        Adyen: "credit-card-payment-adyen"
      };

      this.currentPaymentComponent = paymentComponents[result.paymentMethod.split("~~")[0]] || null;
      this.currentPaymentSubCode = payment.subCode || "";
    },
    async redirectTo(status) {
      if (status >= 0) {
        this.makeToast("success", this.$t("notification.chargeSuccessful"));
        this.isChild ? this.$navigateToStudentAccounts(true) : this.$navigateToCustomerCard();
      }
    }
  },
  async created() {
    if (this.isChild) {
      this.wondCustomer = await accountService.selectChild(this.customerId);
    }
    await this.getPaymentDetails();
  }
};
</script>

<style scoped>
</style>
