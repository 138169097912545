import { orderService } from "@/services/api/orderService";

export default {
  data() {
    return {
      basketTile: null,
      isLoading: false
    };
  },
  computed: {
    // Get basket items
    basketItems() {
      return this.basketTile?.basketItems || [];
    },

    formattedTotal() {
      return this.basketTile?.formattedTotal || "0.00";
    },

    // Find a basket item by prodId
    getBasketItemByProdId() {
      return (prodId) => this.basketItems.find(item => item.prodId === prodId) || null;
    }
  },
  methods: {
    async fetchBasketTile(orderType, options) {
      try {
        this.isLoading = true;
        const result = await orderService.calculateOrder(orderType, options);

        // Handle errors early and return null
        if (result.exception) {
          this.showError("exceptionMessage");
          return null;
        }
        if (result.errorCode > 0) {
          this.showError(result.errorMsg);
          return null;
        }

        this.basketTile = result.basketTile || null;
        if (!this.basketTile) this.makeToast("danger", this.$t("notification.noProducts"));

        return this.basketTile;
      } catch (error) {
        this.makeToast("danger", this.$t("error.general"));
        return null;
      } finally {
        this.isLoading = false;
      }
    },

    fetchBasketItemByProdId(prodId) {
      return this.getBasketItemByProdId(prodId);
    },

    showError(messageKey) {
      this.makeToast("danger", this.$t(messageKey));
    }
  }
};
