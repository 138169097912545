<template>
  <b-card class="custom-card" no-body>
    <b-card-header class="small-header" role="tab">
      <b-button v-b-toggle="id" block variant="primary" class="btn btn-accordion d-flex justify-content-between align-items-center">
        <span class="textClip">{{ title }}</span>
        <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
      </b-button>
    </b-card-header>
    <b-collapse :id="id" :accordion="id" role="tabpanel" class="btnControlledAccordeonBody">
      <b-card-body class="product-details">
        <!-- Check if longDesc exists -->
        <slot name="default"/>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script lang="js">
export default {
  props:{
    title: {
      Type: String | 'LocaleMessages', // just to that the error leaves if we use translatable text
    },
  },
  computed: {
    id(){
      return 'accordion_' + this._uid;
    },
  },
  data(){
    return {};
  },
}
</script>
<style scoped lang="scss">
@import '@/css/customizer';

.btn-accordion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  width: 100%;
  margin: 5px 0 0;
}

</style>
