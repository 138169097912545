<template>
  <div>
    <FlowIndicator current-point="2"/>
    <Basket :order-type="OrderTypes.DINE_IN" />
    <Button @click="handlePayment">{{ $t('button.confirm') }}</Button>
  </div>
</template>

<script>
import { orderService } from "@/services/api/orderService";
import { OrderTypes } from "@/constants/constants";
import { useMealPlanStore } from "@/stores/mealPlan";
import Button from "@/components/barrier-free-template-components/Button.vue";
import {useShopStore} from "@/stores/shop";
import {useAppStore} from "@/stores/app";
import Basket from "@/components/sub-components/Basket/Basket.vue";
import FlowIndicator from "@/components/main-components/FlowIndicator.vue";

export default {
  computed: {
    OrderTypes() {
      return OrderTypes
    }
  },
  components: {Basket, Button, FlowIndicator},
  inject: ["setPageMetaTitle"],
  data() {
    return {
      /** @type {BasketTile} */
      basketTile: {},
      isLoading: false,
    };
  },
  setup() {
    const appStore = useAppStore();
    const mealPlanStore = useMealPlanStore();
    const shopStore = useShopStore();

    return { appStore, mealPlanStore, shopStore };
  },
  async created() {
    await this.setPageMetaTitle(this.shopStore.name);
    // await this.fetchBasketData();
  },
  methods: {
    async fetchBasketData() {
      try {
        let mealPlanId, reservationDateTime;
        const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
        if (order) {
          mealPlanId = order.mealPlanId;
          reservationDateTime = order.reservationDateTime;
        }

        this.isLoading = true;
        /** @type {AfterLoginResponse} */
        const result = await orderService.calculateOrder(OrderTypes.DINE_IN, {
          promotionBarcode: this.promoBarcode,
          comment: this.comment,
          mealPlanId: mealPlanId ? mealPlanId : undefined,
          reservationDateTime: reservationDateTime ? reservationDateTime : undefined
        });
        this.isLoading = false;

        if (result.exception) {
          this.makeToast("danger", this.$t("exceptionMessage"));
          return;
        }

        if (result.errorCode > 0) {
          this.makeToast("danger", result.errorMsg);
          return;
        }

        if (!result.basketTile.basketItems || result.basketTile.basketItems.length === 0) {
          this.makeToast("danger", this.$t("notification.noProducts"));
        } else {
          this.basketTile = result.basketTile;
        }
      } catch (error) {
        this.makeToast("danger", this.$t("error.general"));
      }
    },
    handlePayment() {
      this.$navigateToMealPlanPayment();
    },
  },
};
</script>

<style scoped>
</style>
