<template>
  <div class="articlePageWrapper" >
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div style="text-align:left;">
      <div class="row noMargin">
        <!-- @Button : Previous Article -->
        <keep-alive>
          <div v-if="!isFarLeft" class="btn btn-light border-all border-secondary noPadding centerContent /**/ col-3 col-sm-1 order-0" @click="previousArticle" tabindex="0" role="button" :aria-label="$t('ariaLabel.showPreviousProduct')">
            <img class="navIcon" src="@/assets/images/arrow-left-black.png" />
          </div>
          <div v-else class="col-3 col-sm-1 order-0"></div>
        </keep-alive>

        <div class="flex-grow-1 w-100 /**/ order-2 order-sm-1 col-12 col-sm-10" :class="{[articleOutOfStockCSS]: !isOnStock}">
          <div class="articlePageImage col-12 noPadding align-content-center">
            <img class="artImageLimit" id="artImage" :src="image1" />
          </div>
        </div>

        <!-- @Button : Next Article -->
        <keep-alive>
          <div v-if="!isFarRight" class="btn btn-light border-all border-secondary noPadding centerContent /**/ col-3 offset-6 offset-sm-0 col-sm-1 order-1 order-sm-2" @click="nextArticle" tabindex="0" role="button" :aria-label="$t('ariaLabel.showNextProduct')">
            <img class="navIcon" src="@/assets/images/arrow-right-black.png" />
          </div>
          <div v-else class="col-3 offset-6 offset-sm-0 col-sm-1 order-1 order-sm-2"></div>
        </keep-alive>
      </div>

      <div v-if="!isOnStock" class="text-danger alert-danger alert my-2 py-2">
        <i class="alert-icon fa fa-exclamation-triangle fas fa-1 text-danger"></i>
        <h5 class="noMargin">{{ $t('button.notAvailable') }}</h5>
      </div>

      <div class="col-12" :class="{[articleOutOfStockCSS] : !isOnStock}">
        <p style="text-align:left;white-space: pre-line" v-html="longDescription" ></p>

        <!-- Input : Weight -->
        <div class="form-group" v-if="decimalQuantity">
          <label class="fontField" for="weightInput">{{$t('field.weight')}}</label>
          <input type="number" :placeholder="$t('placeholder.weightLabel')" class="form-control form-control-lg" v-model="weightOfArticle" />
        </div>

        <!-- Allergens : Available List -->
        <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_ALLERGENS && articleObj.allergens != undefined">
          <h5>{{$t('title.allergen')}}</h5>
          <label v-for="(allergen, index) in articleObj.allergens" :key="allergen.id" class="fontField" for="weightInput">{{allergen.name}}{{index != (articleObj.allergens.length - 1) ? "/" : ""}}</label>
        </div>

        <!-- Properties : Available List -->
        <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_DIETARY_PROPTERTIES && articleObj.properties != undefined">
          <h5>{{$t('subTitle.articleProperties')}}</h5>
          <label v-for="(property, index) in articleObj.properties" :key="property.id" class="fontField" for="weightInput">{{property.name}}{{index != (articleObj.properties.length - 1) ? "/" : ""}}</label>
        </div>

        <!-- Allergens : User Warning List ? -->
        <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_ALLERGENS && articleObj.allergenIds != undefined && articleObj.allergenIds.length > 0 && articleObj.allergens == undefined">
          <h5>{{$t('title.allergen')}}</h5>
          <label v-for="(allergenId, index) in articleObj.allergenIds" :key="allergenId" class="fontField" for="weightInput">{{allergenIds.find(element => element.id === allergenId).name}}{{index != (articleObj.allergenIds.length - 1) ? "/" : ""}}</label>
        </div>

        <!-- Properties : User Warning List ? -->
        <div style="text-align:left;" v-if="config.ARTICLE_PAGE_SHOW_DIETARY_PROPTERTIES && articleObj.propertyIds != undefined && articleObj.propertyIds.length > 0 && articleObj.properties == undefined">
          <h5>{{$t('subTitle.articleProperties')}}</h5>
          <label v-for="(propertyId, index) in articleObj.propertyIds" :key="propertyId" class="fontField" for="weightInput">{{dietaryProperties.find(element => element.id === propertyId).name}}{{index != (articleObj.propertyIds.length - 1) ? "/" : ""}}</label>
        </div>

        <!-- Allergens : List (w. Icon) -->
        <div v-if="config.ARTICLE_PAGE_SHOW_ALLERGENS_IMAGES" class="col-12 noPadding text-left" style="">
          <h5 v-if="dietaryProps.length > 0">{{$t('title.allergen')}}</h5>

          <div class="small-grid-layout">
            <div v-for="(lineItem) in dietaryProps" :key="lineItem.id" class="d-flex flex-column flex-nowrap gap10 align-items-center">
              <img :src="lineItem.image" style="max-width: 50px;" alt="">
              <label class="">{{lineItem.name}}</label>
            </div>

          </div>
        </div>

        <!-- / Allergens & Properties -->

        <div v-for="(lineItem, indexLineItem) in articleObj.items" :key="lineItem.menuId" class="col-12" style="padding-left:0px;text-align:left;">
          <div class="row">
            <p>{{lineItem.name}}</p>
            <label style="padding-left: 10%;" :class=" 'errorLabel id_' + indexLineItem">{{ errorMessages[indexLineItem] }}</label>
          </div>

          <div v-if="hideLocked(lineItem.items).length<1">{{ $t('text.noSubitemAvailable') }}</div>

          <div style="text-align: start;" v-for="(subItem, indexSubItem) in hideLocked(lineItem.items)" :key="subItem.lineId" >
            <check-box @change="onCheckSelect();subItem.items!==undefined&&updateShow('' + indexLineItem + '_' + indexSubItem);"
                       :object="subItem"
                       :dataOperationCount="dataOpCnt"
                       :label="formatLabelWithPrice(subItem.name.replace(articleObj.text1,'').replace(lineItem.name, ''), subItem)"
                       :value="{ price: (articleObj.price > 0 && articleObj.menu) ? 0 : subItem.price, lineId: lineItem.lineId, articleId: subItem.articleId, prodId: subItem.kkProdId, minQty: lineItem.minQty, maxQty: lineItem.maxQty, uniqueItemId: '' + indexLineItem + '_' + indexSubItem, uniqueLineId: '' + indexLineItem, orderString: 'opt_' + lineItem.minQty + '_' + lineItem.maxQty + '_' + ((indexSubItem + 1) * 1000) + '_' + subItem.kkProdId}"
                       :available="getSubItemAvailability(subItem)"
                       :hidePrice="articleObj.price > 0 && articleObj.menu"
                       v-model="selectedValues">
            </check-box>
            <transition>
              <div v-show="show['' + indexLineItem + '_' + indexSubItem]">
                <div style="text-align: start; padding-left: 15%;" v-for="(subLineItem2, indexSubLineItem2) in subItem.items" :key="subLineItem2.lineId">
                  <div class="row">
                    <p>{{subLineItem2.name}}  </p>
                    <label style="padding-left: 10%;" :class=" 'errorLabel id_' + indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2"></label>
                  </div>

                  <div v-if="hideLocked(subLineItem2.items).length<1">{{ $t('text.noSubitemAvailable') }}</div>

                  <div style="text-align: start;" v-for="(subItem2, indexSubItem2) in hideLocked(subLineItem2.items)" :key="subItem2.lineId" >
                    <check-box @change="onCheckSelect();subItem2.items != undefined&&updateShow(indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2);"
                               :object="subItem2"
                               :dataOperationCount="dataOpCnt"
                               :label="formatLabelWithPrice(subItem2.name.replace(articleObj.text1,'').replace(subLineItem2.name, ''), subItem2)"
                               :value="{ price: subItem2.price, lineId: subLineItem2.lineId, parentArticle: subItem.kkProdId, articleId: subItem2.articleId, prodId: subItem2.kkProdId, minQty: subLineItem2.minQty, maxQty: subLineItem2.maxQty, uniqueItemId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2, uniqueLineId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2, orderString: 'subOpt_' + subLineItem2.minQty + '_' + subLineItem2.maxQty + '_' + (((indexSubItem + 1) * 1000) + ((indexSubItem2 + 1) * 100)) + '_' + subItem.kkProdId }"
                               :available="getSubItemAvailability(subItem2)"
                               v-model="selectedValues">
                    </check-box>
                    <transition>
                      <div v-show="show['' + indexLineItem + '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2]">
                        <div style="text-align: start; padding-left: 15%;" v-for="(subLineItem3, indexSubLineItem3) in subItem2.items" :key="subLineItem3.lineId">
                          <div class="row">
                            <p>{{subLineItem3.name}}</p>
                            <label style="padding-left: 10%;" :class=" 'errorLabel id_' + indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2 + '_' + indexSubLineItem3"></label>
                          </div>

                          <div v-if="hideLocked(subLineItem3.items).length<1">{{ $t('text.noSubitemAvailable') }}</div>

                          <div style="text-align: start;" v-for="(subItem3, indexSubItem3) in hideLocked(subLineItem3.items)" :key="subItem3.lineId" >
                            <check-box @change="onCheckSelect"
                                       :object="subItem3"
                                       :dataOperationCount="dataOpCnt"
                                       :label="formatLabelWithPrice(subItem3.name.replace(articleObj.text1,'').replace(subLineItem3.name, ''), subItem3)"
                                       :value="{ price: subItem3.price, lineId: subLineItem3.lineId, articleId: subItem3.articleId, prodId: subItem3.kkProdId, minQty: subLineItem3.minQty, maxQty: subLineItem3.maxQty, uniqueItemId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2 + '_' + indexSubLineItem3 + '_' + indexSubItem3, uniqueLineId: indexLineItem+ '_' + indexSubItem + '_' + indexSubLineItem2 + '_' + indexSubItem2 + '_' + indexSubLineItem3, orderString: 'subOpt_' + subLineItem3.minQty + '_' + subLineItem3.maxQty + '_' + (((indexSubItem + 1) * 1000) + ((indexSubItem2 + 1) * 100) + ((indexSubItem3 + 1) * 10)) + '_' + subItem.kkProdId + '_' + subItem2.kkProdId }"
                                       :available="getSubItemAvailability(subItem3)"
                                       v-model="selectedValues">
                            </check-box>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div v-if='errorMessage != ""' class="col-12 text-left">
          {{errorMessage}}
        </div>

        <div class="" v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS">
          <textarea v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS" type="text" :placeholder="$t('placeholder.articleComment')" maxlength="40" class="form-control form-control-lg articleCommentField" v-model="articleComment" />
        </div>

        <!-- # Group : Footer (Prize, Add to Cart, Amount) -->
        <div class="col-12">
          <div class="row">
            <!-- # Item Prize -->
            <div class="col-12 col-sm-6 col-lg-8 fontTitle align-content-end noPadding">
              {{ menuPrice }}
            </div>
            <div class="col-12 col-sm-6 col-lg-4 noPadding"> <!-- Define Size of Group -->
              <div class="d-flex flex-row flex-nowrap w-100 gap10 justify-content-end align-content-end"> <!-- internally split the space with eachother -->
                <!-- # Item: Amount -->
                <div class="form-group noMargin noPadding flex-grow-1" style="" v-if="config.SHOW_ARTICLE_AMOUNT_SELECT && isOnStock">
                  <label class="fontField">{{ $t('field.articleAmountSelect') }}</label>
                  <input type="number" class="form-control form-control-lg" v-model="menge" min="1"/>
                </div>
                <!-- # Botton : Add to Cart -->
                <div class="align-content-end">
                  <!-- todo - where the accessablilty Text !? -->
                  <b-button type="button" class="btn btn-primary py-2" v-if="errorMessage == '' && isOnStock" :disabled="decimalQuantity && (weightOfArticle == undefined || weightOfArticle < 1)" @click="onSubmit" style="width:100px; margin:0px;">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'cart-plus']" />
                  </b-button>
                  <b-button type="button" class="btn btn-danger btn-inverted noMargin" v-if="!isOnStock" style="">  {{ $t('button.notAvailable') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / Group : Footer -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue, {nextTick} from 'vue'
import VueLoading from 'vue-loading-overlay'
import CheckBox from '../sub-components/CheckBox.vue'
import $ from 'jquery'
import {productService} from "@/services/api/productService";
import {basketService} from "@/services/api/basketService";
import {useAuthStore} from "@/stores/auth";
import {useOrderStore} from "@/stores/order";
import {useAppStore} from "@/stores/app";
import {formatLabelWithPrice} from "@/utils/productUtils";

export default {
  data() {
    return {
      isLoading: false,
      articleObj: {},
      selectedValues: [],
      errorMessage : "",
      longDescription: "",
      decimalQuantity: false,
      weightOfArticle: undefined,
      articleOutOfStockCSS: "articleCardDisabled",
      subItemQuantities: [],
      dataOpCnt: 0,
      image1: "",
      keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
      articleComment: "",
      reload:false,
      errorMessages: {},
      menuPrice: 0,
      show: {},
      menge: 1,
      dietaryProps: [],
    }
  },
  props: {
    article:{
      Type: Object
    },
    callbackBack:{
      Type: Function
    },
    callbackPrevious:{
      Type: Function
    },
    callbackNext:{
      Type: Function
    },
    isOnStock: {
      Type:Boolean
    },
    isFarLeft:{
      Type:Boolean
    },
    isFarRight:{
      Type:Boolean
    }
  },
  components:{
    'loading': VueLoading,
    'check-box': CheckBox
  },
  created() {
    this.articleObj = this.article;
    this.getLongDescriptionAndStock();
  },
  watch:{
    article(newVal) {
      this.articleObj = newVal;
      this.getLongDescriptionAndStock();
    },
  },
  mounted(){
    this.menuPrice = this.articleObj.formattedPrice;
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const orderStore = useOrderStore();

    return {
      appStore,
      authStore,
      orderStore
    };
  },
  computed:{
    config(){
      return window.config;
    },
    allergenIds(){
      return window.allergens;
    },
    dietaryProperties(){
      return window.dietaryProperties;
    },
    subItemsWithId(){
      var items = this.articleObj.items;

      if(items != undefined)
        for (let index = 0; index < items.length; index++) {
          Object.assign(items[index], { uniqueLineId: "" + index});
          let subItems = items[index].items;
          if(subItems != undefined){
            for (let indexSubitem = 0; indexSubitem < subItems.length; indexSubitem++) {
              Object.assign(subItems[indexSubitem], { uniqueLineId: "" + index});
              let lines2 = subItems[indexSubitem].items;
              if(lines2 != undefined){
                for (let indexLines2 = 0; indexLines2 < lines2.length; indexLines2++) {
                  Object.assign(lines2[indexLines2], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2, uniqueParentItemId: "" + index + "_" + indexSubitem });
                  let subItems2 = lines2[indexLines2].items;
                  if (subItems2 != undefined) {
                    for (let indexSubItems2 = 0; indexSubItems2 < subItems2.length; indexSubItems2++) {
                      Object.assign(subItems2[indexSubItems2], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2});
                      let lines3 = subItems2[indexSubItems2].items;
                      if(lines3 != undefined){
                        for (let indexLines3 = 0; indexLines3 < lines3.length; indexLines3++) {
                          Object.assign(lines3[indexLines3], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2 + "_" + indexSubItems2 + "_" + indexLines3, uniqueParentItemId: "" + index + "_" + indexSubitem + "_" + indexLines2 + "_" + indexSubItems2});
                          let subItems3 = lines3[indexLines3].items;
                          if (subItems3 != undefined) {
                            for (let indexSubItems3 = 0; indexSubItems3 < subItems.length; indexSubItems3++) {
                              Object.assign(subItems3[indexSubItems3], { uniqueLineId: "" + index + "_" + indexSubitem + "_" + indexLines2 + "_" + indexSubItems2 + "_" + indexLines3});
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      return items;
    }
  },
  methods:{
    formatLabelWithPrice,
    hideLocked(items) {
      return items.filter(function(item){
        return !item.locked;
      });
    },
    updateShow(index){
      Vue.set(this.show, index, !this.show[index]);
    },
    encodeUtf8(string){
      string = string.toString().replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {

        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }
      return utftext;
    },
    encode64(input){
      var output = "";
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = this.encodeUtf8(input);

      while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output = output +
            this.keyStr.charAt(enc1) + this.keyStr.charAt(enc2) +
            this.keyStr.charAt(enc3) + this.keyStr.charAt(enc4);
      }
      return output;
    },
    getSubItemAvailability(item) {
      if(this.subItemQuantities == undefined || this.subItemQuantities == null || this.subItemQuantities.length == 0)
      {
        return 0;
      }

      var itemQuantity = Object.values(this.subItemQuantities).filter(quantity => quantity.itemId === item.id)

      if(itemQuantity == undefined)
      {
        return 0;
      }

      if(itemQuantity == null)
      {
        return 0;
      }

      if(itemQuantity.length == 0)
      {
        return 0;
      }

      if(itemQuantity[0].quantity > 0)
      {
        return 1;
      }
    },
    async getLongDescriptionAndStock() {
      var config = window.config;

      const result = await productService.getProductDetails(this.articleObj.id, false, true, true, true, true);
      if ((result.exception !== undefined) || (result.item !== undefined)) {
        this.makeToast('danger', this.$t('notification.getItemFailed'));
        return;
      }

      this.longDescription = result.product.longDesc;
      var quantity;

      if (result.product.recipe != undefined) {
        var quantityParsed = parseInt(result.product.recipe);
        if (quantityParsed > 0) {
          quantity = quantityParsed;
        } else {
          quantity = 0;
        }
      } else {
        quantity = 0;
      }

      if (config.ARTICLE_PAGE_SHOW_ALLERGENS_IMAGES && result.product.allergens != undefined) {
        this.dietaryProps = []
        if (result.product.allergens.length > 0) {
          this.dietaryProps = result.product.allergens;
        }
      }

      this.subItemQuantities.push({
        itemId: result.kkProdId,
        quantity: quantity
      });

      this.image1 = result.product.image1;
      this.decimalQuantity = result.product.menu == true ? false : result.product.decimalQuantity;
      this.articleObj = result.product;

      if (this.articleObj.items != undefined) {
        this.errorMessage = this.$t("articleChoicesInstructionMessage");
        nextTick(this.onCheckSelect);
      }

      this.$forceUpdate();
    },
    previousArticle(){
      this.callbackPrevious(this.articleObj);
    },
    nextArticle(){
      this.callbackNext(this.articleObj);
    },
    updateRequirementText(uniqueLineId,text){
      const item = document.getElementsByClassName('id_' + uniqueLineId);
      if (item.length > 0 && item[0] !== undefined) {
        item[0].innerHTML = text;
      }
    },
    onCheckSelect() {
      let config = window.config;
      this.errorMessage="";
      if(this.articleObj.items == undefined)
      {
        return;
      }

      this.menuPrice = 0;
      if (this.articleObj.price > 0) {
        this.menuPrice = this.articleObj.price;
      }

      if(!window.config.EXTRAS_CONSIDER_SEPARATE){
        this.selectedValues.forEach(element => {
          this.menuPrice += element.price;
        });
      }

      var formattedMenuPrice = new Intl.NumberFormat(config.PRICE_NUMBER_FORMAT, { style: 'currency', currency: config.MENU_CURRENCY}).format(this.menuPrice);
      this.menuPrice = formattedMenuPrice;

      this.reload = true;

      Array.from(document.getElementsByClassName('errorLabel')).forEach(element => {
        element&&(element.innerHTML = "");
      });
      this.errorMessages = new Object();
      var subLines1 = this.subItemsWithId;

      if(subLines1 != undefined)
        for (const subLines1Element of subLines1) {
        // subLines1.forEach(subLines1Element => {
          let lineValuesSelected = this.selectedValues.filter(function(x) {
            return x.uniqueLineId == subLines1Element.uniqueLineId });
          if(lineValuesSelected.length < subLines1Element.minQty){
            this.errorMessage = " ";
            // document.getElementsByClassName('id_' + subLines1Element.uniqueLineId)[0].innerHTML = this.$t('text.subItemMin') + subLines1Element.minQty;
            this.updateRequirementText(subLines1Element.uniqueLineId, this.$t('text.subItemMin',[subLines1Element.minQty]))
          }else if(lineValuesSelected.length > subLines1Element.maxQty){
            this.errorMessage = " ";
            // document.getElementsByClassName('id_' + subLines1Element.uniqueLineId)[0].innerHTML = this.$t('text.subItemMax') + subLines1Element.maxQty;
            this.updateRequirementText(subLines1Element.uniqueLineId, this.$t('text.subItemMax',[subLines1Element.maxQty]))
          }

          var subItems1 = subLines1Element.items;
          if(subItems1 != undefined)
            for (const subItemElement of subItems1){
            // subItems1.forEach(subItemElement => {
              var subLines2 = subItemElement.items;
              if(subLines2 != undefined)
                for (const subLines2Element of subLines2) {
                // subLines2.forEach(subLines2Element => {
                  let lineValues2 = this.selectedValues.filter(function(x){
                    return x.uniqueItemId == subLines2Element.uniqueParentItemId
                  });
                  if (lineValues2.length > 0) {
                    let lineValuesSelected2 = this.selectedValues.filter(function(x) {
                      return x.uniqueLineId == subLines2Element.uniqueLineId });
                    if(lineValuesSelected2.length < subLines2Element.minQty){
                      this.errorMessage = " ";
                      // document.getElementsByClassName('id_' + subLines2Element.uniqueLineId)[0].innerHTML = this.$t('text.subItemMin') + subLines2Element.minQty;
                      this.updateRequirementText(subLines2Element.uniqueLineId, this.$t('text.subItemMin',[subLines2Element.minQty]));
                    }else if(lineValuesSelected2.length > subLines2Element.maxQty){
                      this.errorMessage = " ";
                      // document.getElementsByClassName('id_' + subLines2Element.uniqueLineId)[0].innerHTML = this.$t('text.subItemMax') + subLines2Element.maxQty;
                      this.updateRequirementText(subLines2Element.uniqueLineId, this.$t('text.subItemMax',[subLines2Element.maxQty]));
                    }

                    var subItems2 = subLines2Element.items;
                    if (subItems2 != undefined)
                      for (const subItem2Element of subItems2) {
                      // subItems2.forEach(subItem2Element => {
                        var subLines3 = subItem2Element.items;
                        if(subLines3 != undefined)
                          for (const subLines3Element of subLines3) {
                          // subLines3.forEach(subLines3Element => {
                            let lineValues3 = this.selectedValues.filter(function(x){
                              return x.uniqueItemId == subLines3Element.uniqueParentItemId
                            });
                            if (lineValues3.length > 0) {
                              let lineValuesSelected3 = this.selectedValues.filter(function(x) {
                                return x.uniqueLineId == subLines3Element.uniqueLineId });
                              if(lineValuesSelected3.length < subLines3Element.minQty){
                                this.errorMessage = " ";
                                // document.getElementsByClassName('id_' + subLines3Element.uniqueLineId)[0].innerHTML = this.$t('text.subItemMin') + subLines3Element.minQty;
                                this.updateRequirementText(subLines3Element.uniqueLineId, this.$t('text.subItemMin',[subLines3Element.minQty]));
                              }else if(lineValuesSelected3.length > subLines3Element.maxQty){
                                this.errorMessage = " ";
                                // document.getElementsByClassName('id_' + subLines3Element.uniqueLineId)[0].innerHTML = this.$t('text.subItemMax') + subLines3Element.maxQty;
                                this.updateRequirementText(subLines3Element.uniqueLineId, this.$t('text.subItemMax',[subLines3Element.maxQty]));
                                // this.errorMessages[subLines3Element.uniqueLineId] = 'Max ' + subLines3Element.minQty;
                              }
                            }
                            // var subItems3 = subLines3Element.items;
                            // if(subItems3 != undefined)
                            // // subItems3.forEach(subItem3Element => {
                            // //     // console.log(subItem3Element);
                            // // });
                          }
                      }
                  }

                }
            }
        }

      this.reload = false;

      this.$forceUpdate();
      $( "#artImage" ).trigger( "click" );
    },
    async doSubmitForMenuItems() {
      let count = 0;
      let optLines = [];

      if (this.selectedValues.length == 0) {
        let opt = "optdiv" + this.articleObj.prodId;
        let optLineDesc = {optLine: opt, prodId: this.articleObj.prodId};
        optLines.push(optLineDesc);
      }

      for (let j = 0; j < this.selectedValues.length; j++) {
        count++;
        let optLine = this.selectedValues[j].orderString + '_' + count;
        let optLineDescription = {optLine: optLine, prodId: this.selectedValues[j].prodId};
        optLines.push(optLineDescription);
      }

      let paramsNoError = {
        prod: this.articleObj.kkProdId,
        scroll: 0,
        freeText: this.articleComment,
        weightOrVolume: this.decimalQuantity ? this.weightOfArticle / 1000 : undefined,
      };

      if (optLines.length > 0) {
        for (var x = 0; x < optLines.length; x++) {
          var paramName = optLines[x].optLine;
          paramsNoError[paramName] = optLines[x].prodId;
        }
        paramsNoError.kkjson = this.encode64(JSON.stringify(paramsNoError));
      }

      const quantity = this.decimalQuantity ? undefined : this.menge;
      const order = this.orderStore.getOrder(this.appStore.currentOrderId);
      const result = await basketService.addComplexProductToBasket(this.articleObj.kkProdId, order.getOrderType, this.authStore.isAuthenticated, quantity, paramsNoError);
      await this.handleSubmitResult(result);
    },
    async doSubmitForArticles() {
      let paramsNoError = {
        freeText: this.articleComment,
        weightOrVolume: this.decimalQuantity ? this.weightOfArticle / 1000 : undefined,
      };

      const quantity = this.decimalQuantity ? undefined : this.menge;
      const result = await basketService.addProductToBasket(this.articleObj.kkProdId, this.orderStore.getOrderTypeById(this.appStore.currentOrderId), this.authStore.isAuthenticated, quantity, paramsNoError);
      await this.handleSubmitResult(result, this.articleObj.kkProdId, quantity);
    },
    async doSubmitForArticlesWithExtraSeparate() {
      let paramsNoError = {
        freeText: this.articleComment,
      };

      for (let i = 0; i < this.selectedValues.length; i++)
      {
        const result = await basketService.addProductToBasket(this.selectedValues[i].prodId, this.orderStore.getOrderTypeById(this.appStore.currentOrderId), this.authStore.isAuthenticated, this.menge, paramsNoError);
        await this.handleSubmitResult(result);
      }
    },
    async onSubmit()
    {
      let config = window.config;

      if (this.errorMessage == "")
      {
        if (this.articleObj.menu == true) {
          await this.doSubmitForMenuItems();
        } else if (this.articleObj.hasChildren) {
          if (config.EXTRAS_CONSIDER_SEPARATE) {
            await this.doSubmitForArticlesWithExtraSeparate();
          } else {
            await this.doSubmitForMenuItems();
          }
        } else {
          await this.doSubmitForArticles();
        }
      }
    },
    async handleSubmitResult(result)
    {
      this.isLoading = true;

      if ((result.exception !== undefined)) {
        if (result.exception.code === 64)
        {
          this.makeToast('danger', this.$t('notification.addItemMinError'));
        }
        else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('notification.addItemMaxError'));
        }
        else {
          this.makeToast('danger', this.$t('notification.addItemFailed'));
        }
        return;
      }

      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

      // reset input fields
      this.selectedValues = [];
      this.show = {};
      this.onCheckSelect();

      this.$forceUpdate();
      this.isLoading = false;
      this.callbackBack(result.basketTile.formattedTotal, true);

      // TODO check if subitems/extas should be added to orderStore here
      // await this.orderStore.addProductToOrder(this.appStore.currentOrderId, articleId, price, 1);
    },
    formatCurrency(value)
    {
      let config = window.config;
      let formatter = new Intl.NumberFormat(config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: config.SUBSIDY_CURRENCY});
      return formatter.format(value);
    }
  }
}
</script>

<style scoped>

.articleCommentField {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size:15px;
  width:100%;
  /*float:inline-end;*/
}

.articleCommentField:focus {
  border-color: var(--focus-color);
  box-shadow: 0 1px 1px var(--focus-color) inset, 0 0 8px var(--focus-color);
  outline: 0 none;
}

.navIcon {
  /*
  margin-top: 150px;
  */
  opacity: 1;
}

/*
@media screen and (max-width:1000px) {
  .navIcon {
    margin-top: 0px;
  }
}
*/

/*
@media screen and (max-width:770px) {
  .navIcon {
    display:none;
  }
}
*/

</style>
