<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <FlexContainer v-if="isChild" :title="$t('subTitle.customerCardInfos')" class="mb-2" #content>
      <div class="d-flex flex-column noPadding text-left gap10">
        <h3 class="normalText"><strong>{{ $t('text.cardHolder') }}:</strong> {{ fullName(wondCustomer) }}</h3>
        <h3 class="normalText"><strong>{{ $t('text.cardNumber') }}:</strong> {{ wondCustomer.cardNum }}</h3>
        <h3 class="normalText"><strong>{{ $t('text.customerCardBalance') }}</strong> {{ wondCustomer.formattedPrepayBalanceCash }}</h3>
      </div>
    </FlexContainer>
    <b-container fluid>
      <b-row class="gap10">
        <b-col v-if="!Utils.isEmpty(config.TOPUP.predefinedValues)" id="predefinedTopUpValues" cols="12" :md="columnConfig.md">
          <FlexContainer :title="$t('subTitle.chargeAmount')" #content>
            <div class="d-flex flex-column noPadding gap10">
              <div v-for="predefinedValue in config.TOPUP.predefinedValues" :key="predefinedValue">
                <b-button class="btn btn-primary topUpBtn noMargin" v-bind:key="predefinedValue" @click="confirmTopUp(predefinedValue)" :aria-label="$t('ariaLabel.chargeAmount',[predefinedValue + ' ' + config.ACCOUNT_BALANCE_CURRENCY])">{{ Utils.formatCurrency(predefinedValue, config.ACCOUNT_BALANCE_CURRENCY, true, config.ACCOUNT_BALANCE_SEPARATOR) }}</b-button>
              </div>
            </div>
          </FlexContainer>
        </b-col>
        <b-col v-if="config.TOPUP.customField.enabled" cols="12" md="6">
          <FlexContainer :title="$t('subTitle.chargeCustomAmount')" #content>
            <div class="d-flex flex-column noPadding gap10">
              <b-input-group class="input-group topUpInputField noMargin" :prepend="Utils.getCurrencySymbol(config.ACCOUNT_BALANCE_CURRENCY)">
                <currency-input class="form-control" id="chargeAmount" name="chargeAmount" style="height: auto" :aria-label="$t('ariaLabel.rechargeHelpText')"
                                v-model="chargeAmount"
                                :options="{
                                    currency: config.ACCOUNT_BALANCE_CURRENCY,
                                    currencyDisplay: 'hidden',
                                    valueRange: { min: config.TOPUP.customField.minValue, max: config.TOPUP.customField.maxValue }}">
                </currency-input>
              </b-input-group>
              <b-button type="button" :disabled="chargeAmount === null" @click.stop="confirmTopUp(chargeAmount)" :aria-label="$t('ariaLabel.rechargeConfirmHelpText')" class="btn btn-primary topUpBtn noMargin">{{ $t('button.confirm', chargeAmount, config.ACCOUNT_BALANCE_CURRENCY) }}</b-button>
            </div>
          </FlexContainer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import CurrencyInput from "@/components/sub-components/CurrencyInput.vue";
import VueLoading from "vue-loading-overlay";
import {accountService} from "@/services/api/accountService";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import {useAppStore} from "@/stores/app";
import {paymentService} from "@/services/api/paymentService";
import {useOrderStore} from "@/stores/order";
import {Workflow} from "@/constants/constants";

export default {
  name: "top-up",
  components: {
    'loading' : VueLoading,
    'currency-input': CurrencyInput,
    FlexContainer
  },
  data() {
    return {
      isLoading: false,
      wondCustomer: {},
      chargeAmount: null,
    };
  },
  setup() {
    const appStore = useAppStore();
    const orderStore = useOrderStore();

    return {
      appStore,
      orderStore
    };
  },
  computed: {
    columnConfig() {
      return {
        md: this.config.TOPUP.customField.enabled ? 6 : 12,
      };
    },
    Utils() {
      return Utils
    },
    config(){
      return window.config;
    },
    isChild() {
      return this.appStore.isWorkflow(Workflow.SCHOOL);
    },
  },
  props: {
    customerId: {
      type: [String, Number],
    }
  },
  methods: {
    fullName(customer) {
      const nameParts = [];

      if (customer.description) {
        nameParts.push(customer.description);
      }

      if (customer.firstName) {
        nameParts.push(customer.firstName);
      }

      return nameParts.join(' ').trim();
    },
    async selectChild(id) {
      this.isLoading = true;
      const result = await accountService.selectChild(id);
      this.isLoading = false;

      if (result.exception !== undefined || result.errorCode < 0) {
        this.makeToast('danger', this.$t('notification.selectChildError'));
        return;
      }

      return result.wondChild;
    },
    async confirmTopUp(amount) {
      if (!Utils.isEmpty(this.chargeAmount)) {
        amount = this.chargeAmount;
      }
      await this.configurePrepayment(amount);
    },
    async configurePrepayment(amount) {
      this.loading = true;
      const result = await paymentService.configurePrepayment(amount);
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('error.onTopUp'));
        return;
      }
      this.loading = false;

      this.$navigateToTopUpPayment();
    },
  },
  async created() {
    if (this.isChild) {
      this.wondCustomer = await this.selectChild(this.customerId);
    }
  },
};
</script>
<style scoped>

.topUpBtn {
  width: 100%;
}

.topUpInputField{
  width: 100%;
  height: 44px;
}
</style>
