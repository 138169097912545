<template>
  <div>
    <flow :currentPoint="2"> </flow>

    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="col-12 checkoutPadding">
      <div class="col-12" style="padding-left: 0px;padding-right: 0px; padding-top:0px;">
        <div style="margin-left:10%; margin-right:10%;">
          <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; padding: 5px; border-bottom:0.2px solid lightgray">
            <span style="font-size:20px;float:left;">{{ getCampaignName(item.campaignId) }}</span>

            <span v-if="item.gainedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
              <span style="font-size:15px;"> {{ $t('text.rewardPointsGained') }} </span>
              <span style="color:var(--main-color); font-size:20px;"> {{ item.gainedPoints }} </span>
            </span>

            <span v-if="item.usedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
              <span style="font-size:15px;"> {{ $t('text.rewardPointsBurned') }} </span>
              <span style="color:var(--main-color); font-size:20px;"> {{ item.usedPoints }} </span>
            </span>
          </div>
        </div>

        <div v-if="basketItems.length > 0" class="shoppingCartItemsContainer">
          <div v-for="item in basketItems" :key="item.id" class="shoppingCartEntry">
            <cart-entry
                :item="item"
                :callbackAddNormal="addArticle"
                :callbackEdit="changeItemQuantity"
                :callbackAddWeight="weightAlert"
                :callbackDelete="deleteItem"
                :callbackRemove="removeArticle"
            ></cart-entry>
          </div>
        </div>


        <div class="shoppingCartDiscounts" v-if="discounts.length > 0">
          <div class="shoppingCartDiscountsTitle">{{ $t('text.discounts') }}</div>
          <div v-for="discount in discounts" v-bind:key="discount.id">
            <div class="shoppingCartDiscountsEntry">
              <span class="shoppingCartDiscountsEntryName">{{discount.description}}</span>
              <span class="shoppingCartDiscountsEntryValue">{{formatDiscount(discount.value)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 row">
        <div class="col-6 align-content-center" style="font-size:20px; text-align:left;">{{$t('text.sum')}}:</div>
        <div class="col-6" style="font-size:30px; text-align:right;padding-left: 0; padding-right: 0;">{{basketTotal}} </div>
      </div>

      <div class="col-12 row" v-if="paymentLists.length > 0">
        <!-- <div class="shoppingCartDiscountsTitle">{{ $t('discounts') }}</div> -->
        <!-- <div v-for="paymentList in paymentLists" v-bind:key="paymentList.paymentId" class="col-12">
                <span class="col-6 shoppingCartDiscountsEntryName" style="font-size:20px; text-align:left;">{{$t('subTitle.subsidy')}}</span>
                <span class="col-6 shoppingCartDiscountsEntryValue" style="font-size:30px; text-align:right;">{{formatDiscount(paymentList.paymentAmount)}}</span>
        </div> -->
        <span class="col-6 align-content-center" style="font-size:16px; text-align:left;">{{$t('subTitle.subsidy')}}</span>
        <span class="col-6" style="font-size:22px; text-align:right; color:gray;">{{formatCurrency(paymentLists[0].paymentAmount)}}</span>
      </div>

      <div class="col-12" v-if="this.authStore.isAuthenticated && promotionsEnabled == true">
        <input type="text" class="col-12 commentField" v-model="promoBarcode" :placeholder="$t('field.promotionBarcode')" />
        <b-button @click="onPromotionBarcodeSubmit" class=" col-2 btn btn-primary floatRight" style="">{{ $t('button.promotionBarcodeCheck') }}</b-button>
      </div>

      <input v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS" type="text" style="margin-bottom: 10px;" class="col-12 commentField" v-model="comment" :placeholder="$t('field.comment')" />

      <div class="col-12" v-if="ageVerification">
        <p class="text-left">{{ $t('text.acceptCustomerAge') }}</p>
      </div>

      <Button :disabled="!this.basketItems" @click="onSubmit">{{ $t('button.checkout') }}</Button>
    </div>
  </div>
</template>

<script>
import FlowIndicator from '../main-components/FlowIndicator.vue'
import VueLoading from 'vue-loading-overlay'
import ShoppingCartEntry from '../sub-components/ShoppingCartEntry.vue'
import {Mode, OrderTypes} from "@/constants/constants";
import {useOrderStore} from "@/stores/order";
import {accountService} from "@/services/api/accountService";
import {useAuthStore} from "@/stores/auth";
import {useShopStore} from "@/stores/shop";
import {orderService} from "@/services/api/orderService";
import {basketService} from "@/services/api/basketService";
import Button from "@/components/barrier-free-template-components/Button.vue";
import {useAppStore} from "@/stores/app";

export default {
  inject:['setPageMetaTitle'],
  data(){
    return {
      comment:"",
      basketItems: {
        Type: Array
      },
      basketTotal: "",
      quantityButtonDisabled: 'qtyBtnDisabled',
      pointCampaigns: [],
      discounts: [],
      paymentLists: [],
      pointCampaignsDefinitions: [],
      isLoading: false,
      delivery_fee_article_prod_id: 0,
      customerLoggedIn: false,
      promoBarcode:""
    }
  },
  setup() {
    const appStore = useAppStore();
    const orderStore = useOrderStore();
    const authStore = useAuthStore();
    const shopStore = useShopStore();

    return {
      appStore,
      orderStore,
      authStore,
      shopStore
    };
  },
  computed: {
    orderType() {
      return this.orderStore.getOrderType;
    },
    isTableMode() {
      return this.appStore.isMode(Mode.TABLE);
    },
    config() {
      return window.config;
    },
    ageVerification(){
      return this.shopStore.isAgeVerification;
    } ,
    promotionsEnabled(){
      return window.config.PROMOTION_CODE_ENABLED;
    }
  },
  components: {
    Button,
    'loading' : VueLoading,
    'flow' : FlowIndicator,
    'cart-entry' : ShoppingCartEntry,
  },
  created(){
    this.setPageMetaTitle(this.shopStore.getShopName);


    if (window.comment !== undefined && this.comment.length == 0){
      this.comment = window.comment; // todo - please use pinia or any other store option.
    }
  },
  async beforeMount() {
    this.isLoading = true;

    const result = await accountService.afterLogin();

    this.isLoading = false;

    this.customer = result.customer;
    if (result.wondCustomer != undefined) {
      this.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
    }

    if (result.customer != undefined) {
      if (result.customer.type != undefined) {
        if (result.customer.type == 2) {
          window.isGuestCheckout = true;
        }
      }
    }

    window.pointCampaignsDefinitions = this.pointCampaignsDefinitions;

    await this.calculateOrder();
  },
  methods: {
    async onPromotionBarcodeSubmit() {
      this.isLoading = true;
      const result = await orderService.calculateOrder(this.orderStore.getOrderType, {
        promotionBarcode: this.promoBarcode,
        comment: this.comment
      });
      this.isLoading = false;

      if (result.exception != undefined) {
        if (result.exception.code == 611) {
          this.makeToast('danger', this.$t('invalidPromotionCode'));
          return;
        }
      }

      this.basketItems = result.basketTile.basketItems;
      this.basketTile = result.basketTile;
      this.basketTotal = result.basketTile.formattedTotal;
      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

      this.dataCounter++;
      await this.updateCart();
      this.$forceUpdate();
    },
    formatDiscount(value){
      var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: window.config.SUBSIDY_CURRENCY,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    },
    goBack(){
      this.$router.go(-1);
    },
    formatCurrency(value) {
      // switch (this.config.) {
      //     case value:

      //         break;

      //     default:
      //         break;
      // }
      // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
      // return formatter.format(value);
      var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
      return formatter.format(value);
    },
    getCampaignName(campaignId){
      for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
      {
        if(this.pointCampaignsDefinitions[i].id == campaignId)
        {
          return this.pointCampaignsDefinitions[i].description;
        }
      }

      return "";
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    async changeItemQuantity(element, articleId) {
      var value = element.srcElement.value;
      if (!this.isNumeric(value)) {
        this.makeToast('danger', this.$t('notification.inputQuantityNotValid'));
        element.srcElement.value = "1";
        return;
      }

      this.isLoading = true;

      const result = await basketService.editBasket(articleId, value, this.orderType);
      if (value == 0) {
        await this.deleteItem(articleId);
        return;
      }

      this.isLoading = false;
      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
      this.basketItems = result.basketTile.basketItems;
      this.basketTotal = result.basketTile.formattedTotal;
      this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
      this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

      if (result.exception !== undefined) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.changeItemMinError'));
          return;
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('notification.changeItemMaxError'));
          return;
        }
        this.makeToast('danger', this.$t('notification.changeItemQtyFailed'));
        return;
      }
    },
    async addItemsToOrder() {
      if (this.isTableMode && window.openTable != undefined) {
        this.isLoading = true;
        const result = await orderService.addItemsToOrder(true);
        this.isLoading = false;
        window.openTable = result.order;
      }
    },
    async calculateOrder() {
      this.isLoading = true;
      const result = await orderService.calculateOrder(this.orderType, {
        promotionBarcode: window.promotionBarcode
      });
      this.isLoading = false;

      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t("exceptionMessage"));
        return;
      }

      if (result.errorCode > 0) {
        this.makeToast('danger', result.erroMsg);
        return;
      }

      window.shoppingCart = result.basketTile.basketItems;
      this.basketItems = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
      this.basketTotal = result.basketTile.formattedTotal;
      this.pointCampaigns = result.basketTile.pointCampaigns != undefined ? result.basketTile.pointCampaigns : [];
      this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
      this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

      if (result.basketTile.basketItems == undefined || result.basketTile.basketItems.length == 0) {
        const _self = this;
        this.$router.push({path: "/Menu"}).then(function(){
          _self.makeToast('danger', _self.$t('notification.noProducts'));
        });
      }
    },
    weightAlert(){
      this.makeToast('danger', this.$t('notification.weightArticleQuantityChange'))
    },
    async deleteItem(article) {
      this.isLoading = true;
      const result = await basketService.removeProductFromBasket(article.id, this.orderType);
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }
      this.isLoading = false

      await this.updateCart();
      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

      if (result.basketTile.basketItems.length < 1) {
        this.$router.push({path: '/Menu'});
        return;
      }

      this.basketTotal = result.basketTile.formattedTotal;
      this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
      this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

      this.$forceUpdate();
      this.dataCounter++;
    },
    async addArticle(article) {
      this.isLoading = true;

      const result = await basketService.editBasket(article.id, article.quantity + 1, this.orderType);
      if (article.quantity + 1 == 0) {
        await this.deleteItem(article);
        return;
      }

      this.isLoading = false;

      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

      if (result.basketTile.basketItems.length < 1) {
        this.$router.push({path: '/Menu'});
        return;
      }

      this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
      this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

      this.$forceUpdate();
      await this.updateCart();
      this.dataCounter++;

      if (result.exception !== undefined) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.removeItemMinError'));
          return;
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('removeItemMaxError'));
          return;
        }
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }
    },
    async updateCart() {
      this.isLoading = true;
      const result = await orderService.getCurrentOrder(this.orderType, true, this.promoBarcode);
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t("exceptionMessage"));
        return;
      }
      if (result.errorCode > 0) {
        this.makeToast('danger', result.erroMsg);
        return;
      }
      this.isLoading = false;

      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
      this.basketItems = result.basketTile.basketItems;
      this.basketTotal = result.basketTile.formattedTotal;
    },
    async removeArticle(article) {
      this.isLoading = true;

      const result = await basketService.editBasket(article.id, article.quantity - 1, this.orderType);
      if (article.quantity - 1 == 0) {
        this.deleteItem(article);
        return;
      }

      this.isLoading = false;

      if (result.basketTile.basketItems.length < 1) {
        this.$router.push({path: '/Menu'});
        return;
      }

      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
      this.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
      this.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

      this.$forceUpdate();
      await this.updateCart();
      this.dataCounter++;

      if (result.exception !== undefined) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.removeItemMinError'));
          return;
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('removeItemMaxError'));
          return;
        }
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }
    },
    onSubmit() {
      window.comment = this.comment;
      this.addItemsToOrder();

      if(this.isTableMode)
      {
        if(window.openTable != undefined)
          this.$navigateToTablePay();
        else
          this.$navigateToOrderPayment();
      }
      else
      {
        if (this.orderType === OrderTypes.DELIVERY) {
          this.$navigateToAddress();
        } else {
          this.$navigateToOrderPayment();
        }
      }
    }
  },
}
</script>

<style scoped>

.shoppingCartItemsTable {
  color: var(--text-color);
  width:100%;
}

.commentField {
  width:100%;
  height:100%;
  border: 0.2px solid var(--main-color);
}


.shoppingCartDiscounts {
  border-bottom: 1px solid rgba(236,236,236,1);
  /* border-top: 1px solid rgba(236,236,236,1); */
  padding:20px;
  text-align:left;
}

.shoppingCartDiscountsEntry {
  text-align:left;
  padding-left:5%;
}

.shoppingCartDiscountsTitle {
  padding: 0px;
  text-align:left;
  margin-bottom:10px;
  font-weight:600;
  font-size:15px;
  color: var(--main-color);
}

.shoppingCartDiscountsEntryName{
  width:40%;
  display: inline-block;
}

.shoppingCartDiscountsEntryValue{
  margin-left:10%;
}

.forwardBackward {
  border-top: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

/* Container for shopping cart items */
.shoppingCartItemsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Individual cart item entry */
.shoppingCartEntry {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  background: var(--products-area-color);
  border-bottom: 1px solid var(--main-color);
}
</style>
