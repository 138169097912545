<template>
  <div class="toggle-sidebar-container">

    <!-- Toggle Button -->
    <b-button :aria-label="$t('ariaLabel.menu')" v-b-toggle.sidebar @click="toggleSidebar" class="toggle-sidebar-btn">
      <font-awesome-icon v-if="!isSidebarOpen" :icon="['fas', 'bars']" class="toggle-sidebar-btn-icon" />
      <font-awesome-icon v-else :icon="['fas', 'times']" class="toggle-sidebar-btn-icon" />
    </b-button>

    <!-- Sidebar -->
    <b-sidebar id="sidebar"
               bg-variant="light"
               shadow
               backdrop
               no-header-close
               :aria-label="$t('ariaLabel.sidebar')"
               @shown="onSidebarShown"
               @hidden="onSidebarHidden">

      <div class="d-flex flex-column h-100">

        <div class="sidebar-header text-center p-3">
          <img :src="logoPath" alt="Logo" class="img-fluid mb-4" style="max-width: 100px;">
        </div>

        <!-- Navigation Links -->
        <b-list-group flush class="text-left flex-grow-1" v-if="isLoggedin()">
          <b-list-group-item v-for="item in getLinks()" :key="item.key" :class="{'p-3':true,'activeItem':item.isCurrent}" button @click="item.action()" :role="item.role||'link'" :aria-current="item.isCurrent&&'page'||null">
            <span class="icon-wrapper">
              <font-awesome-icon :icon="['fas', item.icon]" />
            </span>
            <!--{{ // item.name[0] === '$' ? $t(item.name.substring(1)):item.name }}-->
            {{ item.name() }}
          </b-list-group-item>
        </b-list-group>

        <div class="sidebar-footer mt-auto d-flex">
            <b-button id="btnLanguages" class="btn btn-inverted-header flex-fill button-with-border" v-for="entry in config.LANGUAGE_LIST" :key="entry.value" v-on:click="changeLanguage(entry.value)" :aria-label="$t('ariaLabel.changeLanguageNative', entry.value)" v-bind:aria-pressed="isLanguageActive(entry.value) ? 'true' : 'false'" :lang="entry.title">{{entry.title}}</b-button>
        </div>

      </div>

    </b-sidebar>
  </div>
</template>

<script>
  import {accountService} from "@/services/api/accountService";
  import {useLocaleStore} from "../../stores/locale";
  import {useAppStore} from "../../stores/app";
  import {useAuthStore} from "../../stores/auth";
  import {Workflow} from "@/constants/constants";

  /** @typedef {{key:number,name:function():string,action:function():never,icon:string,routeId?:string,visible?:function():boolean,role?:string}} linkItem */
  export default {
    data() {
      return {
        isSidebarOpen: false,
        /** @type {linkItem[]} */
        items:[
          //   todo: load from $router
          {key: 1,name: ()=>this.$t('title.dashboard'), action: this.navigateToDashboard, icon: 'home', routeId: 'Dashboard'},
          {key: 2,name: ()=>this.$t('title.customerCard'), action: this.navigateToCustomerCard, icon: 'qrcode', routeId: 'CustomerCard', visible: ()=>this.authStore.isUser},
          {key: 3,name: ()=>this.$t('title.account'), action: this.navigateToAccount, icon: 'user', routeId: 'Account', visible: ()=>this.authStore.isUser},
          {key: 4,name: ()=>this.$t('title.order'), action: this.navigateToOrder, icon: 'burger', routeId: 'OrderShops', visible: ()=>this.config.ORDER.enabled},
          {key: 5,name: ()=>this.$t('title.mealPlan'), action: this.navigateToMealPlan, icon: 'calendar-days', routeId: 'MealPlanShops', visible: ()=>this.config.MEAL_PLAN.enabled},
          {key: 6,name: ()=>this.$t('title.orderHistory'), action: this.navigateToOrderHistory, icon: 'rectangle-list', routeId: 'OrderHistory', visible: ()=>this.config.ORDER_HISTORY.enabled && this.authStore.isUser},
          {key: 7,name: ()=>this.$t('title.transactionHistory'), action: this.navigateToTransactionHistory, icon: 'receipt', routeId: 'TransactionHistory', visible: ()=>this.config.TRANSACTION_HISTORY.enabled && this.authStore.isUser},
          {key: 8,name: ()=>this.$t('title.studentAccounts'), action: this.navigateToStudentAccounts, icon: 'user-graduate', routeId: 'StudentAccounts', visible: ()=>this.config.STUDENT_ACCOUNTS.enabled && this.authStore.isUser},
          {key: 9,name: ()=>this.$t('button.logout'), action: this.logout, icon: 'power-off', role: 'button'},
        ],
      };
    },
    computed: {
      logoPath() {
        return process.env.BASE_URL + 'settings/resources/logo.png';
      },
      config() {
        return window.config;
      },
    },
    setup() {
      const authStore = useAuthStore();
      const localeStore = useLocaleStore();
      const appStore = useAppStore();

      return {
        authStore,
        localeStore,
        appStore
      };
    },
    methods: {
      async changeLanguage(newLocale) {
        await this.localeStore.setLocale(newLocale);
      },
      toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
      },
      onSidebarShown() {
        this.isSidebarOpen = true;
      },
      onSidebarHidden() {
        this.isSidebarOpen = false;
      },
      navigateToAccount() {
        this.$navigateToAccount();
      },
      navigateToOrder() {
        this.appStore.setWorkflow(Workflow.ORDER);
        this.$navigateToOrderShops();
      },
      navigateToMealPlan() {
        this.appStore.setWorkflow(Workflow.MEAL_PLAN);
        this.$navigateToMealPlanShops();
      },
      navigateToCustomerCard() {
        this.appStore.setWorkflow(Workflow.TOP_UP);
        this.$navigateToCustomerCard();
      },
      navigateToTransactionHistory() {
        this.$navigateToTransactionHistory();
      },
      navigateToOrderHistory() {
        this.$navigateToOrderHistory();
      },
      navigateToStudentAccounts() {
        this.appStore.setWorkflow(Workflow.SCHOOL);
        this.$navigateToStudentAccounts();
      },
      navigateToDashboard() {
        this.$navigateToDashboard();
      },
      isLanguageActive(language)
      {
        return this.$i18n.locale === language;
      },
      isLoggedin() {
        return this.authStore.isLoggedIn;
      },
      /** @returns {linkItem & {isCurrent:boolean}} */
      getLinks() {
        return this.$data.items.filter(function(item){
          return item.visible === undefined || item.visible();
        }).map(a=>({...a,isCurrent:this.isCurrent(a)}));
      },
      /** @param {linkItem} item */
      isCurrent(item){
        if (typeof item.routeId == 'string') {
          return this.$route.name === item.routeId;
        }
        return false;
      },
      async logout() {
        const result = await accountService.logout();
        if (result.error) {
          this.makeToast('danger', result.message);
        } else {
          this.makeToast('success', this.$t('notification.logoutSuccessful'));
        }

        await this.$nextTick(); // Wait for the DOM to update
        this.$navigateToLogin(); // Then navigate to the login page
      }
    },
  };
</script>

<style scoped lang="scss">
@import '@/css/customizer';

  .toggle-sidebar-container {
    position: unset;
    display: flex;
    align-items: center;
  }

  .toggle-sidebar-btn {
    background: none;
    border: 2px solid var(--global-header-element-color);
    cursor: pointer;
    font-size: 1.5rem;
    outline: none;
    height: 50px; /* size between open and closed are different; it jumps */
    width: 50px;
  }

  .toggle-sidebar-btn-icon {
    transform: translate(-.5px,-2px);
    color: var(--global-header-element-color);
  }

  ::v-deep .b-sidebar {
    top: Max(10vh,55px);
    position: absolute;
    max-height: unset;
    height: calc(100dvh - Max(10vh,55px));
  }

  ::v-deep .b-sidebar-outer {
    top: 0;
    position: absolute;
  }

  ::v-deep .b-sidebar-backdrop {
    position: absolute;
    bottom: 0;
    right: 0;
    width: unset;
    height: 100dvh;
  }

  .sidebar nav ul {
    list-style: none;
    padding: 20px;
    margin: 0;
  }

  .sidebar nav ul li {
    margin-bottom: 15px;
  }

  .sidebar nav ul li a {
    text-decoration: none;
    color: #333;
  }

  .sidebar nav ul li a:hover {
    color: #007bff;
  }

  .toggle-sidebar-btn i {
    transition: transform 0.3s ease;
  }

  .toggle-sidebar-btn i.fas.fa-times {
    color: #ff0000;
  }

  .sidebar-footer {
    background-color: grey;
    text-align: center;
    gap: 1px;
    padding-top: 1px;
  }

  .sidebar-footer .btn {
    border: 0;
    color: #495057;
    &:focus-visible,
    &:hover,
    &:focus { /* layout issue with overlapping elements */
      z-index: 1;
      background-color: var(--main-color);
      color: var(--btn-text-color);
    }
  }

  .button-with-border {
    border-radius: 0;
  }

  .icon-wrapper {
    display: inline-block;
    width: 30px; /* Setze die Breite auf einen Wert, der für alle Icons passt */
    text-align: center;
  }

  .activeItem {
    color: custom-chain(sidebar-color-text-active,--main-color);
    background: custom-chain(-sidebar-background-active);
  }
</style>
