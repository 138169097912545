<template>
  <button :class="classList" @click="defaultClick" :disabled="disabled" :aria-expanded="collapsed && nativeModel.toString()">
    <div v-if="icon" class="icon">
      <font-awesome-icon :icon="['fas', icon]" />
    </div>
    <!-- no icon : default = -->
    <slot v-if="!icon" />
    <div v-else class="flex-grow-1 align-content-center" :class="{['text-left']:left}">
      <!-- icon : default = -->
      <slot />
    </div>
  </button>
</template>
<script>
export default {
  event: ['click','update:modelValue'],
  model: { // vue 2 v-model
    event: 'update:modelValue',
    prop: 'modelValue',
  },
  props: {
    action: {
      Type: Function,
      default: () => null,
    },
    icon: String, // Property
    unstyled: Boolean, // Class Switch
    disabled: Boolean, // Switch
    left: Boolean, // Class Switch
    presetSize: Boolean, // Switch => disables w-100
    toggle: Boolean, // Switch => if true, stores the modelValue and updates it
    collapsed: Boolean, // Mode, not A value!
    secondary: Boolean, // Class Switch
    lg: Boolean, // Class Switch
    modelValue: {
      Type: Object,
      default: null,
    },
  },
  computed: {
    nativeModel: {
      get() {return this.modelValue;},
      set(value) {this.$emit('update:modelValue', value);}
    },
    classList() {
      let _default = 'noMargin border-1';
      if (this.left) {
        _default = _default.concat(' text-left rowGap20');
      }
      if (this.unstyled) {
        return _default.concat(' noPadding bg-transparent');
      }
      if (this.presetSize) {
        _default = _default.concat(' m-auto noPadding');
      } else {
        _default = _default.concat(' w-100 mx-0');
      }
      _default = _default.concat(' btn');
      if (!this.secondary) {
        _default = _default.concat(' btn-primary');
      } else {
        _default = _default.concat(' btn-secondary');
      }
      if (this.lg) {
        _default = _default.concat(' btn-lg');
      }
      if (this.icon) {
        return _default.concat(' d-flex flex-row');
      }
      return _default;
    },
  },
  methods:{
    defaultClick(event) {
      if (this.action === null){
        this.$emit('click', event);
      } else this.action(event);
      if (this.toggle) { // change the collapsed state
        this.nativeModel = !this.nativeModel;
      }
    },
  },
}
</script>
<style scoped>
.icon {
  align-self: center;
}
</style>
