<template>
    <div class="registrationPageWrapper">
        <loading :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
            loader='bars'></loading>

      <div>
        <b-container fluid>
          <b-form @submit.stop.prevent>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.firstName.visible || config.GUEST_CHECKOUT.FIELDS.lastName.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="firstName">
                  <template v-slot:label>
                    {{ $t('field.firstName') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.firstName.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="firstName" name="firstName"
                                type="text" autofocus
                                v-model="$v.form.firstName.$model"
                                :state="validateState('firstName')"
                                aria-describedby="firstName-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.firstName.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="firstName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="lastName">
                  <template v-slot:label>
                    {{ $t('field.lastName') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.lastName.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="lastName"
                                name="lastName"
                                type="text"
                                v-model="$v.form.lastName.$model"
                                :state="validateState('lastName')"
                                aria-describedby="lastName-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.lastName.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="lastName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.emailAddr.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="emailAddr">
                  <template v-slot:label>
                    {{ $t('field.email') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.emailAddr.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" v-model="$v.form.emailAddr.$model"
                                :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.emailAddr.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.cardNumber.visible || config.GUEST_CHECKOUT.FIELDS.customerCode.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="cardNumber">
                  <template v-slot:label>
                    {{ $t('field.cardNumber') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.cardNumber.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="cardNumber" name="cardNumber" type="text" v-model="$v.form.cardNumber.$model"
                                :state="validateState('cardNumber')" aria-describedby="cardNumber-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.cardNumber.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="cardNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="customerCode">
                  <template v-slot:label>
                    {{ $t('field.customerCode') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.customerCode.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="customerCode" name="customerCode" type="text" v-model="$v.form.customerCode.$model"
                                :state="validateState('customerCode')" aria-describedby="customerCode-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.customerCode.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="customerCode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.streetAddress.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="streetAddress">
                  <template v-slot:label>
                    {{ $t('field.street') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.streetAddress.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="streetAddress"
                                name="streetAddress" type="text"
                                v-model="$v.form.streetAddress.$model"
                                :state="validateState('streetAddress')"
                                aria-describedby="streetAddress-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.streetAddress.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="streetAddress-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.postcode.visible || config.GUEST_CHECKOUT.FIELDS.city.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="postcode">
                  <template v-slot:label>
                    {{ $t('field.postalCode') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.postcode.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="postcode"
                                name="postcode"
                                type="text"
                                v-model="$v.form.postcode.$model"
                                :state="validateState('postcode')"
                                aria-describedby="postcode-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.postcode.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="postcode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="city">
                  <template v-slot:label>
                    {{ $t('field.city') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.city.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="city"
                                name="city"
                                type="text"
                                v-model="$v.form.city.$model"
                                :state="validateState('city')"
                                aria-describedby="city-feedback"
                                :maxLength="config.GUEST_CHECKOUT.FIELDS.city.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="city-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.countryId.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="countryId">
                  <template v-slot:label>
                    {{ $t('field.country') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.countryId.required" class="text-danger">*</span>
                  </template>
                  <select-picker-country
                      id="customerCountry"
                      name="customerCountry"
                      class="countrySelect"
                      v-if="countriesLoaded"
                      :options="countries"
                      :callback="selectCountry"
                      :preselectedId="selectedCountryId"
                      aria-describedby="countryId-feedback"
                      :state="validateState('countryId')" />
                  <b-form-invalid-feedback class="text-left" id="countryId-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.telephoneNumber.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="telephoneNumber">
                  <template v-slot:label>
                    {{ $t('field.telephoneNumber') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.telephoneNumber.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="telephoneNumber" name="telephoneNumber" type="text" v-model="$v.form.telephoneNumber.$model"
                                :state="validateState('telephoneNumber')" aria-describedby="telephoneNumber-feedback"/>
                  <b-form-invalid-feedback class="text-left" id="telephoneNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.notes1.visible || config.GUEST_CHECKOUT.FIELDS.notes2.visible" >
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes1">
                  <template v-slot:label>
                    {{ $t('field.notes1') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes1.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes1" name="notes1" type="text" v-model="$v.form.notes1.$model"
                                :state="validateState('notes1')" aria-describedby="notes1-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes1.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes1-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes2">
                  <template v-slot:label>
                    {{ $t('field.notes2') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes2.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes2" name="notes2" type="text" v-model="$v.form.notes2.$model"
                                :state="validateState('notes2')" aria-describedby="notes2-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes2.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes2-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.notes3.visible || config.GUEST_CHECKOUT.FIELDS.notes4.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes3">
                  <template v-slot:label>
                    {{ $t('field.notes3') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes3.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes3" name="notes3" type="text" v-model="$v.form.notes3.$model"
                                :state="validateState('notes3')" aria-describedby="notes3-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes3.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes3-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="notes4">
                  <template v-slot:label>
                    {{ $t('field.notes4') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.notes4.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="notes4" name="notes4" type="text" v-model="$v.form.notes4.$model"
                                :state="validateState('notes4')" aria-describedby="notes4-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.notes4.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="notes4-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.GUEST_CHECKOUT.FIELDS.otherInfos.visible">
              <b-col cols="12" md="12">
                <b-form-group class="form-group fontField" label-for="otherInfos">
                  <template v-slot:label>
                    {{ $t('field.otherInfos') }} <span v-if="config.GUEST_CHECKOUT.FIELDS.otherInfos.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="otherInfos" name="otherInfos" type="text" v-model="$v.form.otherInfos.$model"
                                :state="validateState('otherInfos')" aria-describedby="otherInfos-feedback" :maxLength="config.GUEST_CHECKOUT.FIELDS.otherInfos.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="otherInfos-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col cols="12" md="12">
                <i18n class="text-left" path="text.acceptTermsAndPrivacyAsGuest" tag="p">
                  <template v-slot:terms>
                    <a :href="config.COMPANY.links.terms" target="_blank">{{ $t('link.terms') }}</a>
                  </template>
                  <template v-slot:privacy>
                    <a :href="config.COMPANY.links.privacy" target="_blank">{{ $t('link.privacy') }}</a>
                  </template>
                </i18n>
              </b-col>
            </b-form-row>
            <b-form-row cols="1">
              <b-button type="submit" @click.stop="onClickCaptcha" :disabled="$v.$invalid" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha w-100 noMargin">{{ $t('button.submit') }}</b-button>
            </b-form-row>
          </b-form>
        </b-container>
      </div>
    </div>
</template>

<script>
import {email, requiredIf} from 'vuelidate/lib/validators'
import VueLoading from 'vue-loading-overlay'
import {en, de, fr} from 'vuejs-datepicker/dist/locale'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import {accountService} from "../../services/api/accountService";
import {useAppStore} from "@/stores/app";
import {Login} from "@/constants/constants";
import {useAuthStore} from "@/stores/auth";

export default {
  data() {
      return {
          submitStatus: null,
          form: {
            firstName: undefined,
            lastName: undefined,
            streetAddress: undefined,
            postcode: undefined,
            city: undefined,
            countryId: undefined,
            telephoneNumber: undefined,
            emailAddr: undefined,
            cardNumber: undefined,
            customerCode: undefined,
            notes1: undefined,
            notes2: undefined,
            notes3: undefined,
            notes4: undefined,
            otherInfos: undefined,
          },
          isLoading:false,
          en: en,
          de: de,
          fr: fr,
          countriesLoaded: false,
          countries: {
              Type: Array
          },
           selectedCountry: {
              Type:Object
          },
          zones: {
              Type: Array
          },
          selectedCountryId: 0,
          reponseToken:""
      }
  },
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();

    return {
      authStore,
      appStore,
    };
  },
  computed: {
      config() {
          return window.config;
      },
  },
  components:{
      'loading' : VueLoading,
      'select-picker-country': selectPickerCountry,
  },
  validations: { // STANDARD
    form: {
      firstName: {
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.firstName.required),
      },
      lastName:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.lastName.required),
      },
      streetAddress:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.streetAddress.required),
      },
      postcode:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.postcode.required),
      },
      city:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.city.required),
      },
      countryId:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.countryId.required),
      },
      telephoneNumber:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.telephoneNumber.required),
      },
      emailAddr:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.emailAddr.required),
        email,
      },
      cardNumber:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.cardNumber.required),
      },
      customerCode: {
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.customerCode.required),
      },
      notes1:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes1.required),
      },
      notes2:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes2.required),
      },
      notes3:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes3.required),
      },
      notes4:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.notes4.required),
      },
      otherInfos:{
        required: requiredIf(() => window.config.GUEST_CHECKOUT.FIELDS.otherInfos.required),
      },
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
      // eslint-disable-next-line no-undef
    onClickCaptcha(e) {
        console.log("onClickCaptcha");
        e.preventDefault();
        if(window.config.G_CAPTCHA.enabled == true)
        {
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function() {
                // eslint-disable-next-line no-undef
                grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                    this.responseToken = token;
                    this.onSubmit(e);
                });
            });
        }
        else
        {
            this.onSubmit(e);
        }

    },
    selectCountry(countryId) {
        this.form.countryId = countryId;
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.makeToast('danger', this.$t('notification.fillAllRequiredFields'));
        return;
      }

      const result = await accountService.register(this.responseToken, undefined, this.form.gender, this.form.firstName, this.form.lastName,
        this.form.emailAddr, this.form.cardNumber, this.form.customerCode, this.form.streetAddress, this.form.postcode, this.form.city, this.form.countryId, this.form.telephoneNumber,
        this.form.notes1, this.form.notes2, this.form.notes3, this.form.notes4, this.form.otherInfos, 'xxxxxxxx', true);

      if (result.exception !== undefined || result.errorCode > 0) {
        this.makeToast('danger', this.$t("notification.registrationError"));
        return;
      }

      this.authStore.setAuthenticated(true, Login.Mode.GUEST, Login.UserType.GUEST_USER);
      this.handleLoginSuccess();
    },
    handleLoginSuccess() {
      const redirectRouteName = this.appStore.redirectRouteName
      if (redirectRouteName) {
        console.log("Redirecting to last route:", redirectRouteName);
        this.appStore.clearRedirectRoute();
        this.$navigate(redirectRouteName, {withReplace: true});
      } else {
        this.$navigateToStartPage();
      }
    },
  },
  async created() {
    const result = await accountService.getCountries(1);

    this.countries = result.countries;
    if (result.selectedCountry != undefined) {
      this.selectedCountry = result.selectedCountry;
      this.form.countryId = result.selectedCountry.id;
      this.selectedCountryId = result.selectedCountry.id;
    } else {
      if (this.countries.length > 0)
        this.selectCountry(this.countries[0].id);
    }

    this.countriesLoaded = true;
  },
}
</script>
<style scoped>
</style>
