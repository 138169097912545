import { defineStore } from 'pinia';
import i18n, {loadLocaleMessages} from "@/i18n";
import { customerService } from "@/services/api/customerService";

export const useLocaleStore = defineStore('locale', {
    // State
    state: () => ({
        locale: window.config.LANGUAGE_LIST[0].value, // Default locale
    }),

    // Getters
    getters: {
        getLocale: (state) => state.locale,
    },

    // Actions
    actions: {
        async setLocale(newLocale) {
            try {
                // Update the locale on the server (if needed)
                await customerService.changeCustomerLocale(newLocale);

                // Update the Vue i18n instance
                i18n.locale = newLocale;  // Update the i18n instance directly
                if (!i18n.availableLocales.includes(newLocale))
                {
                  await loadLocaleMessages(i18n, newLocale);
                }

                // Optionally, update the document's language attribute
                document.documentElement.lang = newLocale.replace('_', '-');

                // Update the store's state directly
                this.locale = newLocale;
            } catch (error) {
                console.error("Failed to change locale:", error);
            }
        },
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'locale',
                storage: localStorage,
            },
        ],
    }
});
