<template>
  <div class="d-flex flex-column" style="row-gap: 7px">
    <!-- Check if product exists before rendering the image -->
    <!-- region Product Image -->
    <div v-if="product" class="product-image">
      <img :src="product.image1" :alt="product.name" class="imageCoversParent"/>
    </div>
    <!-- endregion -->

    <!-- region Long Description -->
    <Accordion v-if="product && product.longDesc" :title="$t('subTitle.productDetails')">
      <!-- Check if longDesc exists -->
      <span class="text-left">{{ product.longDesc }}</span>
    </Accordion>
    <!-- endregion -->

    <!-- region Product Properties -->
    <Accordion v-if="product && product.properties && product.properties.length > 0" :title="$t('subTitle.properties')">
      <div class="small-grid-layout">
        <div class="filter-item" v-for="property in product.properties" :key="property.id">
          <div class="filter-icon">
            <img :src="property.image" :alt="property.name" class="imageFitsParent" />
          </div>
          <span>{{ property.name }}</span>
        </div>
      </div>
    </Accordion>
    <!-- endregion -->

    <!-- region Allergens -->
    <Accordion v-if="product && product.allergens && product.allergens.length > 0" :title="$t('subTitle.allergens')">
      <div class="small-grid-layout">
        <div class="filter-item" v-for="allergen in product.allergens" :key="allergen.id">
          <div class="filter-icon">
            <img :src="allergen.image" :alt="allergen.name" class="imageFitsParent" />
          </div>
          <span>{{ allergen.name }}</span>
        </div>
      </div>
    </Accordion>
    <!-- endregion -->

    <!-- region Nutrients -->
    <Accordion v-if="product && product.nutriValues && product.nutriValues.length > 0" :title="$t('subTitle.nutriValues')">
      <div class="allergens" v-for="nutriValue in product.nutriValues" :key="nutriValue.id">
        <span class="text-left">
          {{ nutriValue.name }}
        </span>
        <span class="text-right">
          {{ nutriValue.value }}
        </span>
      </div>
    </Accordion>
    <!-- endregion -->

    <!-- region Add / Edit Item Properties -->
    <hr class="noMargin">

    <!--
    <ProductOptions
      v-if="product.hasChildren"
      :product="product"
      @updateSelection="handleSelection"
    />
    -->
    <StructureEditGroup
      id="editItem"
      v-for="groupNode in getStructure()"
      :key="groupNode.lineId"
      :structure="groupNode"
      v-model="samplePreselectedStructure"
      :structure-settings="structureSettings"
    />

    <!-- region Testing Elements -->
    <!--<span>Original Datastructure:<br/> {{ samplePreselectedStructure }}</span>-->
    <!--<span>Serialized Datastructure:<br/> {{ resolveItemList(samplePreselectedStructure) }}</span>-->
    <!--<span>Deserialized Datastructure:<br/> {{ unresolveItemList(resolveItemList(samplePreselectedStructure)) }}</span>-->


    <!--<b-button class="btn btn-primary noMargin" @click="samplePreselectedStructure= []">Test: Deselect All</b-button>-->
    <!--<b-button class="btn btn-primary noMargin" @click='samplePreselectedStructure= [ "1.1381", "2.1443", "2.1401", "2.1401.1.1379" ]'>Test: Do Pre Selection for Burger</b-button>-->
    <!--<b-button class="btn btn-primary noMargin" @click='samplePreselectedStructure= unresolveItemList([ "opt_1_1_0_1381_1=1381", "opt_0_3_0_1443_2=1443", "opt_0_3_0_1401_3=1401", "subOpt_1_1_0_1401_4=1379" ])'>Test: Preselection from Server for Burger</b-button>-->
    <!-- endregion -->

    <!-- endregion -->


    <div v-if="(canOrder && maxQuantity > 0) || product.hasChildren">
      <!--<SpinButton
        class="custom-spin-button"
        :input-disabled="true"
        :min="minQuantity"
        :max="maxQuantity"
        :modelValue="quantity"
        @update:modelValue="({ value, type }) => handleProductUpdate(product.kkProdId, product.isLocked, product.hasChildren, product.name, product.price, type, group.id)"
        role="button"
        tabindex="0"
        aria-label="Add or remove product from basket"
      />-->
      <b-form-spinbutton
        v-model="quantity"
        :min="0"
        :max="maxQuantity"
      />
      <b-button v-if="quantity > 0" class="btn btn-accordion btn-primary" @click="addToBasket">
        <span>{{ formattedTotalPrice }}</span>
        <font-awesome-icon class="addIconMenu" :icon="['fas', 'cart-plus']" />
      </b-button>
      <b-button v-if="quantity === 0" class="btn btn-accordion btn-primary" @click="addToBasket">
        <font-awesome-icon class="mr-3" :icon="['fas', 'trash-can']" />
        <span>{{ $t('button.removeArticle') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import {productService} from "@/services/api/productService";
import Accordion from "@/components/layout-components/Accordion.vue";
import StructureEditGroup from "@/components/layout-components/StructureEditGroup.vue";
import {StructureUtils} from "@/utils/structureUtils";

export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
    maxQuantity: { // remove
      type: Number,
      required: false,
    },
    currentQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    groupId: { // remove
      type: Number,
      required: false,
    },
    canOrder: { // remove
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /** @type {ProductDetails.product} */
      product: {},
      quantity: 1,
      selectedOptions: [],
      totalPrice: null,
      samplePreselectedStructure:[],
      /** @type {StructureEditSettings} */
      structureSettings: {
        idPropGroup: 'lineId',
        idPropNode: 'kkProdId',
        namePropGroup: 'name',
        namePropNode: 'name',
        hasChildPropNode: 'hasChildren',
        childPropNode: 'items',
        childPropGroup: 'items'
      },
    };
  },
  async created() {
    if (!this.productId) return;

    this.loading = true;
    const result = await productService.getProductDetails(this.productId, true, true, true, true, true);
    this.loading = false;

    this.quantity = this.currentQuantity;
    if (result.exception || !result.product) {
      this.makeToast("warning", this.$t("notification.getItemFailed"));
      return;
    }

    this.product = result.product;
  },
  components: {
    StructureEditGroup,
    Accordion,
  },
  computed: {
    formattedTotalPrice() {
      return this.totalPrice || Utils.formatCurrency(this.quantity * (this.product.price || 0), window.config.ACCOUNT_BALANCE_CURRENCY);
    },
  },
  methods: {
    handleSelection(selectedOptions) {
      this.selectedOptions = selectedOptions;
      console.log("handleSelection", selectedOptions);
      this.updateTotalPrice();
    },
    updateTotalPrice() {
      let basePrice = this.product.price || 0;

      let additionalPrice = this.selectedOptions.reduce((total, option) => {
        return total + (option.price || 0); // Using collected price directly
      }, 0);

      this.totalPrice = Utils.formatCurrency(
        this.quantity * (basePrice + additionalPrice),
        window.config.ACCOUNT_BALANCE_CURRENCY
      );
    },
    addToBasket() {
      if (this.quantity > this.currentQuantity) {
        // Add only the difference
        const quantityToAdd = this.quantity - this.currentQuantity;

        if (quantityToAdd > 0) {
          this.$emit(
            "add-to-basket",
            this.product.kkProdId,
            quantityToAdd,
            this.product.price,
            this.selectedOptions,
            this.groupId
          );
        }
      } else if (this.quantity < this.currentQuantity) {
        // Remove the difference if the new quantity is less than the current
        const quantityToRemove = this.currentQuantity - this.quantity;

        this.removeFromBasket(quantityToRemove);
      }

      this.closeModal();
    },
    removeFromBasket(quantityToRemove) {
      if (quantityToRemove > 0) {
        this.$emit(
          "remove-from-basket",
          this.product.kkProdId,
          quantityToRemove,
          this.groupId
        );
      }
    },
    closeModal() {
      this.$emit('close');
    },
    /** @return {Array<ProductDetailGroupNode>} */
    getStructure(){
      if (!this.product.hasChildren) // isUniqueItem()
        return [];

      return this.product.items;
    },
    resolveItemList(pathSet) {
      return StructureUtils.convertToServer(
        pathSet,
        this.getStructure(),
        this.structureSettings,
        "groupId",
        "kkProdId"
      );
    },
    unresolveItemList(compiledItems) {
      return StructureUtils.convertToClient(
        compiledItems,
        this.getStructure(),
        this.structureSettings,
        "groupId",
        "kkProdId"
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/css/customizer';

.modal-body .product-image {
  aspect-ratio: 1 / 1;
  /* todo - Customize this icon */
}

.product-details {
  display: flex;
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.filter-icon {
  height: 60px;
  width: 60px;
}

@media (max-width: 768px) {
  .filter-icon {
    height: 50px;
    width: 50px;
  }
}

.allergens {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.allergen-img {
  max-width: 30px;
  margin-right: 5px;
}

.small-header {
  font-size: 0.9em; /* Small header font size */
  text-align: left; /* Align header text to the left */
  background: none;
  padding: 0;
}

.btn-accordion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  width: 100%;
  margin: 5px 0 0;
}

.addIconMenu {
  margin-left: 5px;
}

/* Align radio buttons and checkboxes to the left */
.b-form-radio-group,
.b-form-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.b-form-radio,
.b-form-checkbox {
  margin-left: 0; /* Align radio buttons and checkboxes to the left */
}

/* Add padding to b-card-header title */
.b-card-header {
  padding-left: 1rem; /* Adjust as needed to match collapse button padding */
}

/* Override SpinButton styling */
.custom-spin-button {
  display: flex;
  align-items: stretch;
  width: auto;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  justify-content: space-between;
}

/* Override button styles */
.custom-spin-button button {
  background-color: transparent;
  color: #000;
  border: none;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}

.custom-spin-button button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

/* Override input field styles */
.custom-spin-button input {
  border: none;
  text-align: center;
  width: 50px;
  font-size: 14px;
  padding: 5px;
  background-color: #f9f9f9;
}
</style>
