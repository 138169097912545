import axiosClient from "@/apiClient";
import { extractSessionExpiry } from "@/store/SessionTimer";
import { useAuthStore } from "@/stores/auth";

export const apiUtils = {
  doGet(api, params) {
    return axiosClient.get(api, { params })
      .then(response => apiUtils.handleResponse(api, response))
      .catch(error => apiUtils.handleError(error));
  },
  doPost(api, params) {
    return axiosClient.post(api, params)
      .then(response => apiUtils.handleResponse(api, response))
      .catch(error => apiUtils.handleError(error));
  },
  // New method for handling file downloads or different content types
  doGetWithContentType(api, params, contentType = "application/json") {
    return axiosClient.get(api, {
      params,
      headers: { "Content-Type": contentType },
      responseType: contentType === "application/pdf" ? "blob" : "json",
    })
      .then(response => {
        if (contentType === "application/pdf") {
          return response.data;
        }
        return apiUtils.handleResponse(api, response);
      })
      .catch(error => apiUtils.handleError(error));
  },
  // Extracted common response handling logic
  handleResponse(api, response) {
    const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
    console.log(`API Call [${api}]: Requires login - ${requiresLogin}`);

    const authStore = useAuthStore();
    if (authStore.isLoggedIn) {
      if (requiresLogin) {
        authStore.reset();
        return;
      }
      extractSessionExpiry(response.headers["x-session-expiry"]);
    }

    return response.data;
  },
  handleError(error) {
    console.error("API Error:", error);
    throw error;
  }
};
